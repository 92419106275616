import { Container } from '../../ui'
import InfiniteScroll from 'react-infinite-scroll-component'
import { OrderListData } from './OrderListContext'
import OrderListItem from './OrderListItem'
import PageLoader from '../../Layout/PageLoading'


type OrderListProps = {
	hasMore: boolean
	current: OrderListData[]
	getMoreData: () => void
}

export default function OrderListBody(props: OrderListProps) {
	const { hasMore, current, getMoreData } = props;

  return (
    <Container.Flex fullWidth maxWidth={724}>
      <InfiniteScroll
        style={{ width: '100%' }}
        dataLength={current.length}
        next={getMoreData}
        hasMore={hasMore}
        loader={current.length > 0 ? <PageLoader /> : null}
      >
        {current &&
          current.map(order => {
            return <OrderListItem data={order} key={order.number} />
          })}
      </InfiniteScroll>
    </Container.Flex>
  )
}
