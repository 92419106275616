export const collectGetOrderData = (orderNumber: string, itemNumber?: string, code?: string) => {
    const obj = {
        number: orderNumber
    } as any;

    if (itemNumber) {
        obj.itemNumber = itemNumber
    }

    if (code) {
        obj.code = code 
    }

    return obj
}