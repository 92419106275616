import { createContext, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { URLS } from '../apinew'
import { PartnerDetail } from '../components'
import PageLoader from '../components/Layout/PageLoading'
import { Container, Typography } from '../components/ui'
import { useApi } from '../hooks/useApi'
import { PartnerDetail as PartnerDetailType } from '../types'

const ParnterDetailContext = createContext(
  {} as {
    data: PartnerDetailType | null
  },
)

export const usePartnerDetail = () => {
  return useContext(ParnterDetailContext)
}

const PartnerPage = () => {
  const { partnerCode } = useParams()
  const { post } = useApi()

  const [data, setData] = useState<PartnerDetailType | null>(null)
  const [pending, setPending] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    setPending(true)
    post(URLS.getPartnersDetail, { partnerCode }).then(response => {
      if (response.status === 'success') {
        setData(response.data)
      }
      if (response.status === 'error') {
        setError(response.errors.join('. '))
      }
      setPending(false)
    })
  }, [])

  return (
    <ParnterDetailContext.Provider value={{ data }}>
      <Container.Flex fullWidth>
        {pending && <PageLoader />}
        {error && <Typography.Error>{error}</Typography.Error>}
        {data && <PartnerDetail />}
      </Container.Flex>
    </ParnterDetailContext.Provider>
  )
}

export default PartnerPage
