import { useTheme } from 'styled-components'
import { Container, Typography } from '../../ui'
import Icon from '../../ui/Icon'
import Input from '../../ui/Input'
import { useOrderListData } from './OrderListContext'
import styled from 'styled-components/macro'

const Filter = styled.button`
  background-color: transparent;
  border: none;
  padding: 10px;
  font-size: 0;
  line-height: 0;
  cursor: pointer;
  flex-shrink: 0;
  margin: 0;

  @media (max-width: 660px) {
    padding-right: 8px;
  }
`

const SearchWrapper = styled.div`
  width: 100%;
  max-width: 300px;
  margin-right: 2px;

  @media (max-width: 660px) {
    max-width: none;
  }
`

export default function OrderListHeader() {
  const {
    openFilters,
    anyFiltersOn,
    search: searchValue,
    setSearch,
    getOrderList,
  } = useOrderListData()

  const theme = useTheme()

  return (
    <Container.Flex
      fullWidth
      direction="row"
      justify="between"
      breakpoints={{
        660: {
          flexFlow: 'column',
          alignItems: 'stretch',
        },
      }}
    >
      <Container.Flex
        direction="row"
        styles={{ flexShrink: 0 }}
        breakpoints={{
          660: {
            marginBottom: '12px',
            justifyContent: 'space-between',
          },
        }}
      >
        <Typography.Title margin="0 16px 0 4px">Список заявок</Typography.Title>

        <Typography.Link
          href="/create/"
          styles={{
            padding: '3px 0 1px',
            fontSize: '14px',
            lineHeight: '16px',
            fontWeight: 'normal',
          }}
        >
          Создать заявку
        </Typography.Link>
      </Container.Flex>

      <Container.Flex direction="row" justify="end" styles={{ flexGrow: 1 }}>
        <SearchWrapper>
          <Input
            placeholder="Поиск заявки"
            value={searchValue}
            icon="search"
            iconPosition="right"
            onChange={e => setSearch(e.target.value.replace(/ /g, ''))}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                getOrderList()
              }
            }}
            iconClick={getOrderList}
            styles={{
              width: '100%',
            }}
          />
        </SearchWrapper>
        <Filter onClick={openFilters}>
          <Icon
            name={anyFiltersOn ? 'filters-on' : 'filters-off'}
            color={theme.colors.text.secondary}
          />
        </Filter>
      </Container.Flex>
    </Container.Flex>
  )
}
