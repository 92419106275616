import { KeyboardEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetItemData, getItemData } from '../../store/offerSlice'
import { bp, cardSizes } from '../../theme/media'
import { Button, Card, Container, Info, Loader, Typography } from '../ui'
import Input from '../ui/Input'

interface Props {
  shouldRemove: boolean
  hide: () => void
}

export default function OfferSearch(props: Props) {
  const { shouldRemove, hide } = props
  const [itemNumber, setItemNumber] = useState('')

  const dispatch = useDispatch()

  const getItem = useSelector(getItemData)

  const { pending, errors } = getItem

  const fetch = useCallback(() => {
    dispatch(GetItemData.request(itemNumber))
  }, [dispatch, itemNumber])

  const errorString = useMemo(() => {
    if (!errors.length) return ''

    return errors.map(el => el.message).join('. ')
  }, [errors])

  const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      fetch()
    }
  }

  useEffect(() => {
    if (shouldRemove) {
      setTimeout(hide, 300)
    }
  }, [shouldRemove])

  return (
    <Card
      padding={28}
      className={shouldRemove ? 'hide' : ''}
      styles={{
        width: cardSizes.big,
      }}
      breakpoints={{
        [bp.table]: {
          width: cardSizes.short,
        },
				[bp.xs] : {
          width: '100%'
        }
      }}
      maxContent
    >
      <Container.Flex
        fullWidth
        alignItems="start"
        styles={{ position: 'relative', maxWidth: '288px' }}
        breakpoints={{
          750: {
            maxWidth: '256px',
          },
        }}
      >
        <Container.Flex
          fullWidth
          alignItems="start"
          className={pending ? 'hide' : ''}
        >
          <Typography.Title secondary>По номеру устройства</Typography.Title>

          <Input
            placeholder="Укажите номер устройства"
            value={itemNumber}
            onChange={e => setItemNumber(e.target.value)}
            onKeyPress={onKeyPress}
          />

          <Button
            fullWidth
            disabled={!itemNumber || undefined}
            onClick={fetch}
            withLoader
            pending={pending}
            margin={'16px 0 20px 0'}
          >
            НАЙТИ УСТРОЙСТВО
          </Button>

          <Info>
            <Typography.Main start margin={0}>
              Клиент может предоставить вам QR-код для сканирования или просто
              введите номер устройства вручную
            </Typography.Main>
          </Info>

          <Typography.Error className={!!errorString ? 'show' : 'hide'}>
            {errorString}
          </Typography.Error>
        </Container.Flex>

        {pending && (
          <Container.Flex
            fullWidth
            fullHeight
            styles={{ position: 'absolute' }}
            justify="center"
          >
            <Loader />
          </Container.Flex>
        )}
      </Container.Flex>
    </Card>
  )
}
