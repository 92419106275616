import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import persistStore from 'redux-persist/es/persistStore'
import { PersistGate } from 'redux-persist/integration/react'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-TQL58LR',
}

const serverType = process.env.REACT_APP_SERVER_TYPE

if (serverType === 'production') {
  TagManager.initialize(tagManagerArgs)
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

let persistor = persistStore(store)

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
)

reportWebVitals()
