import SVG from "../SVG";
import { IconProps } from "../types";

const EmployeeIcon = (props: IconProps) => {
    return (
        <SVG {...props} width={props.width ?? 22} height={props.height ?? 22}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 20C11.5155 20 12.0209 19.9567 12.5127 19.8734C12.2068 19.7024 12 19.3753 12 19L12 17.6344C12 15.052 9.96267 13 7.50001 13C5.667 13 4.07312 14.1324 3.37384 15.7815C4.96604 18.3156 7.78631 20 11 20ZM14 19C14 19.2001 13.9412 19.3864 13.84 19.5427C15.9489 18.842 17.7113 17.3822 18.8033 15.4873C17.9772 14.5679 16.8004 14 15.5 14C14.6935 14 13.937 14.2178 13.2816 14.6012C13.7412 15.5122 14 16.5443 14 17.6344L14 19ZM12.1194 12.9669C11.5893 12.4198 10.9663 11.9659 10.2755 11.6326C10.7298 11.0421 11 10.3026 11 9.5C11 7.567 9.433 6 7.5 6C5.567 6 4 7.567 4 9.5C4 10.3029 4.27033 11.0426 4.72493 11.6331C3.79728 12.0809 2.99169 12.7458 2.36956 13.5609C2.12909 12.7492 2 11.8897 2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11C20 11.8753 19.8751 12.7213 19.642 13.5214C19.2271 13.1708 18.7682 12.8715 18.2749 12.6334C18.7296 12.0428 19 11.303 19 10.5C19 8.567 17.433 7 15.5 7C13.567 7 12 8.567 12 10.5C12 11.303 12.2704 12.0428 12.7252 12.6334C12.5169 12.7339 12.3147 12.8453 12.1194 12.9669ZM11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22ZM7.5 8C6.67157 8 6 8.67157 6 9.5C6 10.3284 6.67157 11 7.5 11C8.32843 11 9 10.3284 9 9.5C9 8.67157 8.32843 8 7.5 8ZM14 10.5C14 9.67157 14.6716 9 15.5 9C16.3284 9 17 9.67157 17 10.5C17 11.3284 16.3284 12 15.5 12C14.6716 12 14 11.3284 14 10.5Z"
                fill="currentColor"
            />
        </SVG>
    );
};

export default EmployeeIcon;
