import { useEffect, useRef, useState } from 'react'
import { getProgressByStatus } from '../../helpers/getProgressByStatus'
import { OrderItem } from '../../store/orderSlice/types'
import OfferCard from '../Offer/OfferCard'
import OfferQrCode from '../Offer/OfferQrCode'
import OrderDevice from '../Offer/OrderDevice'
import { Container } from '../ui'

interface OrderShortItemProps {
  progress: number
  itemData: OrderItem
  currency: string
  showResult: boolean
  orderNumber: string
  withoutLoader?: true
}

const OrderShortItem = ({
  itemData,
  currency,
  showResult,
  withoutLoader
}: OrderShortItemProps) => {
  const [resize, setResize] = useState(false)
  const [showQrCOde, setSHowQrCode] = useState(false)
  const [height, setHeight] = useState(0)

  useEffect(() => {}, [showQrCOde, setResize]);

	const contentRef = useRef<HTMLDivElement>(null);

	const _resize = () => {
		if (contentRef.current) {
			setResize(true)
			setHeight(contentRef.current.offsetHeight)
		}
	}

	useEffect(_resize, [_resize])

  return (
    <OfferCard
      isLoading={false}
      progress={getProgressByStatus(itemData.status, 0.5)}
      resize={resize}
      isOpen={showQrCOde}
      withoutLoader={withoutLoader}
    >
      <Container.Flex
        fullWidth
        styles={{
          height: `${height}px`,
          transition: 'height 200ms ease-in, opacity 150ms ease-in 50ms',
          overflow: 'hidden',
        }}
				onTransitionEnd={_resize}
      >
        <Container.Flex
          ref={contentRef}
          fullWidth
        >
          <OrderDevice
            data={itemData}
            hidingChars
            currency={currency}
            showResult={showResult}
            withDelete={true}
            resize={resize}
            setResize={setResize}
            onClose={() => {
              _resize()
              setSHowQrCode(false)
            }}
            onOpen={() => {
              _resize()
              setSHowQrCode(true)
            }}
          />
          <Container.Flex fullWidth styles={{
						transition: 'all 200ms ease-in',
						opacity: showQrCOde ? 1 : 0
					}}>
            {!!showQrCOde && (
              <OfferQrCode
                hide={!showQrCOde}
                itemNumber={itemData.itemNumber}
                currency={currency}
                amount={`${itemData.price}`}
                status={itemData.status}
              />
            )}
          </Container.Flex>
        </Container.Flex>
      </Container.Flex>
    </OfferCard>
  )
}

export default OrderShortItem
