import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from ".."
import { ContactFromData } from "../../components/Order/OrderFinal/Ordering/OrderingContext"
import { GetContractDataResponse } from "../../types"

type ContractSlice = {
    [orderNumber: string] : {
        formData: ContactFromData | null,
        contractData: GetContractDataResponse | null
    }
}

const contractSlice = createSlice({
    name: 'contract',
    initialState: {} as ContractSlice,
    reducers: {
        setContractFormData(state, { payload } : PayloadAction<{ data: ContactFromData | null, orderNumber: string}>) {
            const { data, orderNumber } = payload;
            if (state[orderNumber]) {
                state[orderNumber].formData = data;
            } else {
                state[orderNumber] = {
                    formData: data,
                    contractData: null
                }
            }

        },
        setContractResponseData(state, { payload } : PayloadAction<{ data: GetContractDataResponse | null, orderNumber: string}>) {
            const { data, orderNumber } = payload;

            if (state[orderNumber]) {
                state[orderNumber].contractData = data;
            } else {
                state[orderNumber] = {
                    formData: null,
                    contractData: data
                }
            }
        }
    }
})

export const getContractData = (contractNumber?: string ) => (createSelector(
    (state: RootState) => state.contract,
    contract => contractNumber ? contract[contractNumber] : null
))

export const { 
    setContractFormData,
    setContractResponseData
} = contractSlice.actions

export default contractSlice.reducer;