import styled, { css } from "styled-components";
import { TextInputProps } from "./types";

export const commonCSS = css`
    width: 100%;
    height: 40px;
    background-color: #fff;
    backdrop-filter: blur(8px);
    border-radius: 12px;
    border: none;
    font-size: 16px;
    padding: 10px 12px 10px 16px;
    outline: none;
    &:focus {
        border: none;
    }
    &:active {
        border: none;
    }
`;

export const TextInput = styled.input<TextInputProps>`
    ${commonCSS};
    color: ${props => props.theme.colors.text.secondary}
`