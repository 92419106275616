export const formatPhoneValue = (value: string) => {

    const format = '+375 (__) ___ __ __';

    if (value.length < 7) return format


    const matched = (value.match(/[^_]+/)?.[0] || "");

    const letters = matched.split('');

    if (/ \d\d__$/.test(value)) {
        letters.pop()
    };

    if (/ \d{3}__ __/.test(value)) {
        letters.pop()
    }

    if (/\)___ __ __/.test(value)) {
        letters.pop();
        letters.pop()
    }

    letters.reverse()

    const result = format
        .split('')
        .map((el, idx) => { 
            const letter = letters.pop();

            if (!letter) return el;
            if (!/\d/.test(letter)) {
                return el
            }
            return letter ?? el
        })


    return result.join("")
}

const setCaretPosition = (target: any) => {
    const position = target.value.search(/_/);

    if (position < 0) {
        return 
    }

    if (target.setSelectionRange) {
        target.setSelectionRange(position, position)
    } else if (target.createTextRange) {
        var range = target.createTextRange();
        range.collapse(true);
        range.moveEnd('character', position);
        range.moveStart('character', position);
        range.select();
      }
}

export const onPhoneInputChange = (e: any) => {
    e.target.value = formatPhoneValue(e.target.value);
    setCaretPosition(e.target);
    return e.target.value
}


export const onPhoneInputFocus = (e: any) => {
    setCaretPosition(e.target)
}
