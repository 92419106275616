import { useTheme } from "styled-components";
import { styled } from "../../helpers/styled";
import { usePartnerDetail } from "../../pages/Partner";
import { Container, Typography } from "../ui";

const Label = styled.div`
  background-color: ${props => props.theme.colors.accent.default};
  padding: 4px 8px;
  border-radius: 20px;
`

export default function PartnerSpecify () {
  const { data } = usePartnerDetail();
  const theme = useTheme()

  if (!data) return null;

  return (
    <Container.Flex fullWidth maxWidth={312} alignItems="start" verticalGap={16}>
      <Typography.Title secondary margin={"0 4px"}>Категории товаров</Typography.Title>

      <Container.Flex direction="row" fullWidth wrapped gap={8}>
        {data.goodsCategories.map(cat => (
          <Label key={cat}>
            <Typography.Medium margin={0} color={theme.colors.text.contrast}>
              {cat}
            </Typography.Medium>
          </Label>
        ))}
      </Container.Flex>

      <Typography.Title margin={"16px 4px 0 4px"} secondary>Проводимые операции</Typography.Title>

      <Container.Flex direction="row" fullWidth wrapped gap={8}>
        {data.registrationTypes.map(type => (
          <Label key={type}>
            <Typography.Medium margin={0} color={theme.colors.text.contrast}>
              {type}
            </Typography.Medium>
          </Label>
        ))}
      </Container.Flex>
    </Container.Flex>
  )
}