import { useTheme } from 'styled-components'
import { ItemStatuses, Order } from '../../../store/orderSlice/types'
import OrderDevice from './OrderDevice'
import { Card, Container, Price, Typography } from '../../ui'

interface OrderItemsProps {
  order: Order
  editOrder: () => void
}

const OrderItems = ({ order, editOrder }: OrderItemsProps) => {
  const theme = useTheme()

  const { items, currencyLang, registration, amount, amountRansom, currency } =
    order

  const readyItems = items.filter(el => el.status === ItemStatuses.Confirm)

  const readyItemsAmout = readyItems.reduce((acc, next) => {
    if (registration) {
      if (registration === 'Трейд-ин') {
        return acc + +next.price
      } else {
        return acc + (+next.price * +amountRansom) / +amount
      }
    } else {
      return 0
    }
  }, 0)

  return (
    <Card fullWidth padding={28}>
      <Container.Flex verticalGap={16}>
        {readyItems.map(item => (
          <OrderDevice
            data={item}
            key={item.itemNumber}
            currency={currencyLang ?? currency}
          />
        ))}

        {!!registration && (
          <Container.Flex direction="row" fullWidth padding={'0 4px'}>
            <Typography.Medium
              color={theme.colors.text.tertiary}
              margin={'0 4px 0 0'}
            >
              {`${registration}: `}
            </Typography.Medium>
            <Price
              amount={readyItemsAmout}
              currency={currencyLang ?? currency}
            />
          </Container.Flex>
        )}
      </Container.Flex>
    </Card>
  )
}

export default OrderItems
