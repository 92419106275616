import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { useUploadFiles } from '../../../contexts/uploadFiles'
import { getAddImagesData } from '../../../helpers/getAddImagesData'
import {
    SendPhoto,
    setPauseQuestions,
} from '../../../store/offerSlice'
import { setOfferCardOpacity } from '../../../store/viewSlice'
import { Button, Container, FilesContainer, Info, Typography } from '../../ui'

export default function UploadImageSubmit() {
    const { files, setProcess } = useUploadFiles()
    const { orderNumber, itemNumber, checkList } = useParams()

    const theme = useTheme()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setOfferCardOpacity("1"));
    }, [dispatch])

    useEffect(() => {
        setProcess('Все фото загружены')
    }, [setProcess])

    const submit = useCallback(() => {
        dispatch(setPauseQuestions(false))
        dispatch(
                SendPhoto.request({
                    files: getAddImagesData(files),
                    itemNumber,
                    orderNumber,
                    isCopyItem: checkList ? true : undefined
                }),
            )
    }, [dispatch, setPauseQuestions, checkList, getAddImagesData])

    return (
        <Container.Flex fullWidth alignItems="start">
            <FilesContainer />

            <Typography.Title
                color={theme.colors.text.secondary}
                margin="16px 0 24px"
                padding="0 0 0 4px"
                textAlign="start"
            >
                Фото готовы к отправке
            </Typography.Title>

            <Info>
                <Typography.Main
                    styles={{
                        textAlign: 'start',
                        margin: 0,
                        marginBottom: '15px',
                    }}
                >
                    После проверки фото, отобразится финальная стоимость
                </Typography.Main>
            </Info>

            <Button fullWidth onClick={submit}>
                ОТПРАВИТЬ
            </Button>
        </Container.Flex>
    )
}
