import { all } from 'redux-saga/effects'
import offerSagas from './offerSlice/sagas'
import { userSagas } from './userSlice/sagas'
import orderSagas from './orderSlice/sagas'
import viewSagas from './viewSlice/sagas'
import apiSagas from './apiSlice/sagas'
import themeSagas from './themeSlice/sagas'

export default function* rootSaga() {
    yield all([
        userSagas(),
        offerSagas(),
        orderSagas(),
        viewSagas(),
        apiSagas(),
        themeSagas()
    ])
}