import { GivenAnswer, Question } from "../../../store/offerSlice/types";
import { isString } from "../../../store/types";

type CollectAnswerData = {
    questionData: Question,
    answerName: string
    answerId?: string
    alternateName?: isString
    fileName?: string
}

export const collectAnswerData = ({
    questionData,
    answerName,
    answerId,
    alternateName,
    fileName
} : CollectAnswerData ) => {
    const {
        questionId,
        questionGroup,
        questionName,
        questionShortName,
        questionKey,
        saveInOrder
    } = questionData;
    
    const givenAnswer: GivenAnswer = {
        saveInOrder,
        questionKey,
        questionId,
        questionGroup,
        questionName,
        questionShortName,
        answerId,
        answerName,
        fileName,
        alternateName: alternateName ?? null
    };

    return givenAnswer;
};
