import SVG from "../SVG";
import { IconProps } from "../types";

const ExitIcon = (props: IconProps) => {
    return (
        <SVG {...props} width={props.width ?? 21} height={props.height ?? 23}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.0005 22.0027C14.1051 22.0027 15.0005 21.1073 15.0005 20.0027V16.0027C15.0005 15.4504 14.5528 15.0027 14.0005 15.0027C13.4482 15.0027 13.0005 15.4504 13.0005 16.0027V20.0027H2.00049V2.00269H13.0005V6.00269C13.0005 6.55497 13.4482 7.00269 14.0005 7.00269C14.5528 7.00269 15.0005 6.55497 15.0005 6.00269V2.00269C15.0005 0.898117 14.1051 0.00268555 13.0005 0.00268555H2.00049C0.89592 0.00268555 0.000488281 0.898115 0.000488281 2.00269V20.0027C0.000488281 21.1073 0.895918 22.0027 2.00049 22.0027H13.0005ZM17.7071 7.29314C17.3166 6.90261 16.6834 6.90261 16.2929 7.29314C15.9024 7.68366 15.9024 8.31683 16.2929 8.70735L17.5858 10.0002L9 10.0002C8.44771 10.0002 8 10.448 8 11.0002C8 11.5525 8.44772 12.0002 9 12.0002H17.5858L16.2929 13.2931C15.9024 13.6837 15.9024 14.3168 16.2929 14.7074C16.6834 15.0979 17.3166 15.0979 17.7071 14.7074L20.7071 11.7073C20.8946 11.5198 21 11.2655 21 11.0002C21 10.735 20.8946 10.4807 20.7071 10.2931L17.7071 7.29314Z"
                fill="currentColor"
            />
        </SVG>
    );
};

export default ExitIcon;
