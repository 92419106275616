
import { ApiError, ResponseData } from '../store/types';
import { Session } from '../store/userSlice/types';

const toFormData = (
    obj: Record<any, any>,
    form?: FormData,
    nameSpace?: string,
) => {
    const fd = form || new FormData()
    let formKey

    for (const property in obj) {
        if (obj.hasOwnProperty(property)) {
            if (nameSpace) {
                formKey = nameSpace + `[${property}]`
            } else {
                formKey = property
            }

            if (obj?.[property]?.file && obj?.[property]?.name) {
                fd.append(formKey, obj[property].file, obj[property].name)
            } else {
                if (obj[property] instanceof Date) {
                    fd.append(formKey, obj[property].toISOString())
                } else if (
                    typeof obj[property] === 'object' &&
                    obj[property] !== null &&
                    !(obj[property] instanceof File)
                ) {
                    toFormData(obj[property], fd, formKey)
                } else {
                    fd.append(
                        formKey,
                        obj[property] === null ? '' : obj[property],
                    )
                }
            }
        }
    }
    return fd
}

export const collectFormData = (data: object, session: Session) : FormData => {

    const formData = toFormData(data);
    if (session.auth_param_name && session.auth_param_value) {
        formData.set(session.auth_param_name, session.auth_param_value);
    }

    return formData
}

const createApiError = (message: string) : ApiError => {
    return ({
        message,
        code: 500,
        customData: {
            showForm: "default"
        }
    })
}

export const getErrorResponse = (message?: string) : ResponseData<any> => {
    return {
        data: null,
        errors: [createApiError(message ?? "Неизвестная ошибка")],
        status: "error",
    }
}

export const collectParams = (session: Session, query: any) => {
    if (session.auth_param_name && session.auth_param_value) {
        query[session.auth_param_name] = session.auth_param_value
    }

    return query
}

