import { createContext, useContext, useState } from 'react'
import { ComponentProps } from '../types'

interface ShowDelete {
    onDelete: (() => void) | null
    title: string
    question: string
}

interface DeleteData extends ShowDelete {
    onCancel: () => void
}

interface IDeleteContext extends DeleteData {
    showDelete: (data: ShowDelete) => void
}

const getIntitalData = (): ShowDelete => ({
    onDelete: null,
    title: '',
    question: '',
})

const DeleteContext = createContext({
    ...getIntitalData(),
    showDelete: () => {},
    onCancel: () => {},
} as IDeleteContext)

export const useDelete = () => {
    return useContext(DeleteContext)
}

export default function DeleteWrapper({ children }: ComponentProps) {
    const [state, setState] = useState<ShowDelete>({
        onDelete: null,
        title: '',
        question: '',
    })

    const showDelete = (data: ShowDelete) => {
        setState(() => data)
    }

    const onCancel = () => setState(() => getIntitalData())

    return (
        <DeleteContext.Provider value={{ ...state, showDelete, onCancel }}>
            {children}
        </DeleteContext.Provider>
    )
}
