import {
  resetQuestions,
  setGivenAnswers,
  restoreOffer,
  setStepRequest,
} from './../offerSlice/index'
import { setAdditionalAction, setStep } from '../offerSlice'
import { customErrors } from './../../helpers/getCustomError'
import { PayloadAction } from '@reduxjs/toolkit'
import {
  CreateOrChangeOrder,
  GetOrder,
  GetItemStatus,
  setQrCode,
  DeleteItem,
  SetOrderRegistration,
  setShowResultFor,
  resetDeleteNumber,
  restoreOrderState,
  UpdateItemDate,
} from './index'
import {
  call,
  put,
  select,
  takeLatest,
  takeLeading,
  delay,
} from 'redux-saga/effects'
import {
  GetOrderRequest,
  Order,
  CreateOrderResponse,
  OrderRequest,
  DeleteItemResponse,
  SetRegistrationResponseData,
  ItemStatuses,
} from './types'
import { ResponseData } from '../types'
import { RootState } from '..'
import {
  hideOfferCard,
  redirectRequest,
  redirectTo,
  setPageVisible,
  showOfferCard,
} from '../viewSlice'
import handleError from '../helpers/handleError'
import Api, { URLS } from '../../apinew'
import { collectCreateOrChangeOrderData } from '../offerSlice/helpers/collectCreateOrderData'
import { collectGetOrderData } from './helpers/collectGetOrderData'
import { resetSavedOrderNumber } from '../savedData'
import { ApiErrorCodes } from '../../types'

const api = new Api()

function* createOrderWorker({ payload }: PayloadAction<OrderRequest<any>>) {
  const state: RootState = yield select()
  const session = state.user.session
  yield put(hideOfferCard())
  yield delay(200)

  const orderNumber = payload.orderNumber

  const data = collectCreateOrChangeOrderData(state, orderNumber)

  const response: CreateOrderResponse = yield call(
    orderNumber ? api.orderAddItem : api.createOrder,
    { data },
    session,
  )

  if (response.status === 'success') {
    yield put(CreateOrChangeOrder.success(response.data))
    const responseItemNumber = response.data?.itemNumber
    const responseOrderNumber = response.data?.number
    if (responseItemNumber && responseOrderNumber) {
      yield put(CreateOrChangeOrder.fulfill())
      yield put(
        redirectTo(`/order/${responseOrderNumber}/${responseItemNumber}/`),
      )
      return
    }
    yield put(setStep('summary'))
  }
  if (response.status === 'error') {
    yield handleError(response)
    yield put(CreateOrChangeOrder.failure(response.errors))
    yield setPageVisible(true)
  }
  yield put(showOfferCard())
  yield put(CreateOrChangeOrder.fulfill())
}

function* getOrderWorker({
  payload,
}: PayloadAction<GetOrderRequest | undefined>) {
  yield put(hideOfferCard())
  yield delay(200)
  yield put(showOfferCard())
  const state: RootState = yield select()
  const orderNumber = payload?.orderNumber ?? state.order.order.number
  const itemNumber = payload?.itemNumber ?? state.order.order.itemNumber
  const session = state.user.session
  const code = payload?.code ?? state.order.order.data?.registration
  const errors = []
  if (!orderNumber) errors.push(customErrors.noOrderId)

  if (errors.length) {
    yield put(GetOrder.failure(errors))
  } else {
    if (!orderNumber) return
    const response: ResponseData<Order> = yield call(
      api.getOrder,
      collectGetOrderData(orderNumber, itemNumber, code),
      session,
    )

    if (response.status === 'success') {
      // yield put(restoreOffer())
      yield put(
        GetOrder.success({
          data: response.data,
          itemNumber,
          checkList: payload?.checkList,
        }),
      )
    }

    if (response.status === 'error') {
      if (
        response.errors
          .map(el => el.code)
          .includes(ApiErrorCodes.OrderNotExitsts)
      ) {
        yield put(resetSavedOrderNumber())
      }
      yield put(GetOrder.failure(response.errors))
    }

    yield put(GetOrder.fulfill())
    yield put(setAdditionalAction())
    yield put(setPageVisible(true))
  }
}

function* getItemStatusRequestWorker({
  payload,
}: PayloadAction<OrderRequest<any>>) {
  const state: RootState = yield select()

  if (!state) return
  if (!state.order) return
  if (!state.order.order) return

  const session = state.user.session
  const step = state.offer.step

  const { itemNumber, orderNumber } = payload
  if (!orderNumber || !itemNumber) return
  const response: ResponseData<{ status: string }> = yield call(
    api.orderGetItemStatus,
    { number: orderNumber, itemNumber },
    session,
  )

  if (response.status === 'success') {
    yield put(GetItemStatus.success())

    if (response.data?.status === ItemStatuses.PhotoLoaded) {
      if (step !== 'summary') {
        yield put(setStepRequest('summary'))
      }
    }

    if (response.data?.status === ItemStatuses.Confirm) {
      yield put(hideOfferCard())
      delay(200)
      yield put(setShowResultFor(itemNumber))
      yield put(redirectRequest(`/order/${orderNumber}/`))
    }
  }
  if (response.status === 'error') {
    yield put(GetItemStatus.failure(response.errors))
  }

  yield put(GetItemStatus.fulfill())
}

function* getOrderSuccessWorker({
  payload,
}: PayloadAction<{ data: Order; itemNumber?: string; checkList?: string }>) {
  const state: RootState = yield select()
  const answers = state.offer.givenAnswers.answers

  const { qrCode } = state.order
  const orderData = payload.data
  const currentItem = orderData.items.find(
    item => item.itemNumber === payload.itemNumber,
  )
  if (currentItem) {
    yield put(resetQuestions())
    yield put(
      setGivenAnswers({
        combinationId: qrCode ? undefined : currentItem.combinationId,
        combinationCode: qrCode ?? currentItem.combinationCode,
        answers,
      }),
    )
    if (qrCode) {
      yield put(setQrCode())
      yield put(setStep('questions'))
      return
    }
    if (payload.checkList) {
      yield put(setStep('questions'))
      return
    }
    if (currentItem.status === 'F') {
      yield put(setStep('summary'))
    }
    if (currentItem.status === 'N') {
      yield put(setStep('prePrice'))
    }
  }
}

function* deleteItemWorker() {
  const state: RootState = yield select()
  const session = state.user.session
  const orderNumber = state.order.order.data?.number
  const itemNumber = state.order.deleteItem.itemNumber
  // const savedItemNumber = state.saved.orderNumber

  yield put(setPageVisible(false))
  yield delay(200)

  if (!orderNumber || !itemNumber) {
    yield put(DeleteItem.fulfill())
    return
  }

  const response: DeleteItemResponse = yield call(
    api.orderDeleteItem,
    { number: orderNumber, itemNumber },
    session,
  )

  yield put(DeleteItem.fulfill())
  yield put(resetDeleteNumber())

  if (response.status === 'success') {
    yield put(setPageVisible(false))
    yield put(DeleteItem.success(response.data))
  }

  if (response.status === 'error') {
    yield put(DeleteItem.failure(response.errors))
  }

  if (response.data?.order) {
    yield put(resetSavedOrderNumber())
    yield put(restoreOrderState())
    yield put(restoreOffer())
    yield put(setPageVisible(false))
    yield put(redirectTo('/create/'))
  } else {
    yield put(GetOrder.request({ orderNumber }))
    yield put(redirectTo(`/order/${orderNumber}/`))
  }
  yield delay(1000)
  yield put(setPageVisible(true))
}

function* setOrderRegistrationWorker({ payload }: PayloadAction<string>) {
  const state: RootState = yield select()
  const session = state.user.session
  const orderNumber = state.order.order.data?.number
  const code = payload

  if (!orderNumber) {
    yield put(SetOrderRegistration.failure(['Не найден заказ']))
    return
  }

  const response: ResponseData<SetRegistrationResponseData> = yield call(
    api.orderSetRegistration,
    { number: orderNumber, typeCode: code },
    session,
  )

  if (response.status === 'success') {
    yield put(SetOrderRegistration.success(response.data))
  }

  if (response.status === 'error') {
    yield put(SetOrderRegistration.failure(response.errors))
  }

  yield put(SetOrderRegistration.fulfill())

  if (response.status === 'success') {
    yield put(GetOrder.request({ orderNumber, code }))
  }
}

function* updateItemDataWorker({
  payload,
}: PayloadAction< {number: string, itemNumber: string }>) {
	const state: RootState = yield select();
	const session = state.user.session;
  const api = new Api();

	const response: ResponseData<Order> = yield call(api._post(URLS.getOrder), payload, session);

	if (response.status === 'success') {
		yield put(UpdateItemDate.success(response.data))
	}
}

export default function* orderSagas() {
  yield takeLeading(CreateOrChangeOrder.REQUEST, createOrderWorker)
  yield takeLeading(GetOrder.REQUEST, getOrderWorker)
  yield takeLeading(GetItemStatus.REQUEST, getItemStatusRequestWorker)
  yield takeLatest(GetOrder.SUCCESS, getOrderSuccessWorker)
  yield takeLeading(DeleteItem.REQUEST, deleteItemWorker)
  yield takeLeading(SetOrderRegistration.REQUEST, setOrderRegistrationWorker)
  yield takeLeading(UpdateItemDate.request, updateItemDataWorker)
}
