import { createGlobalStyle, useTheme } from 'styled-components'
import { Box, Container } from '../../../ui'
import { useOrderingData } from './OrderingContext'

const GlobalStyles = createGlobalStyle`
	.form-circle {
		width: 8px;
		height: 8px;
		transition: all 200ms ease-in;
		margin-right: 12px;
		border-radius: 4px;

		&:last-child {
			margin-right: 0;
		}
	}
`

export default function OrderFormPagination() {
  const { step } = useOrderingData()
  const theme = useTheme()

  return (
    <Container.Flex direction="row" fullWidth justify="center">
      <GlobalStyles />
      <Box
        className="form-circle"
        style={{
          backgroundColor:
            step === 'clientInfo'
              ? theme.colors.accent.default
              : theme.colors.icon.quaternary,
        }}
      />
      <Box
        className="form-circle"
        style={{
          backgroundColor:
            step === 'documentInfo'
              ? theme.colors.accent.default
              : theme.colors.icon.quaternary,
        }}
      />
      <Box
        className="form-circle"
        style={{
          backgroundColor:
            step === 'address'
              ? theme.colors.accent.default
              : theme.colors.icon.quaternary,
        }}
      />
    </Container.Flex>
  )
}
