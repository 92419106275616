import { Fragment, useState } from 'react'
import Button from '../ui/Button'
import Input from '../ui/Input'
import Container from '../ui/Container'
import Card from '../ui/Card'
import Typography from '../ui/Typography'
import { useApi } from '../../hooks/useApi'
import {
    ChangePasswordSuccessData,
    ChangePasswordRequestData,
} from '../../store/userSlice/types'
import { URLS } from '../../apinew'

const VALIDATE_ERROR = 'Пароль должен состоять минимум из 5-ти символов'
const NO_MATCH_ERROR = 'Пароли не совпадают'

const ChangePassword = () => {
    const [password, setPassword] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [passwordConfirmError, setPasswordConfirmError] = useState('')
    const [noMatchError, setNoMatchError] = useState('')
    const { pending, errors, apiPost, data } = useApi()

    const result = data as ChangePasswordSuccessData | null

    const validatePassword = (pass: string) => pass.length >= 5

    const onSubmit = () => {
        let error = false
        if (!validatePassword(password)) {
            setPasswordError(VALIDATE_ERROR)
            error = true
        }

        if (!validatePassword(passwordConfirm)) {
            setPasswordConfirmError(VALIDATE_ERROR)
            error = true
        }

        if (password !== passwordConfirm) {
            setNoMatchError(NO_MATCH_ERROR)
        }

        if (error) return

        const data = {
            newPassword: password,
        } as ChangePasswordRequestData
        apiPost(URLS.changePassword, data, true)
    }

    const onPasswordChange = (e: any) => {
        setPasswordError('')
        setPassword(e.target.value)
    }

    const onPasswordConfirmChange = (e: any) => {
        setPasswordConfirmError('')
        setPasswordConfirm(e.target.value)
    }

    return (
        <Card
            noShadow
            padding={28}
            styles={{
                width: 'auto',
                margin: 'auto',
                transition: 'all 200ms ease-in',
            }}
        >
            <Container.Flex
                verticalGap={16}
                fullWidth
                fullHeight
                justify="center"
                styles={{ opacity: pending ? 0 : 1 }}
            >
                {!result && (
                    <Fragment>
                        <Input
                            value={password}
                            onChange={onPasswordChange}
                            fullWidth
                            error={passwordError}
                            placeholder="Новый пароль"
                            type="password"
                            secure={true}
                        />

                        <Input
                            value={passwordConfirm}
                            onChange={onPasswordConfirmChange}
                            error={passwordConfirmError}
                            fullWidth
                            placeholder="Повторите пароль"
                            type="password"
                            secure={true}
                        />

                        <Button pending={pending} fullWidth onClick={onSubmit}>
                            ИЗМЕНИТЬ ПАРОЛЬ
                        </Button>
                    </Fragment>
                )}

                {(!!errors.length || noMatchError) && (
                    <Typography.Error>
                        {[...errors, noMatchError].join('. ')}
                    </Typography.Error>
                )}

                {!!result && (
                    <Fragment>
                        <Typography.Main>{result.MESSAGE}</Typography.Main>

                        <Button link="/" fullWidth>
                            {result.TYPE}
                        </Button>
                    </Fragment>
                )}
            </Container.Flex>
        </Card>
    )
}

export default ChangePassword
