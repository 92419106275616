import { useTheme } from 'styled-components'
import { Button, Card, Container, Info, Price, Typography } from '../ui'
import { ItemStatuses, Order } from '../../store/orderSlice/types'
import { useMemo } from 'react'
import useSetRegistrationData from '../../hooks/useSetRegistrationData'
import OfferLoader from './OfferLoader'
import { useSession } from '../../hooks/useSession'
import { bp } from '../../theme/media'

interface OfferOrderingProps {
  orderData: Order
}

const Line = ({
  label,
  amount,
  currency,
  text,
}: {
  label: string
  amount?: number
  currency?: string
  text?: string
}) => {
  const theme = useTheme()

  return (
    <Container.Flex direction="row" justify="start" fullWidth>
      <Typography.Medium color={theme.colors.text.tertiary} margin="0 4px 0 0">
        {label}
      </Typography.Medium>

      <Price amount={amount} currency={currency} text={text} />
    </Container.Flex>
  )
}

const ProcessedButton = (props: {
  value: string
  outline: boolean
  onClick: () => void
}) => {
  const { value, outline, onClick } = props

  return (
    <Button
      fullWidth
      variant={outline ? 'outline' : 'primary'}
      onClick={onClick}
    >
      {value}
    </Button>
  )
}

const OfferOrdering = ({ orderData }: OfferOrderingProps) => {
  const theme = useTheme()

  const { isAdmin } = useSession()

  const { amount, amountRansom, items, currency, currencyLang, button } =
    orderData

	const readyItems = items.filter(item => item.status === ItemStatuses.Confirm)

	const readyItemsCount = readyItems.length;
	const readyItemsAmount = readyItems.reduce((acc, next) => acc + +next.price, 0);
	const readtItemsAmountRansom = ((+amountRansom / +amount) * readyItemsAmount);

  const { loading, setRegistration } = useSetRegistrationData()

  const description = useMemo(
    () => (button ? button.map(el => el.description) : []),
    [button],
  )

  return (
    <Card
      padding={28}
      styles={{ maxWidth: '100% !important', marginTop: 'auto' }}
      fullWidth
    >
      <Container.Flex
        fullWidth
        alignItems="start"
        gap={16}
        justify="between"
        direction="row"
        breakpoints={{
          750: {
            flexDirection: 'column',
          },
        }}
      >
        <Container.Flex
          alignItems="start"
          verticalGap={16}
          styles={{ width: isAdmin ? '246px' : '592px' }}
          breakpoints={{
            [bp.table]: {
              width: '256px',
            },
            [bp.mobile]: {
              width: '100%',
            },
          }}
        >
          <Typography.Title
            color={theme.colors.text.secondary}
            margin="0 0 8px"
            padding="0 4px"
          >
            Оформление
          </Typography.Title>

          <Container.Flex verticalGap={8} padding="0 4px" fullWidth>
            <Line
              label="Количество: "
              text={readyItemsCount ? ` ${readyItemsCount} шт` : ' не указана'}
            />
            <Line
              label="Скидка по «Трейд-ин»: "
              amount={readyItemsAmount}
              currency={currencyLang || currency}
            />

            <Line
              label="Цена для «Выкупа»: "
              amount={readtItemsAmountRansom}
              currency={currencyLang || currency}
            />
          </Container.Flex>

          {button && (
            <Container.Flex
              fullWidth
              direction={isAdmin ? 'column' : "row"}
              gap={16}
              breakpoints={{
                [bp.table]: {
                  flexDirection: 'column',
                },
              }}
            >
              {button.map((el, i) => (
                <ProcessedButton
                  value={el.value}
                  key={i}
                  outline={i % 2 === 1}
                  onClick={() => setRegistration(el.code)}
                />
              ))}
            </Container.Flex>
          )}
        </Container.Flex>

        <Container.Flex
          alignItems="start"
          styles={{ width: '288px' }}
          breakpoints={{
            [bp.table]: {
              width: '256px',
            },
            660: {
              width: '100%',
            },
          }}
        >
          <Info parts={description}></Info>
        </Container.Flex>
      </Container.Flex>

      {loading && <OfferLoader minHeight="100%" />}
    </Card>
  )
}

export default OfferOrdering
