import { useState } from "react";
import Button from "../ui/Button";
import Input from "../ui/Input";
import Container from "../ui/Container";
import Card from "../ui/Card";
import Typography from "../ui/Typography";
import { useAuthData } from "../../contexts/auth";

const Login = () => {
    const [loginValue, setLoginValue] = useState("");
    const [password, setPassword] = useState("");

    const { login, errors, pending } = useAuthData();

    const onSubmit = () => {

        login({login: loginValue, password: password})

    };

    return (
        <Card
            noShadow
            padding={28}
            styles={{
                width: "auto",
                margin: "auto",
            }}
        >
            <Container.Flex
                verticalGap={16}
                fullWidth
                fullHeight
                justify="center"
            >
                <Input
                    value={loginValue}
                    onChange={(e) => setLoginValue(e.target.value)}
                    fullWidth
                    placeholder="Логин или email"
                />

                <Input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                    secure={true}
                    placeholder="Пароль"
                />

                <Typography.Link
                    textAlign="end"
                    fullWidth
                    href="/restore-password/"
                    styles={{
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: "4",
                    }}
                >
                    Вспомнить пароль?
                </Typography.Link>
                <Button
                    withLoader
                    pending={pending}
                    onClick={onSubmit}
                    square
                    fullWidth
                    submit
                >
                    Войти
                </Button>

                {!!errors.login.length && (
                    <Typography.Error>
                        {errors.login.map(err => err.message)
                            .join(". ")}
                    </Typography.Error>
                )}
            </Container.Flex>
        </Card>
    );
};

export default Login;
