import { useTheme } from 'styled-components'
import {
  Card,
  Container,
  Icon,
  Image,
  RublesWithCentes,
  Typography,
} from '../../ui'
import StatusImage from '../../ui/Image/StatusImage'
import {
  OrderListData,
  OrderListItem as OrderListItemType,
} from './OrderListContext'
import styled from 'styled-components/macro'

interface OrderListItemProps {
  data: OrderListData
}

interface ItemProps {
  data: OrderListItemType
  currency: string
}

const ItemGrid = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-template-rows: minmax(20px, max-content);
  align-items: start;
  grid-gap: 8px 24px;
  row-gap: 12px;

  @media (max-width: 660px) {
    grid-template-columns: auto 1fr 36%;
    grid-gap: 0;
    row-gap: 16px;
  }
`

const Item = ({ data, currency }: ItemProps) => {
  const theme = useTheme()

  return (
    <Container.Flex
      direction="row"
      alignItems="start"
      fullWidth
    >
      <Container.Flex
        styles={{ width: '40px', height: '40px' }}
        margin={'0 16px 0 0'}
        breakpoints={{
          660: {
            width: '60px',
            height: '60px',
          },
        }}
      >
        {data.image && (
          <Image
            src={data.image}
            alt={data.itemNumber}
            fullWidth
            fullHeight
            styles={{ borderRadius: '8px' }}
          />
        )}
        {!data.image && <Icon name="photo" fullHeight fullWidth />}
      </Container.Flex>
      <Container.Flex alignItems="stretch" styles={{ width: 'calc(100% - 56px)'}} verticalGap={4}>
        <Typography.Title start margin={'0 0 0 0'}>
          {data.name}
        </Typography.Title>

        <Container.Flex
          direction="row"
          alignItems="stretch"
          verticalGap={4}
          breakpoints={{
            660: {
              flexFlow: 'column',
            },
          }}
        >
          {data.answers &&
            Object.entries(data.answers).map(([key, value]) => (
              <Container.Flex
                direction={'row'}
                styles={{ marginRight: '12px' }}
                key={key}
                alignItems="stretch"
                horizontalGap={4}
                breakpoints={{
                  660: {
                    marginRight: '0',
                  },
                }}
              >
                <Typography.Small
                  start
                  margin={'0 0 0 0'}
                  styles={{
                    color: theme.colors.text.tertiary,
                  }}
                >
                  {key}
                </Typography.Small>
                <Typography.Small fullHeight start margin={0}>
                  {value}
                </Typography.Small>
              </Container.Flex>
            ))}
          <Container.Flex direction="row" horizontalGap={4}>
            <StatusImage status={data.status} noMargin/>
            <Typography.Small textAlign="start">{`${data.price} ${currency}`}</Typography.Small>
          </Container.Flex>
        </Container.Flex>
      </Container.Flex>
    </Container.Flex>
  )
}

export default function OrderListItem({ data }: OrderListItemProps) {
  const theme = useTheme()
  return (
    <Card padding={28} fullWidth margin={'0 0 24px 0'} noMaxWidth>
      <ItemGrid>
        <Container.Flex
          margin={'0 4px -1px 4px'}
          styles={{
            height: '20px',
            rowGap: '10px',
            borderBottom: '2px dotted ' + theme.colors.text.secondary
          }}
          justify="center"
          direction="row"
          alignItems="start"
        >
          <Typography.Title
            link={`/order/${data.number}/`}
            margin={0}
            start
          >
            {data.number.toLowerCase()}
          </Typography.Title>
        </Container.Flex>
        <Typography.Medium
          withIndicator
          margin={0}
          indicatorColor={data.statusColor}
          color={data.statusColor}
          styles={{
            gridColumn: '3 / 4',
            gridRow: '1 / 2',
            justifyContent: 'flex-end',
          }}
          breakpoints={{
            660: {
              gridColumn: '2 / 4',
              gridRow: '1 / 2',
              marginRight: '4px',
            },
          }}
        >
          {data.status}
        </Typography.Medium>
        <Container.Flex
          margin={0}
          styles={{
            gridColumn: '2 / 3',
            gridRow: '1 / 3',
            rowGap: '12px',
          }}
          breakpoints={{
            660: {
              gridColumn: '1 / 4',
              gridRow: '2 / 3',
              rowGap: '16px'
            },
          }}
        >
          {data.items &&
            data.items.map(item => (
              <Item
                data={item}
                key={item.itemNumber}
                currency={data.currency}
              />
            ))}
        </Container.Flex>
        <Container.Flex
          direction="row"
          justify="end"
          styles={{
            gridColumn: '3 / 4',
            gridRow: '2 / 3',
            marginTop: '-4px',
          }}
          breakpoints={{
            660: {
              justifyContent: 'flex-start',
              marginTop: 0,
              gridColumn: '1 / 3',
              gridRow: '3 / 4',
              marginLeft: '4px',
            },
          }}
        >
          {!!data.registration && (
            <Typography.Medium
              margin={'0 4px 0 0'}
              textAlign="start"
              styles={{
                color: theme.colors.text.tertiary,
              }}
            >
              {data.registration}:
            </Typography.Medium>
          )}
          <RublesWithCentes price={data.amount} currency={data.currency} />
        </Container.Flex>
        <Typography.Micro
          secondary
          margin={0}
          styles={{
            gridColumn: '1 / 2',
            gridRow: '2 / 3',
          }}
          breakpoints={{
            660: {
              gridColumn: '3 / 4',
              gridRow: '3 / 4',
              textAlign: 'right',
              marginRight: '4px',
              marginTop: '6px'
            },
          }}
        >
          {data.dateCreate}
        </Typography.Micro>
      </ItemGrid>
    </Card>
  )
}
