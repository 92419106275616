import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Links, LowerMenuItem } from './types'
import LowerMenuContent from './LowerMenuContent'

const link: {[k: string] : LowerMenuItem } = {
  estimate: {
    icon: 'mobile',
    title: 'Оценить смартфон',
    link: '/create/'
  },
  addDevice: {
    icon: 'add-device',
    title: 'Добавить уст-во',
    link: 'handler/add-device'
  },
  saveOrder: {
    icon: 'save-order',
    title: "Сохранить оценку",
    link: 'handler/save-order'
  },
  choosePartner: {
    icon: 'leaf',
    title: 'Выбрать партнера',
    link: '/partners/'
  },
  mapPartners: {
    icon: 'map',
    title: "Партнёры на карте",
    link: '/partners/map/'
  }
}

const links: Links = {
  main: [ link.estimate, link.choosePartner ],
  partners: [ link.estimate, link.mapPartners ],
  create: [],
  order: [ link.addDevice, link.choosePartner, link.saveOrder ]
}

const getLinkByPathname = (pathname: string) => {

  if (/^\/create\//.test(pathname)) {
    return links.create
  }

  if (/^\/order\/.*/.test(pathname)) {
    return links.order
  }

  switch (pathname) {
    case '/':
      return links.main
    case '/partners/':
      return links.partners
    default:
      return [];
  }
}

const LowerMenu = () => {
  const [items, setItems] = useState<LowerMenuItem[]>([]);
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;

    setItems(getLinkByPathname(pathname));
  }, [location])

  if (!items.length) return null;

  return <LowerMenuContent items={items} />

}

export default LowerMenu
