import { call, takeLeading, put, select } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { DefaultTheme } from 'styled-components'
import { getTheme } from '.'
import { RootState } from '..'
import Api from '../../apinew'
import { ResponseData } from '../types'

const api = new Api()

function* getThemeWork({ payload }: PayloadAction<number>) {
    const state: RootState = yield select();
    const session = state.user.session;
    yield put(getTheme.trigger())
    const response: ResponseData<{ theme: DefaultTheme }> = yield call(
        api.getTheme,
        session,
    )
    if (response.status === 'success') {
        yield put(getTheme.success(response.data?.theme))
    }
    if (response.status === 'error') {
        yield put(getTheme.failure(response.errors))
    }
    yield put(getTheme.fulfill())
}

export default function* themeSagas() {
    yield takeLeading(getTheme.REQUEST, getThemeWork)
}
