import { Card, Container } from "../../../ui";
import OrderingFormPart from "./OrderingFormPart";
import { createGlobalStyle, keyframes } from 'styled-components'
import { useRef, useState } from "react";

export const formClassNames = {
    hideToLeft: 'hide-to-left',
    hideToRight: 'hide-to-right',
    showFromLeft: 'show-from-left',
    showFromRight: 'show-from-right',
}

export const TRANSITION_TIME = 200;

export type FormClassNames = typeof formClassNames[keyof typeof formClassNames];

const showFromRight = keyframes`
    0% {
        transform: translateX(100%);
        visibility: 0
    }
    100% {
        transform: translateX(0);
        visibility: 1
    }
`

const showFromLeft = keyframes`
    0% {
        transform: translateX(-100%);
        visibility: 0
    }
    100% {
        transform: translateX(0);
        visibility: 1
    }
`

const hideToRight = keyframes`
    0% {
        transform: translateX(0%);
        visibility: 1
    }
    100% {
        transform: translateX(100%);
        visibility: 0
    }
`

const hideToLeft = keyframes`
    0% {
        transform: translateX(0%);
        visibility: 1;
    }
    100% {
        transform: translateX(-100%);
        visibility: 0;
    }
`

const GlobalStyles = createGlobalStyle`
    .${formClassNames.hideToLeft} {
        animation: ${hideToLeft} ${TRANSITION_TIME}ms ease-in 0s;
        transform: translateX(-100%);
        opacity: 0;
        transition: opacity ${TRANSITION_TIME}ms 50ms ;
    }
    .${formClassNames.hideToRight} {
        animation: ${hideToRight} ${TRANSITION_TIME}ms ease-in 0s;
        transform: translateX(100%);
        opacity: 0;
        transition: opacity ${TRANSITION_TIME}ms 50ms ;
    }
    .${formClassNames.showFromLeft} {
        animation: ${showFromLeft} ${TRANSITION_TIME}ms ease-in 50ms;
        transform: translateX(0);
        opacity: 1;
        transition: opacity ${TRANSITION_TIME}ms 50ms ;
    }
    .${formClassNames.showFromRight} {
        animation: ${showFromRight} ${TRANSITION_TIME}ms ease-in 50ms;
        transform: translateX(0);
        opacity: 1;
        transition: opacity ${TRANSITION_TIME}ms 50ms ;
    }
`

const PADDING = 28;

export default function OrderingFormWrapper () {

    const contentRef = useRef<HTMLDivElement>(null);

    const [height, setHeight] = useState(0);

    const resize = () => {
        if (contentRef.current) {
            setHeight(contentRef.current.offsetHeight + PADDING * 2);
        }
    }

    return (
        <Card  fullWidth padding={PADDING} maxContent styles={{
            transition: 'height 200ms ease-in !important',
            height: `${height}px !important`,
            overflow: 'hidden'
        }}>
            <GlobalStyles />
            <Container.Flex fullWidth ref={contentRef}>
            <OrderingFormPart resize={resize}/>
            </Container.Flex>
        </Card>
    )
}