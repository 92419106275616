import { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import Api, { URLS } from "../apinew";
import { ApiError } from "../store/types";
import { redirectRequest } from "../store/viewSlice";
import { useSession } from "./useSession";

type DeleteOrderRequest = {
  number: string
}

export const useDeleteOrder = () => {

  const { session } = useSession()

  const dispatch = useDispatch()

  const [status, setStatus] = useState<"success" | 'error' | null>(null);
  const [pending, setPending] = useState(false);
  const [errors, setErrors] = useState<ApiError[]>([]);

  useEffect(() => {
    if (status === 'success') {
      dispatch(redirectRequest('/'))
    }
  }, [status, dispatch])

  const onDeleteOrder = useCallback((number?: string) => () => {
    if (!number) {
      setErrors([{
        message: "Отсутствует номер заказа",
        customData: null,
        code: 500
      }])
    }
    const api = new Api();
    setPending(true);
    api._post(URLS.deleteOrder)({ number } as DeleteOrderRequest, session).then(response => {
      if (response.status === 'success') {
        setStatus('success');
      }

      if (response.status === 'error') {
        setStatus('error');
        setErrors(response.errors);
      }

      setPending(false)
    }).catch((error: any) => {
      setErrors([{message: error.message, code: 500, customData: null}])
    })
  }, [])

  const error = errors.map(el => el.message).reduce((acc, next) => acc + next, "");

  return {
    error,
    onDeleteOrder,
    status,
    pending,
    setStatus
  }
}