import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  useForm,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { URLS } from '../../../../apinew'
import {
  formatPhoneValue,
  onPhoneInputChange,
  onPhoneInputFocus,
} from '../../../../helpers/formatPhoneValue'
import { useApi } from '../../../../hooks/useApi'
import {
  setContractFormData,
  setContractResponseData,
} from '../../../../store/contractSlice'
import {
  GetContractDataResponse,
  GetSalePointsResponse,
} from '../../../../types'
import { Loader, Typography } from '../../../ui'
import {
  formClassNames,
  FormClassNames,
  TRANSITION_TIME,
} from './OrderingFormWrapper'

//SETTINGS

const patterns = {
  required: /^.+/,
  phone: /\+\d{3} \(\d{2}\) \d{3} \d{2} \d{2}/,
}

export const documents = {
  passport: {
    name: 'Паспорт РБ',
    value: 'passport',
  },
  idCard: { name: 'ID карта', value: 'idCard' },
  residentCard: { name: 'Вид на жительство', value: 'residentCard' },
  refugee: { name: 'Удостоверение беженца', value: 'refugee' },
}

const orderingState = {
  clientInfo: {
    lastName: '',
    firstName: '',
    secondName: '',
    phone: '',
    location: '',
  },
  documentInfo: {
    document: documents.passport,
    documentId: '',
  },
  address: {
    sailPointId: '',
  },
}

// TYPES

type OrderFieldsType = 'text' | 'select' | 'date' | 'number'

export type OrderingFormStep = keyof typeof orderingState

export type OrderingField<T> = {
  placeHolder?: string
  type: OrderFieldsType
  pattern?: RegExp
  key: keyof T
  onChange?: (e: any) => any
  onFocus?: (e: any) => void
  defaultValue?: any
  length?: number
  options?: Array<{
    name: string
    value: string
  }>
}

export type FormPart<T> = {
  title: string
  step: OrderingFormStep
  fields: Array<OrderingField<T>>
  getDependentFields?: (v: any) => OrderingField<any>
  buttons: Array<{
    text: string
    fullWidth?: true
    canBeDisabled: boolean
    variant: 'outline' | 'primary'
    onClick: () => void
    submit?: true
  }>
}

export type ContactFromData = {
  lastName: string
  firstName: string
  secondName: string
  phone: string
  location: string
  document: string
  documentId: string
  salePointId: string
}

export const clientInfo: FormPart<typeof orderingState.clientInfo> = {
  title: 'Информация о клиенте',
  step: 'clientInfo',
  fields: [
    {
      placeHolder: 'Фамилия',
      type: 'text',
      key: 'lastName',
      pattern: patterns.required,
    },
    {
      placeHolder: 'Имя',
      type: 'text',
      key: 'firstName',
      pattern: patterns.required,
    },
    {
      placeHolder: 'Отчество',
      type: 'text',
      key: 'secondName',
      pattern: patterns.required,
    },
    {
      defaultValue: formatPhoneValue(''),
      type: 'text',
      key: 'phone',
      onChange: onPhoneInputChange,
      onFocus: onPhoneInputFocus,
      pattern: patterns.phone,
    },
    {
      placeHolder: 'Город/посёлок, улица, д., кв.',
      type: 'text',
      key: 'location',
      pattern: patterns.required,
    },
  ],
  buttons: [
    {
      text: 'Далее',
      variant: 'primary',
      onClick: () => {},
      fullWidth: true,
      canBeDisabled: true,
    },
  ],
}

export const documentInfo: FormPart<typeof orderingState.documentInfo> = {
  title: 'Документ клиента',
  step: 'documentInfo',
  fields: [
    {
      key: 'document',
      defaultValue: documents.passport,
      type: 'select',
      options: Object.values(documents),
    },
    {
      key: 'documentId',
      placeHolder: 'Идентификационный номер',
      type: 'text',
      pattern: patterns.required,
    },
  ],
  buttons: [
    {
      text: 'Назад',
      variant: 'outline',
      onClick: () => {},
      canBeDisabled: false,
    },
    {
      text: 'Далее',
      variant: 'primary',
      onClick: () => {},
      canBeDisabled: true,
    },
  ],
}

export const address: FormPart<typeof orderingState.address> = {
  title: 'Адрес торгового объекта',
  step: 'address',
  fields: [
    {
      type: 'select',
      key: 'sailPointId',
      placeHolder: 'Адрес',
    },
  ],
  buttons: [
    {
      text: 'Назад',
      variant: 'outline',
      fullWidth: true,
      onClick: () => {},
      canBeDisabled: false,
    },
    {
      text: 'ПЕЧАТЬ ДОГОВОРА',
      variant: 'primary',
      fullWidth: true,
      onClick: () => {},
      canBeDisabled: true,
      submit: true,
    },
  ],
}

const _OrderingContext = createContext(
  {} as {
    formState: typeof orderingState
    onChangeStep: (step: OrderingFormStep) => void
    formPart: FormPart<any>
    step: OrderingFormStep
    register: UseFormRegister<any>
    errors: {
      [x: string]: any
    }
    formPartValidated: boolean
    watch: UseFormWatch<any>
    setValue: UseFormSetValue<any>
    getValues: UseFormGetValues<any>
    className: FormClassNames
    setClassName: (v: FormClassNames) => void
    opacity: number
  },
)

export const useOrderingData = () => {
  return useContext(_OrderingContext)
}

export default function OrderingContext({ children }: { children: ReactNode }) {
  const { orderNumber } = useParams()
  const { data, errorString, pending, apiPost, post } = useApi()
  const disptach = useDispatch()

  const [formState] = useState<typeof orderingState>({
    ...orderingState,
  })
  const [opacity, setOpacity] = useState(1)

  const linkRef = useRef<HTMLAnchorElement>(null)

  useEffect(() => {
    if (!pending && !errorString) {
      apiPost(URLS.getSalePoints, {})
    }
  }, [apiPost, pending, errorString])

  const [className, setClassName] = useState<FormClassNames>(
    formClassNames.showFromRight,
  )

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues,
  } = useForm()
  const [step, setStep] = useState<OrderingFormStep>('clientInfo')

  const onChangeStep = useCallback(
    (newStep: OrderingFormStep) => () => {
      setTimeout(() => {
        setOpacity(0)
      }, 50)
      const keys = Object.keys(orderingState)
      const nextStep =
        keys.findIndex(el => el === newStep) > keys.findIndex(el => el === step)
      if (nextStep) {
        setClassName(formClassNames.hideToLeft)
      } else {
        setClassName(formClassNames.hideToRight)
      }
      setTimeout(() => {
        setOpacity(1)
        if (nextStep) {
          setClassName(formClassNames.showFromRight)
        } else {
          setClassName(formClassNames.showFromLeft)
        }
        setStep(newStep)
      }, TRANSITION_TIME)
    },
    [step, setStep],
  )

  const formPart = useMemo(() => {
    switch (step) {
      case 'address':
        const _adress = { ...address }
        if (data) {
          const responseData: GetSalePointsResponse = data
          _adress.fields[0].options = responseData.map(el => ({
            name: el.name,
            value: el.id,
          }))
          _adress.fields[0].defaultValue = responseData.find(el => el.default)
        }
        _adress.buttons[0].onClick = onChangeStep('documentInfo')
        return address
      case 'documentInfo':
        const _documentIfno = { ...documentInfo }
        _documentIfno.buttons[0].onClick = onChangeStep('clientInfo')
        _documentIfno.buttons[1].onClick = onChangeStep('address')
        return documentInfo
      default:
        const _clientInfo = { ...clientInfo }
        _clientInfo.buttons[0].onClick = onChangeStep('documentInfo')
        return _clientInfo
    }
  }, [step, data])

  const onSubmit = async (data: any) => {
    const response = await post(URLS.getContractData, {
      number: orderNumber,
      salePointId: data.sailPointId,
    })

    if (response.data && orderNumber) {
      const responseData: GetContractDataResponse = response.data
      disptach(setContractFormData({ data, orderNumber }))
      disptach(setContractResponseData({ data: responseData, orderNumber }))
      if (linkRef.current) {
        linkRef.current.click()
      }
    }
  }

  return (
    <_OrderingContext.Provider
      value={{
        formState,
        onChangeStep,
        step,
        formPart,
        register,
        errors,
        watch,
        formPartValidated: false,
        setValue,
        getValues,
        className,
        setClassName,
        opacity,
      }}
    >
      {errorString && <Typography.Error>{errorString}</Typography.Error>}
      {pending ? (
        <Loader />
      ) : (
        <form
          style={{
            width: '100%',
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Link
            target={'_blank'}
            to={`/contract/${orderNumber}/`}
            style={{ display: 'none' }}
            ref={linkRef}
          />
          {children}
        </form>
      )}
    </_OrderingContext.Provider>
  )
}
