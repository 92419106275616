import { useOfferData } from '../../hooks/useOfferData'
import Container from '../ui/Container'
import { Typography } from '../ui'
import { useEffect, useMemo, useState } from 'react'
import OfferDevice from './OfferDevice'
import { OfferQuestions } from '.'
import OfferCard from './OfferCard'
import OfferSummary from './OfferSummary'
import { useLocation, useParams } from 'react-router-dom'
import OfferPrePrice from './OfferPrePrice'
import OrderDevice from './OrderDevice'
import OfferErrorForm from './OfferErrorForm/index'
import { useSelector } from 'react-redux'
import { getUserData } from '../../store/userSlice'
import { UserType } from '../../store/userSlice/types'
import OfferSearch from './OfferSearch'
import OfferQrCode from './OfferQrCode'
import OfferCheckList from './OfferCheckList'

const Offer = () => {
  const { orderNumber, itemNumber } = useParams()
  const location = useLocation()
  const user = useSelector(getUserData)

  const {
    step,
    deviceInfo,
    questionsData,
    givenAnswers,
    getQuestions,
    progress,
    question,
    isLoading,
    changeContent,
    giveAnswer,
    currentItem,
    orderData,
    changeStep,
    resetQr,
    showResultFor,
    errorForm,
    errorFormPending,
    errorFormSubmitData,
    pauseQuestions,
  } = useOfferData({ orderNumber, itemNumber })

  const [resize, setResize] = useState(false)
  const [showSearch, setShowSearch] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (location.pathname.startsWith('/create/')) {
      setShowSearch(
        user.userData?.userType === UserType.employee ||
          user.userData?.userType === UserType.partner,
      )
    }
  }, [user.userData, setShowSearch, location])

  useEffect(() => {
    setResize(true)
  })

  const showOfferDevice = location.pathname.startsWith('/create/')

  const shoudSearchRemove = useMemo(() => {
    return givenAnswers.answers.length > 0 || step === 'check-list'
  }, [givenAnswers, step])

  return (
    <Container.Flex
      verticalGap={24}
      alignItems="stretch"
      padding="0 0 24px"
    >
      <Container.Flex
      fullWidth
        gap={34}
        direction="row"
        alignItems="stretch"
        wrapped
      >
        {!!errorForm && (
          <OfferErrorForm
            data={errorForm}
            pending={errorFormPending}
            submitData={errorFormSubmitData}
            givenAnswers={givenAnswers}
          />
        )}

        {!errorForm && (
          <OfferCard
            isLoading={isLoading}
            progress={progress}
            resize={resize}
            isOpen={false}
          >
            {showOfferDevice && deviceInfo && (
              <OfferDevice
                deviceInfo={deviceInfo}
                givenAnswers={givenAnswers.answers}
              />
            )}

            {!!orderNumber && !!currentItem && (
              <OrderDevice
                givenAnswers={givenAnswers.answers}
                questionData={questionsData}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                data={currentItem}
                hidingChars
                currency={orderData?.currencyLang || orderData?.currency || ''}
                resize={resize}
                setResize={setResize}
                showResult={showResultFor === currentItem.itemNumber}
                withDelete={!!orderNumber || !!itemNumber}
              />
            )}
            {step === 'questions' && (
              <OfferQuestions
                question={question}
                errors={getQuestions.errors}
                givenAnswers={givenAnswers}
                changeContent={changeContent}
                giveAnswer={giveAnswer}
                resetQr={resetQr}
                pauseQuestions={pauseQuestions}
              />
            )}
            {step === 'createOrderFailure' && (
              <Typography.Error>Ошибка создания заказа.</Typography.Error>
            )}

            {step === 'summary' && <OfferSummary />}

            {step === 'prePrice' && (
              <OfferPrePrice
                currency={orderData?.currencyLang || orderData?.currency}
                itemData={currentItem}
                onClick={() => {
                  changeStep('questions')
                }}
              />
            )}

            {!!currentItem?.itemNumber && (
              <OfferQrCode
                status={currentItem.status}
                itemNumber={currentItem?.itemNumber}
                amount={`${currentItem.price}`}
                currency={orderData?.currency ?? 'руб'}
                hide={!isOpen}
                step={step}
              />
            )}

            {step === 'check-list' && <OfferCheckList />}
          </OfferCard>
        )}

        {!!showSearch && (
          <OfferSearch
            shouldRemove={shoudSearchRemove}
            hide={() => setShowSearch(false)}
          />
        )}

      </Container.Flex>
    </Container.Flex>
  )
}

export default Offer
