import { createRoutine } from 'redux-saga-routines'

export const Login = createRoutine('Auth/Login')
export const Logout = createRoutine('Auth/Logout')
export const CheckAuth = createRoutine('Auth/Check-Auth')
export const ChangePassword = createRoutine('Auth/Change-Password')
export const RestorePassword = createRoutine('Auth/Restore-Password')
export const GetUserDetail = createRoutine('User/Get-Detail')
export const GetUsersList = createRoutine('User/Get-List')
export const AddUserData = createRoutine('User/Add-User-Data')
export const UpdateUserData = createRoutine('User/Update-User-Data')
export const SaveOrder = createRoutine("Auth/Save-Order");
export const SMSLogin = createRoutine("Auth-SMS-Login")
export const SendOrderLink = createRoutine("Auth/Send-Order-Link")
