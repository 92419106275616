import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPopupContent, setPopupContent } from "../store/viewSlice";

export default function usePopup() {

    const dispatch = useDispatch();
    const popupContent = useSelector(getPopupContent);

    const hidePopup = useCallback(() => {
        dispatch(setPopupContent(""))
    }, [dispatch])

    const showPopup = useCallback((content: string) => {
        dispatch(setPopupContent(content))
    }, [dispatch]);

    return ({
        popupContent,
        hidePopup,
        showPopup
    })
}