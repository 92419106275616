import { Button, Card, Container, Typography } from "../ui";

interface ReadyItemProps {
    itemNumber?: string
}

export default function ReadyItem ({ itemNumber } : ReadyItemProps) {

    if (!itemNumber) {
        return <Typography.Title>Товар не найден</Typography.Title>
    }

    return (
        <Container.Flex fullWidth alignItems="start">
            <Card padding={28}>
                <Container.Flex fullWidth>
                <Typography.Title>
                    Товар № {itemNumber}
                </Typography.Title>
                </Container.Flex>

                <Button fullWidth>
                    ЗАБРАТЬ ТОВРА НА ОФОРМЛЕНИЕ
                </Button>

            </Card>
        </Container.Flex>
    )
}