import SVG from '../SVG'
import { IconProps } from '../types'

const SaveORDER = (props: IconProps) => {
  return (
    <SVG {...props} width={props.width ?? 20} height={props.height ?? 20}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0C10.2652 0 10.5196 0.105357 10.7071 0.292893L13.7071 3.29289C14.0976 3.68342 14.0976 4.31658 13.7071 4.70711C13.3166 5.09763 12.6834 5.09763 12.2929 4.70711L11 3.41421V11C11 11.5523 10.5523 12 10 12C9.44772 12 9 11.5523 9 11V3.41421L7.70711 4.70711C7.31658 5.09763 6.68342 5.09763 6.29289 4.70711C5.90237 4.31658 5.90237 3.68342 6.29289 3.29289L9.29289 0.292893C9.48043 0.105357 9.73478 0 10 0ZM3 7C1.89543 7 1 7.89543 1 9V18C1 19.1046 1.89543 20 3 20H17C18.1046 20 19 19.1046 19 18V9C19 7.89543 18.1046 7 17 7H15C14.4477 7 14 7.44772 14 8C14 8.55229 14.4477 9 15 9H17V18H3V9H5C5.55228 9 6 8.55229 6 8C6 7.44772 5.55228 7 5 7H3Z"
        fill="currentColor"
      />
    </SVG>
  )
}

export default SaveORDER
    