import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useUploadFiles } from '../../../contexts/uploadFiles'
import { RootState } from '../../../store'
import { removeAnswerByBlob } from '../../../store/offerSlice'
import { setResizeCard } from '../../../store/viewSlice'
import Container from '../Container'
// import Delete from '../Delete'
import Icon from '../Icon'
import Typography from '../Typography'
import { useTheme } from 'styled-components/macro'
import { useDelete } from '../../../contexts/delete'

const ImageContainer = styled.div<{ url: string }>`
    height: 64.5px;
    width: 48.77px;
    background: ${props => (props.url ? '#FFFFFF' : 'none')};
    border-radius: 6.07092px;
    border: ${props =>
        props.url
            ? 'none'
            : `1px dashed ${props.theme.colors.icon.quaternary}`};
    margin-right: 12px;
    position: relative;
    overflow: hidden;
`

const ImageItem = styled.img`
    height: 100%;
    width: auto;
`

const RemoveIcon = styled.div`
    width: 26px;
    height: 26px;
    background-color: ${props => props.theme.colors.background.contrast60};
    position: absolute;
    top: calc(50% - 13px);
    left: calc(50% - 13px);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &:hover {
        cursor: pointer;
    }
`

interface ItemProps {
    url: string
    removeItem: () => void
    id: number
}

const Item = ({ url, removeItem, id }: ItemProps) => {
    return (
        <ImageContainer url={url}>
            {!!url && <ImageItem src={url} alt={`Фото № ${id}`} />}
            {!!url && (
                <RemoveIcon onClick={removeItem}>
                    <Icon
                        name="close"
                        styles={{
                            width: '12px',
                            height: '12px',
                            position: 'absolute',
                            left: 'calc(50% - 6px)',
                            top: 'calc(50% - 6px)',
                        }}
                    />
                </RemoveIcon>
            )}
        </ImageContainer>
    )
}

export default function FilesContainer() {
    const dispatch = useDispatch()
    const theme = useTheme()
    const { questionsData, givenAnswers } = useSelector(
        (state: RootState) => state.offer,
    )

    const { showDelete } = useDelete()

    const { answers } = givenAnswers

    const { removeFile } = useUploadFiles()

    const processedUrls = Object.values(questionsData || {}).map(q => {
        const answer =
            answers.find(ans => ans.questionId === q.questionId)?.answerName ??
            ''

        return answer
    })

    const removeItem = (url: string) => {
        removeFile(url)
        dispatch(removeAnswerByBlob(url))
        dispatch(setResizeCard(true))
    }

    const onRemoveItem = useCallback(
        (url: string) => {
            showDelete({
                title: 'Удалить фото?',
                question: 'Вы точно хотите произвести удаление?',
                onDelete: () => removeItem(url),
            })
        },

        [showDelete],
    )

    if (!processedUrls.length) return null

    return (
        <>
            <Container.Flex
                fullWidth
                alignItems="start"
                padding={'10px 12px'}
                styles={{
                    border: `1px dashed ${theme.colors.icon.quaternary}`,
                    borderRadius: '12px',
                }}
            >
                <Typography.Small margin={'0 0 12px 0'}>
                    Загруженные фото
                </Typography.Small>

                <Container.Flex fullWidth direction="row">
                    {!!processedUrls &&
                        processedUrls.map((url, key) => (
                            <Item
                                url={
                                    (url || '').startsWith('/')
                                        ? (process.env.REACT_APP_IMAGE_BASE ??
                                          '') + url
                                        : url
                                }
                                key={key}
                                id={key}
                                removeItem={() => onRemoveItem(url)}
                            />
                        ))}
                </Container.Flex>
            </Container.Flex>
            {/* {typeof showConfirm === 'string' && (
                <Delete
                    inner
                    question="Вы точно хотите произвести удаление?"
                    title="Удалить фото?"
                    onDelete={() => removeItem(showConfirm)}
                    onCancel={() => setShowConfirm(null)}
                />

            )} */}
        </>
    )
}
