import { Fragment, useMemo } from 'react'
import QRCode from 'react-qr-code'
import { useParams } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { OfferSteps } from '../../store/offerSlice/types'
import { ItemStatuses } from '../../store/orderSlice/types'
import { Box, Button, Container, Info, Typography } from '../ui'

interface OfferQrCodeProps {
  itemNumber: string
  hide: boolean
  status: string
  amount: string
  currency: string
  step?: OfferSteps
}

export default function OfferQrCode({
  itemNumber,
  hide,
  status,
  amount,
  currency,
  step,
}: OfferQrCodeProps) {
  const { orderNumber } = useParams()
  const qrLink = itemNumber ?? ''

  const theme = useTheme()

  const showFinalCount = useMemo(() => {
    if (step === 'summary') return false
    if (step === 'questions') return false
    if (step === 'prePrice') return false
    if (status === ItemStatuses.Confirm) return false

    return true
  }, [step, status])

  return (
    <Container.Flex
      fullWidth
      alignItems="start"
      verticalGap={10}
      justify="start"
      className={hide ? 'hide-content' : 'show-content'}
    >
      {showFinalCount && (
        <Fragment>
          <Typography.Title secondary>
            Предварительная стоимость
          </Typography.Title>
          <Typography.RublesLarge
            fullWidth
          >{`≈ ${amount} ${currency}`}</Typography.RublesLarge>
          <Button link={`/order/${orderNumber}/${itemNumber}/?step=questions`} fullWidth>
            К ФИНАЛЬНОЙ СТОИМОСТИ
          </Button>
        </Fragment>
      )}

      <Typography.Title secondary>QR-код и номер устройства</Typography.Title>
      <Box
        padding={10}
        styles={{
          alignSelf: 'center',
          backgroundColor: '#fff',
          borderRadius: '12px',
          fontSize: '0',
          lineHeight: '0',
        }}
      >
        <QRCode size={108} value={qrLink} />
      </Box>
      <Typography.Title secondary fullWidth margin={'8px 0'}>
        {itemNumber}
      </Typography.Title>
      <Info>
        <Typography.Main margin={0} start color={theme.colors.text.secondary}>
          Покажите этот QR-код партнёру для быстрого поиска
        </Typography.Main>
      </Info>
    </Container.Flex>
  )
}
