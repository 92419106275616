import AddPlus from './icons/addPlus'
import ColorIndicatorIcon from './icons/colorIndicator'
import CloseIcon from './icons/close'
import TelegramIcon from './icons/telegram'
import LoadingIcon from './icons/loading'
import { IconProps } from './types'
import UserIcon from './icons/user'
import ReportsIcon from './icons/reports'
import HelpIcon from './icons/help'
import InfoIcon from './icons/info'
import PhotoIcon from './icons/photo'
import Hidden from './icons/hidden'
import Eye from './icons/eye'
import SettingsIcon from './icons/settings'
import EmployeeIcon from './icons/employee'
import OrderList from './icons/orderList'
import QrCodeIcon from './icons/qrCode'
import NewOrderIcon from './icons/newOrder'
import ExitIcon from './icons/exit'
import BellIcon from './icons/bell'
import EditIcon from './icons/edit'
import EyeClose from './icons/eye-close'
import Arrow from './icons/arrow'
import Search from './icons/search'
import List from './icons/List'
import FiltersOn from './icons/filtersOn'
import FiltersOff from './icons/filtersOff'
import Calendar from './icons/calendar'
import MobileIcon from './icons/mobile'
import LeafIcon from './icons/leaf'
import MapIcon from './icons/map'
import SaveORDER from './icons/save-order'
import AddDevice from './icons/add-device'

const Icon = (props: IconProps) => {
  switch (props.name) {
    case 'add-device':
      return <AddDevice {...props} />
    case 'add-plus':
      return <AddPlus {...props} />
    case 'arrow':
      return <Arrow {...props} />
    case 'close':
      return <CloseIcon {...props} />
    case 'color-indicator':
      return <ColorIndicatorIcon {...props} />
    case 'filters-off':
      return <FiltersOff {...props} />
    case 'filters-on':
      return <FiltersOn {...props} />
    case 'telegram':
      return <TelegramIcon {...props} />
    case 'leaf':
      return <LeafIcon {...props} />
    case 'list':
      return <List {...props} />
    case 'loading':
      return <LoadingIcon {...props} />
		case 'map':
			return <MapIcon {...props} />
    case 'mobile':
      return <MobileIcon {...props} />
    case 'employee':
      return <EmployeeIcon {...props} />
    case 'exit':
      return <ExitIcon {...props} />
    case 'user':
      return <UserIcon {...props} />
    case 'reports':
      return <ReportsIcon {...props} />
    case 'help':
      return <HelpIcon {...props} />
    case 'info':
      return <InfoIcon {...props} />
    case 'new-order':
      return <NewOrderIcon {...props} />
    case 'order-list':
      return <OrderList {...props} />
    case 'photo':
      return <PhotoIcon {...props} />
    case 'qr-code':
      return <QrCodeIcon {...props} />
    case 'hidden':
      return <Hidden {...props} />
    case 'eye':
      return <Eye {...props} />
    case 'eye-close':
      return <EyeClose {...props} />
    case 'settings':
      return <SettingsIcon {...props} />
    case 'bell':
      return <BellIcon {...props} />
    case 'edit':
      return <EditIcon {...props} />
    case 'save-order':
      return <SaveORDER {...props}/>
    case 'search':
      return <Search {...props} />
    case 'calendar':
      return <Calendar {...props} />
  }
}

export default Icon
