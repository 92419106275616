import { useTheme } from 'styled-components'
import { bp } from '../../theme/media'
import { useFiltersData } from '../Filters/FiltersContext'
import { Container, Typography, Input, Icon } from '../ui'

export default function Search() {
  const {
    filtersOn,
    setShowFilters,
    showFilters,
    getFiltersData,
    search,
    setSearch,
    getData,
  } = useFiltersData()

  const theme = useTheme()

  const onSearch = () => {
    const requestData = {} as any
    requestData.filters = getFiltersData

    if (search) {
      requestData.search = search
    }
    getData(requestData)
  }

  return (
    <Container.Flex
      direction="row"
      justify="between"
      fullWidth
      margin={0}
      verticalGap={16}
      breakpoints={{
        750: {
          flexDirection: 'column',
          alignItems: 'flex-start',
        },
      }}
    >
      <Container.Flex fullWidth direction="row" justify="between">
        <Typography.Title margin={"0 4px"} padding={'0'}>
          Магазины-партнёры
        </Typography.Title>

        <Typography.Medium
          margin={0}
          color={theme.colors.link.default}
          styles={{ display: 'none' }}
          breakpoints={{
            750: {
              display: 'block',
            },
          }}
          onClick={() => setShowFilters(!showFilters)}
        >
          Фильтр
        </Typography.Medium>
      </Container.Flex>

      <Container.Flex
        direction="row"
        justify="between"
        fullWidth
        styles={{
          maxWidth: '344px',
        }}
        horizontalGap={12}
        breakpoints={{
          [bp.table]: {
            maxWidth: '312px'
          }
        }}
      >
        <Input
          type="text"
          value={search}
          placeholder="Поиск"
          icon="search"
          iconPosition="right"
          iconClick={onSearch}
          onKeyPress={(e: any) =>
            e.key === 'Enter' ? onSearch() : undefined
          }
          onChange={e => setSearch(e.target.value.replace(/ /g, ''))}
        />

        <Container.Flex styles={{ width: '20px', height: '20px' }} justify='center'>
        <Icon
          secondary
          name={filtersOn ? 'filters-on' : 'filters-off'}
          onClick={() => setShowFilters(!showFilters)}
        />
        </Container.Flex>

      </Container.Flex>
    </Container.Flex>
  )
}
