import { ChangeEvent, Fragment, memo, useEffect, useRef, useState } from 'react'
import Timer from './Timer'
import { classNames } from '../../styles/classNames'
import { Card, Container, PhoneInput, Typography, Button, Input } from '../ui'
import { useDispatch, useSelector } from 'react-redux'
import {
  getSendOrderLinkData,
  getSMSLoginData,
  resetSMSApi,
  resetSendLink,
} from '../../store/userSlice'
import { SendOrderLink, SMSLogin } from '../../store/userSlice/routines'

type Steps = 'phone-input' | 'input-code' | 'success'

interface SaveOrderFormProps {
  orderNumber: string
  hide: () => void
}

const SaveOrderForm = ({ orderNumber, hide }: SaveOrderFormProps) => {
  const dispatch = useDispatch()

  const [step, setStep] = useState<Steps>('phone-input')
  const [phone, setPhone] = useState('')

  const _hide = () => {
    dispatch(resetSMSApi())
    dispatch(resetSendLink())
    hide()
  }

  const { codeSent, coodeSentSuccess, pending, error } =
    useSelector(getSMSLoginData)
  const {
    status: sendLinkStatus,
    pending: sendLinkPending,
    error: sendLinkError,
  } = useSelector(getSendOrderLinkData)

  const [disableCodeSubmit, setDisableCodeSubmit] = useState<true | undefined>(
    true,
  )
  const [disablePhoneSubmit, setDisablePhoneSumbit] = useState<
    true | undefined
  >(true)
  const contentRef = useRef<HTMLDivElement>(null)
  const phoneInputRef = useRef<HTMLInputElement>(null)
  const codeInputRef = useRef<HTMLInputElement>(null)

  const onChangeStep = (step: Steps) => () => {
    if (contentRef.current) {
      contentRef.current.classList.add(classNames.changeCardContent)

      setTimeout(() => {
        setStep(step)
      }, 300)
    }
  }

  useEffect(() => {
    if (codeSent) {
      onChangeStep('input-code')()
    }
  }, [codeSent])

  useEffect(() => {
    if (coodeSentSuccess) {
      dispatch(SendOrderLink.request(orderNumber))
    }
  }, [coodeSentSuccess, dispatch])

  useEffect(() => {
    if (sendLinkStatus === 'success') {
      onChangeStep('success')()
    }
  }, [sendLinkStatus, dispatch])

  const afterPhoneChange = (value: string) => {
    if (disablePhoneSubmit) {
      if (!value.match(/_/)) {
        setDisablePhoneSumbit(undefined)
      }
    } else {
      if (value.match(/_/)) {
        setDisablePhoneSumbit(true)
      }
    }
  }

  const onCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (disableCodeSubmit) {
      if (e.target.value.length === 4) {
        setDisableCodeSubmit(undefined)
      }
    } else {
      if (e.target.value.length !== 4) {
        setDisableCodeSubmit(true)
      }
    }
  }

  const sendPhone = () => {
    const _phone = phone || phoneInputRef.current?.value || '';

    dispatch(
      SMSLogin.request({
        phone: _phone
      }),
    )
    if (_phone) {
      setPhone(_phone)
    }

  }

  const sendCode = () => {
    dispatch(
      SMSLogin.request({
        phone,
        code: codeInputRef.current?.value ?? '',
      }),
    )
  }

  return (
    <Card
      padding={28}
      styles={{
        overflow: 'hidden',
        marginTop: '100px',
        backgroundColor: '#ffffff',
        width: '344px',
      }}
      breakpoints={{
        600: {
          width: '312px',
        },
      }}
    >
      <Container.Flex
        fullWidth
        ref={contentRef}
        verticalGap={16}
        alignItems="start"
        onAnimationEnd={() => {
          contentRef.current?.classList.remove(classNames.changeCardContent)
        }}
      >
        <Typography.Title>Сохранить оценку</Typography.Title>

        <Typography.Main start margin={0}>
          Для быстрого поиска вашей оценки мы отправим ссылку на неё по СМС.
          Оценка будет доступна только вам.
        </Typography.Main>

        {step === 'phone-input' && (
          <Fragment>
            <PhoneInput afterChange={afterPhoneChange} ref={phoneInputRef} />
            <Typography.Small>
              На указанный номер придёт СМС-код
            </Typography.Small>
            <Container.Grid cols="1fr 1fr" fullWidth gap={10}>
              <Button variant="danger" onClick={_hide}>
                Отмена
              </Button>
              <Button
                withLoader
                pending={pending}
                disabled={disablePhoneSubmit}
                fullWidth
                onClick={sendPhone}
              >
                ДАЛЕЕ
              </Button>
            </Container.Grid>
          </Fragment>
        )}

        {step === 'input-code' && (
          <Fragment>
            <Input
              ref={codeInputRef}
              placeholder="Код из СМС"
              type="number"
              maxLength={4}
              onChange={onCodeChange}
            />
            <Timer resendCode={sendPhone} codePending={pending}/>
            <Container.Grid cols="1fr 1fr" fullWidth gap={10}>
              <Button variant="danger" onClick={_hide}>
                Отмена
              </Button>
              <Button
                withLoader
                pending={pending || sendLinkPending}
                disabled={disableCodeSubmit}
                fullWidth
                onClick={sendCode}
              >
                ВОЙТИ
              </Button>
            </Container.Grid>
          </Fragment>
        )}

        {step === 'success' && (
          <Fragment>
            <Typography.Main start>
              {`Ссылка отправлена на номер телефона ${phone}`}
            </Typography.Main>

            <Button onClick={_hide} fullWidth>
              Закрыть
            </Button>
          </Fragment>
        )}

        {!!error && <Typography.Error>{error}</Typography.Error>}
        {!!sendLinkError && (
          <Typography.Error>{sendLinkError}</Typography.Error>
        )}
      </Container.Flex>
    </Card>
  )
}

export default memo(SaveOrderForm)
