import { useTheme } from 'styled-components'
import { Card, Container, Overlay, Typography, Button } from '../'

interface DeleteProps {
    onDelete: () => void
    onCancel: () => void
    title: string
    question: string
    inner?: true
}

const Delete = ({ onDelete, onCancel, title, question, inner }: DeleteProps) => {
    const theme = useTheme()
    return (
        <Overlay padding={'156px 24px'} inner={inner}>
            <Card
                className="show"
                padding={28}
                styles={{
                    backgroundColor: theme.colors.background.contrast,
                }}
            >
                <Container.Flex alignItems="stretch">
                    <Typography.TitleSecondary
                        textAlign="start"
                        margin={'0 0 24px'}
                        padding={'0 4px'}
                    >
                        {title}
                    </Typography.TitleSecondary>
                    <Typography.Main
                        textAlign="start"
                        margin={'0 0 22px'}
                        padding={'0 4px'}
                    >
                        {question}
                    </Typography.Main>

                    <Container.Grid
                        rows="1fr"
                        cols="1fr 1fr"
                        gap={16}
                        fullWidth
                    >
                        <Button variant="danger" fullWidth onClick={() => {onDelete(); onCancel()}}>
                            Удалить
                        </Button>
                        <Button fullWidth onClick={onCancel}>
                            Отмена
                        </Button>
                    </Container.Grid>
                </Container.Flex>
            </Card>
        </Overlay>
    )
}

export default Delete
