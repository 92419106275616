import { ReactNode, createContext, useState, useContext } from 'react'

interface IPopupImageContext {
    image: string | null
    setImage: (src: string | null) => void
}

const PopupImageContext = createContext({
    image: null,
    setImage: () => {},
} as IPopupImageContext)

export const usePopupImage = () => {
    return useContext(PopupImageContext)
}

export default function PopupImage({ children }: { children: ReactNode }) {
    const [image, setImage] = useState<string | null>(null)

    return (
        <PopupImageContext.Provider
            value={{
                image,
                setImage,
            }}
        >
            {children}
        </PopupImageContext.Provider>
    )
}
