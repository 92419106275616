import { defaultPhone } from ".";

import { setCaretPosition } from "./setCaretPosition";

export const onKeyDown = (e: any, afterChange?: (v: string) => void) => {
  e.preventDefault();
  const { value } : { value: string } = e.target;

  if (e.key === "Backspace") {
    if (value.length < 8) {

      return
    } else {
      const digits = value.replace(/\D*$/, "");

      const newValue = digits.slice(0, -1);

      e.target.value = defaultPhone.split("").map((el, idx) => newValue[idx] ?? el).join("");
      setCaretPosition(e.target);
      afterChange && afterChange(e.target.value);
    }
  } else if (/\d/.test(e.key)) {
    if (!/_/.test(value)) {
      return;
    }
    e.target.value = value.replace(/_/, e.key);
    afterChange && afterChange(e.target.value);
    setCaretPosition(e.target);
    return;
  } else {
    return;
  }
}