import SVG from "../SVG";
import { IconProps } from "../types";

const Hidden = (props: IconProps) => {
    return (
        <SVG {...props} width={props.width ?? 22} height={props.height ?? 20}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.49739 9.09528C1.99566 8.81846 2.62362 8.9967 2.90242 9.49316L2.90316 9.49443L2.91575 9.51566C2.92894 9.53751 2.95174 9.57434 2.98447 9.62403C3.05 9.72351 3.15494 9.87389 3.30188 10.0584C3.59625 10.4281 4.05566 10.9304 4.70041 11.435C5.9795 12.436 8.00963 13.465 11 13.465C13.9904 13.465 16.0206 12.436 17.2997 11.435C17.9444 10.9304 18.4038 10.4281 18.6982 10.0584C18.8451 9.87389 18.9501 9.72351 19.0156 9.62403C19.0483 9.57434 19.0711 9.53751 19.0843 9.51566L19.0969 9.49443L19.0977 9.49313C19.3765 8.99669 20.0044 8.81847 20.5027 9.09528C21.0024 9.37288 21.1824 10.003 20.9048 10.5027L20 10C20.9048 10.5027 20.9045 10.5032 20.9042 10.5037L20.9036 10.5048L20.9021 10.5074L20.8985 10.5138L20.8884 10.5315C20.8804 10.5453 20.8697 10.5634 20.8564 10.5855C20.8298 10.6295 20.7926 10.6894 20.7442 10.7627C20.6477 10.9094 20.5065 11.1105 20.3175 11.3479C20.1248 11.5898 19.8815 11.8705 19.5841 12.1699L20.7071 13.2929C21.0977 13.6834 21.0977 14.3166 20.7071 14.7071C20.3166 15.0977 19.6835 15.0977 19.2929 14.7071L18.0427 13.4569C17.2671 13.9923 16.3225 14.4986 15.1953 14.8775L15.4806 16.3039C15.5889 16.8455 15.2377 17.3723 14.6961 17.4806C14.1546 17.5889 13.6278 17.2377 13.5195 16.6961L13.2516 15.3569C12.5544 15.4709 11.8045 15.535 11 15.535C10.1955 15.535 9.44568 15.4709 8.74845 15.3569L8.48061 16.6961C8.3723 17.2377 7.84548 17.5889 7.30392 17.4806C6.76236 17.3723 6.41114 16.8455 6.51945 16.3039L6.80474 14.8775C5.67757 14.4986 4.73302 13.9923 3.95741 13.4569L2.70714 14.7071C2.31661 15.0977 1.68345 15.0977 1.29293 14.7071C0.902401 14.3166 0.902401 13.6834 1.29293 13.2929L2.41598 12.1699C2.11853 11.8705 1.87523 11.5898 1.68256 11.3479C1.49357 11.1105 1.35241 10.9094 1.25582 10.7627C1.2075 10.6894 1.17024 10.6295 1.14364 10.5855C1.13034 10.5634 1.11969 10.5453 1.11165 10.5315L1.10153 10.5138L1.09793 10.5074L1.09648 10.5048L1.09585 10.5037C1.09556 10.5032 1.09528 10.5027 2.00003 10L1.09528 10.5027C0.817679 10.003 0.997711 9.37288 1.49739 9.09528Z"
                fill="currentColor"
            />
        </SVG>
    );
};

export default Hidden;
