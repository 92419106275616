import { Card, Container, Progress, Typography } from '../ui'
import OfferLoader from './OfferLoader'
import {
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getOfferCardData, setResizeCard } from '../../store/viewSlice'
import { zIndexes } from '../../zIndexes'
import { bp, cardSizes } from '../../theme/media'
import { useSession } from '../../hooks/useSession'

interface OfferCardProps {
  children: ReactNode
  onClick?: (e: MouseEvent<HTMLDivElement>) => void
  progress: number
  isLoading: boolean
  isQuestion?: boolean
  resize: boolean
  cursorPointer?: true
  isOpen: boolean
  withoutLoader?: true
}

const OfferCard = ({
  onClick,
  children,
  progress,
  isQuestion,
  cursorPointer,
  isOpen,
  withoutLoader
}: OfferCardProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const cardRef = useRef<HTMLDivElement>(null)

  const { bpTable, isAdmin } = useSession()

  const dispatch = useDispatch()

  const offerCardData = useSelector(getOfferCardData)
  const { offerCard, cardError, resizeCard } = offerCardData
  const { opacity, showLoader } = offerCard

  const [_, setMinHeight] = useState(220)

  const _onClick = useCallback(
    (e: any) => {
      onClick && onClick(e)
    },
    [onClick],
  )

  useEffect(() => {
    if (containerRef.current) {
      const newHeight = containerRef.current.offsetHeight + 28 * 2
      setMinHeight(newHeight > 220 ? newHeight : 220)
    }
    if (resizeCard) {
      dispatch(setResizeCard(false))
    }
  }, [children, resizeCard, dispatch]);

  return (
    <Card
      padding="28px"
      fullWidth
      onClick={_onClick}
      isQuestion={isQuestion}
      ref={cardRef}
      styles={{
        height: `max-content`,
        transition: 'height 300ms ease-in, color 200ms ease-in',
        minHeight: '220px',
        cursor: cursorPointer ? 'pointer' : 'auto',
        width: isAdmin ? '344px' : '312px',
        backdropFilter: isOpen ? 'blur(20px)' : 'blur(8px)',
      }}
      breakpoints={{
        [bpTable] : {
          width: cardSizes.short
        },
        [bp.xs] : {
          width: '100%'
        }
      }}
    >
      <Container.Flex
        fullWidth
        alignItems="stretch"
        ref={containerRef}
        styles={{
          opacity: opacity,
          height: 'auto',
        }}
      >
        {!!progress && <Progress progress={progress} />}

        {children}

        {cardError && (
          <Typography.Error margin={'5px 0 0 0'} fullWidth className="show">
            {cardError}
          </Typography.Error>
        )}
      </Container.Flex>

      {(showLoader && !withoutLoader) && (
        <Container.Flex
          justify="center"
          fullWidth
          fullHeight
          styles={{
            height: `100%`,
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: zIndexes.loader,
          }}
        >
          <OfferLoader minHeight="100%" />
        </Container.Flex>
      )}
    </Card>
  )
}

export default OfferCard
