import { ReactNode } from "react"
import { styled } from '../../../helpers/styled'
import { zIndexes } from "../../../zIndexes"
import { Props } from "../../types"
import Container from "../Container"
import { Box } from "../Box/Box";

type OverlayProps = Props<{
    children: ReactNode
    padding?: number | string;
    inner?: true
    close?: () => void;
}>

const Wrapper = styled.div<{padding?: number | string, inner?: true}>`
    position: ${props => !!props.inner ? "absolute" : "fixed"};
    z-index: ${zIndexes.overlay};
    width: ${props => props.inner ? "100%" : "100vw"};
    height: ${props => props.inner ? "100%" : "100vh"};
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(2px);
    ${props => {
        if (props.padding) {
            if (typeof props.padding === "number") {
                return {
                    padding: `${props.padding}px`
                }
            } else {
                return {
                    padding: `${props.padding}`
                }
            }
        }
    }}
`

const Overlay = ({ children, padding, close, inner } : OverlayProps) => {
    return (
        <Wrapper padding={padding} inner={inner}>
            <Container.Flex fullHeight fullWidth onClick={close}>
                <Box onClick={(event) => {event.stopPropagation()}}>{children}</Box>
            </Container.Flex>
        </Wrapper>
    )
}

export default Overlay