import styled, { useTheme } from 'styled-components'
import * as CSS from 'csstype'
import Container from '../Container'
import Typography from '../Typography'
import Button from '../Button'
import { zIndexes } from '../../../zIndexes'
import Card from '../Card'
import { ButtonVariants } from '../Button/Button'

const Wrapper = styled.div<{ padding?: number | string }>`
    position: fixed;
    z-index: ${zIndexes.overlay};
    width: calc(100% + 32px);
    height: calc(100% + 32px);
    padding: 156px 24px 0 24px;
    top: -16px;
    left: -16px;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(2px);
    ${props => {
        if (props.padding) {
            if (typeof props.padding === 'number') {
                return {
                    padding: `${props.padding}px`,
                }
            } else {
                return {
                    padding: `${props.padding}`,
                }
            }
        }
    }}
`

interface ConfirmProps {
    question: string
    questionTitle?: string
    onYes: () => void
    yesText: string
    noText: string
    onNo: () => void
    styles?: CSS.Properties
    withWrapper?: true
    yesVariant?: ButtonVariants
    noVariant?: ButtonVariants
}

const Confirm = (props: ConfirmProps) => {
    const theme = useTheme()

    return (
      <Wrapper>
        <Card
          padding={28}
          styles={{
            backgroundColor: theme.colors.background.contrast,
            ...props.styles,
          }}
        >
          <Container.Flex alignItems="start" fullWidth verticalGap={24}>
            {!!props.questionTitle && (
              <Typography.Title
                color={theme.colors.text.secondary}
                textAlign="start"
                margin={0}
              >
                {props.questionTitle}
              </Typography.Title>
            )}

            {!!props.question && (
              <Typography.Main margin={0}>{props.question}</Typography.Main>
            )}

            <Container.Grid direction="row" gap={16} cols={'1fr 1fr'} fullWidth>
              <Button
                variant={props.noVariant ?? 'outline'}
                fullWidth
                onClick={props.onNo}
                square
                styles={{ margin: '0' }}
              >
                {props.noText}
              </Button>
              <Button
                variant={props.yesVariant ?? 'outline'}
                fullWidth
                onClick={props.onYes}
                square
                styles={{ margin: '0' }}
              >
                {props.yesText}
              </Button>
            </Container.Grid>
          </Container.Flex>
        </Card>
      </Wrapper>
    )
}

export default Confirm
