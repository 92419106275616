import { useMemo, useState } from 'react'
import { createGlobalStyle, useTheme } from 'styled-components'
import { Container, Typography } from '../ui'
import { Map, Placemark, YMaps } from 'react-yandex-maps'
import { usePartnerDetail } from '../../pages/Partner'
import { zIndexes } from '../../zIndexes'

const GlobalStyles = createGlobalStyle`
  .yandex-map-root {
    width: 100%;
    height: 391px;
  }

  .yandex-map-root > yamps::first-child {
    height: 391px !important;
  }

  .yandex-map-baloon {
    border-radius: 15px;
  }

  .hide-list {
    display: none !important;
  }


  @media screen and (max-width: 600px) {
    .yandex-map-root {
      width: 100%;
      height: calc(100vw - 56px) !important;
      min-height: auto !important;
  }


    .yandex-map-root > yamps::first-child {
      height: calc(100vw - 56px) !important;
      min-height: auto !important;
    }  
  }
`

export default function PartnerAddresses() {
  const [listType, setListType] = useState(false)
  const [zoom] = useState(6)
  const mapState = useMemo(() => {
    return { center: [53.902207, 27.561676], zoom }
  }, [zoom])
  const theme = useTheme()

  const { data } = usePartnerDetail()

  if (!data) return null

  const { addresses } = data

  return (
    <Container.Flex fullWidth margin={0} verticalGap={16}>
      <GlobalStyles />
      <Container.Flex fullWidth direction="row" justify="between">
        <Typography.Title margin={"0 4px"} secondary>Адреса магазинов</Typography.Title>

        <Typography.Small
          color={theme.colors.link.default}
          onClick={() => setListType(!listType)}
        >
          {listType ? 'На карте' : 'Списком'}
        </Typography.Small>
      </Container.Flex>

      <Container.Flex fullWidth className={listType ? 'hide' : 'show'}>
        <YMaps>
          <Map
            className="yandex-map-root"
            state={mapState}
            width="100%"
            style={{ minHeight: '391px', width: '100%' }}
          >
            {addresses.map((el, key) => {
              const { title, address, coordinates } = el
              return (
                <Placemark
                  key={`address${key}`}
                  geometry={coordinates.map(el => +el)}
                  options={{
                    preset: 'islands#orangeShoppingIcon',
                  }}
                  properties={{
                    balloonContentHeader: title,
                    balloonContentBody: address,
                    hintContent: title,
                  }}
                  modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                />
              )
            })}
          </Map>
        </YMaps>
      </Container.Flex>

      <Container.Flex
        className={listType ? 'show' : 'hide hide-list'}
        styles={{
          zIndex: zIndexes.overlay,
          transitionDelay: '100ms',
        }}
      >
        {addresses.map((el, key) => {
          const { title, address } = el
          return (
            <Container.Flex
              direction="row"
              key={key}
              fullWidth
              styles={{
                borderBottom: `1px solid ${theme.colors.text.secondary}`,
              }}
            >
              <Typography.Title margin={'10px'} start>
                {title}
              </Typography.Title>
              <Typography.Main margin={0} secondary>
                {address}
              </Typography.Main>
            </Container.Flex>
          )
        })}
      </Container.Flex>
    </Container.Flex>
  )
}
