import { useEffect, memo, useState } from 'react'
import { URLS } from '../apinew'
import Filters from '../components/Filters/FiltersContext'
import Shops from '../components/Shops'
import { useApi } from '../hooks/useApi'
import { Partners } from '../store/orderSlice/types'

interface Props {
  start?: true
  withoutCreate?: true
}

const ShopsPage = ({ start, withoutCreate }: Props) => {
  const { post } = useApi()

  const [partners, setPartners] = useState<Partners | null>(null)
  const [pending, setPending] = useState(false)
  const [error, setError] = useState('')

  const getParnters = async (filters?: any) => {
    if (pending || partners) {
      return
    }
    setError('')
    setPending(true)
    const response = await post(URLS.getPartners, { filters })

    if (response.status === 'success') {
      setPartners(response.data)
    }
    if (response.status === 'error') {
      setError(response.errors.join('. '))
    }

    setPending(false)
  }

  const getFilteredParnters = async (data: any) => {
    setError('')
    setPending(true)
    const response = await post(URLS.getPartners, data)

    if (response.status === 'success') {
      setPartners(response.data)
    }
    if (response.status === 'error') {
      setError(response.errors.join('. '))
    }

    setPending(false)
  }

  useEffect(() => {
    getParnters()
  }, [post, getParnters])

  return (
    <Filters
      getFiltersURL={URLS.getPartnersFilter}
      getData={getFilteredParnters}
    >
      <Shops
        partners={partners}
        pending={pending}
        error={error}
        start={start}
        withoutCreate={withoutCreate}
      />
    </Filters>
  )
}

export default memo(ShopsPage)
