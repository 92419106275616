import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { URLS } from '../../apinew'
import { useUploadFiles } from '../../contexts/uploadFiles'
import { useApi } from '../../hooks/useApi'
import {
  addSeveralAnswers,
  getItemData,
  restoreOffer,
  setCustomerCombinationId,
  setDeviceInfo,
  setIsCopyItem,
  setStep,
} from '../../store/offerSlice'
import { restoreOrderState } from '../../store/orderSlice'
import { saveOrderNumber } from '../../store/savedData'
import {
  AddCustomerDeviceRequest,
  AddCustomerDeviceResponse,
} from '../../types'
import { Button, Container, Typography } from '../ui'
import ImagesContainer from '../ui/Image/ImagesContainer'
import OrderDevice from './OrderDevice'

export default function OfferCheckList() {
  const checkListItemData = useSelector(getItemData).data
  const { orderNumber } = useParams()

  const dispatch = useDispatch()

  const { data, errorString, pending, apiPost } = useApi()
  const { setRemoteFiles } = useUploadFiles()

  const navigate = useNavigate()

  useEffect(() => {
    const responseData: AddCustomerDeviceResponse | null = data

    if (responseData) {
      dispatch(restoreOffer())
      dispatch(restoreOrderState())
      dispatch(setCustomerCombinationId(responseData.customerCombinationId))
      dispatch(setDeviceInfo(responseData.answers.deviceInfo))
      dispatch(saveOrderNumber(responseData.orderNumber))
      dispatch(setIsCopyItem(true))
      if (checkListItemData?.images) {
        dispatch(addSeveralAnswers(checkListItemData.images))
        setRemoteFiles(checkListItemData.images)
      }
      dispatch(setStep('questions'))
      navigate(
        `/order/${responseData.orderNumber}/${responseData.itemNumber}/checkList`,
      )
    }
  }, [data, dispatch, checkListItemData?.images])

  const images = useMemo(() => {
    if (checkListItemData?.images) {
      return checkListItemData.images
    } else {
      return {}
    }
  }, [checkListItemData])

  if (!checkListItemData) return null

  const onClick = () => {
    apiPost(
      URLS.addCustomerDevice,
      {
        number: orderNumber,
        deviceNumber: checkListItemData.itemNumber,
      } as AddCustomerDeviceRequest,
      true,
    )
  }

  return (
    <Container.Flex verticalGap={16}>
      <OrderDevice
        data={{ ...checkListItemData, price: +checkListItemData.price }}
        withDelete={false}
        resize={false}
        currency={checkListItemData.currencyLang}
        showResult={false}
        initVisible={true}
        withoutClose
      />

      {!!Object.entries(images).length && (
        <ImagesContainer
          images={Object.values(images)}
          title="Загруженные фото"
        />
      )}

      {!!errorString && (
        <Typography.Error
          styles={{
            maxWidth: '280px',
            overflowWrap: 'break-word',
          }}
        >
          {errorString}
        </Typography.Error>
      )}

      <Button fullWidth onClick={onClick} withLoader pending={pending}>
        ПЕРЕЙТИ К ЧЕК ЛИСТУ
      </Button>
    </Container.Flex>
  )
}
