import { Card, Select } from '../ui'

const ThemeSelect = () => {
    return (
        <Card
            padding={20}
            styles={{ maxWidth: 'none', width: 'calc(50% - 20px)' }}
        >
            <Select
                options={[
                    { label: 'Первый элемент', value: 'first' },
                    { label: 'Второй элемент', value: 'second' },
                    { label: 'Третий элемент', value: 'third' },
                ]}
            />
        </Card>
    )
}

export default ThemeSelect
