import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_GET, API_POST, getApiState, resoterApiState } from "../store/apiSlice";
import Api, { URLS } from "../apinew";
import { Session } from "../store/userSlice/types";
import { useSession } from "./useSession";

export type JOB = {
    URL: URLS
    method: 'POST' | "GET",
    data: any
    params?: any
}

export const useApi = () => {

    const dispatch = useDispatch();

    const api = useSelector(getApiState);
    const {session} = useSession()
    const { status, data, errors } = api;
    const pending = status === "pending";

    const apiGet = useCallback((url: URLS, force?: true) => {
        if (!pending || force) {
            dispatch(API_GET.request(url))
        }
    }, [dispatch, pending])

    const apiPost = useCallback((url: URLS, data: any, force?: true) => {

        if (!status || force) {
            dispatch(API_POST.request({
                url,
                data
            }))
        }
    }, [dispatch, pending]);

    const fetchAll = useCallback(async (jobs: JOB[], session: Session, params?: any) => {
        const api = new Api
        return Promise.all(jobs.map(job => {
            if (job.method === "POST") {
                return api._post(job.URL)(job.data, session)
            } else {
                return api._get(job.URL)(session, params)
            }
        }))
    }, []);

    const post = useCallback((url: URLS, data: any) => {
        const api = new Api;
        return api._post(url)(data, session)
    }, [session])

    const errorString = useMemo(() => {
        if (!errors.length) return ""
        return errors.map(el => el.message).join('. ')
    }, [errors])

    const errorCodes = useMemo(() => {
        return errors.map(el => el.code);
    }, [errors])

    useEffect(() => {
        return () => {
            dispatch(resoterApiState()) 
        }
    }, [dispatch])

    return ({
        pending,
        status,
        data,
        errors,
        apiGet,
        apiPost,
        fetchAll,
        post,
        errorString,
        errorCodes
    })
   
};
