import { useNavigate } from 'react-router-dom'
import { Login } from '../components/'

const LoginPage = () => {
  const navigate = useNavigate()

  return <Login hide={() => navigate('/')} />
}

export default LoginPage
