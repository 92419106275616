export enum ItemStatuses {
    New = "N",
    ImageLoaded = "F",
    Approved = "D"
}

export const getProgressByStatus = (status: string, base?: number) => {
    switch (status) {
        case ItemStatuses.New:
            return 0.5
        case ItemStatuses.ImageLoaded:
            return 0.75
        case ItemStatuses.Approved:
            return 1
        default:
            return base ?? 0
    }
}