import styled from 'styled-components'
import { commonCSS } from './TextInput'

export const SelectInputWrapper = styled.div<{ open: boolean }>`
    position: relative;
    width: 100%;
    background: transparent;
    background-color: transparent;
    &::after {
        content: '';
        position: absolute;
        right: 10px;
        top: 10px;
        width: 20px;
        height: 20px;
        background-image: url(/down-s.png);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        transition: transform 200ms ease-in;
        cursor: pointer;
        z-index: -1;
    }
`

export const SelectInput = styled.select`
    cursor: pointer;
    background: transparent ;
    background-color: transparent !important;
    ${commonCSS};
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    color: ${props => props.theme.colors.text.secondary};
`
