const mobile = 600,
    tablet = 1140;

export const media = {
    mobile: `screen and (max-width: ${mobile}px)`,
    tablet: `screen and (max-width: ${tablet}px)`
}

export const bp = {
    table: 1280,
    mobile: 768,
    xs: 360
}

export const containerSizes = {
    desktop: '1104px',
    table: '660px',
    mobile: '312px'
}

export const cardSizes = {
    big: '344px',
    short: '312px',
    micro: 'calc(100% - 48px)'
}