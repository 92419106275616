import { GivenAnswer } from "../../../store/offerSlice/types";

export const getDeviceData = (givenAnswers: GivenAnswer[]) => {

    return givenAnswers.reduce((acc, next) => {
        if (next.questionShortName) {
            if (next.questionShortName === "empty") {
                acc.noLabel = next.alternateName ?? next.answerName;
                return acc;
            }
            const answer = next.alternateName ?? next.answerName;
            acc[next.questionShortName] = acc[next.questionShortName] ? acc[next.questionShortName] + ", " + answer.toLowerCase() : answer
            return acc
        } 
        return acc;
    }, {} as {
        [questionShortName: string]: string
    })
}