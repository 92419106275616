import { ApiError, ApiWorker, isString, N, ResponseData } from '../types'

// ======== API ======== //

export type AuthorizedRequest<T> = {
  [auth_param_name: string]: string
} & T

// ======== LOGIN ======== //

export type LoginRequestData = {
  login: string
  password: string
}

export type LoginResponseData = ResponseData<Session>

// ======== CHECKAUTH ======== //

export type CheckAuthRequestData = AuthorizedRequest<{}>

export type CheckAuthData = {
  isAuthorised: boolean
  login: string
}

export type CheckAuthResponseData = ResponseData<CheckAuthData>

// ======== LOGOUT ======== //

export type LogoutRequestData = AuthorizedRequest<{}>

export type LogoutSuccessData = {
  isAuthorised: boolean
  login: string
}

export type LogoutResponseData = ResponseData<LogoutSuccessData>

// ======== CHANGEPASSWORD ======== //

export type ChangePasswordRequestData = AuthorizedRequest<{
  newPassword: string
}>

export type ChangePasswordSuccessData = {
  MESSAGE: string
  TYPE: string
}

export type ChangePasswordResponseData = ResponseData<ChangePasswordSuccessData>

// ======== RESTOREPASSWORD ======== //

export type RestorePasswordRequestData = {
  loginEmail: string
}

export type RestorePasswordData = {
  MESSAGE: string
  TYPE: string
}

export type RestorePasswordResponseData = ResponseData<RestorePasswordData>

export enum UserType {
  employee = 'employee',
  partner = 'partner',
  quest = 'quest',
}

export type DetailUser = {
  name: string
  secondName: string
  lastName: string
  email: string
  userType: UserType
  partnerCode: string
  image?: string
  rostokCount?: number
}

// ======== GET-DETAIL ======== //

export type GetDetailRequestData = AuthorizedRequest<{
  login: string
}>

export type GetDetailResponseData = ResponseData<DetailUser>

// ======== GET-DETAIL ======== //

export type GetListRequestData = AuthorizedRequest<{}>

export type GetListResponseData = ResponseData<DetailUser[]>

// ======== ADD-USER ======== //

export type CreateEditUserData = {
  name: string
  secondName: string
  lastName: string
  email: string
}

export type AddUserRequestData = AuthorizedRequest<CreateEditUserData>

export type AddUserResponseData = ResponseData<DetailUser>

// ======== UPDATE-USER ======== //

export type UpdateUserRequestData = AuthorizedRequest<CreateEditUserData>

export type UpdateUserResponseData = ResponseData<DetailUser>

export type Session = {
  auth_param_name: isString
  auth_param_value: isString
  isAuthorised: boolean
}

export type SMSLoginRequestData = {
	phone: string
	code?: string
}

export type SMSLoginResponseData = {
	isAuthorised: boolean,
	auth_param_name?: string,
	auth_param_value?: string,
	codeSent?: true
}

export type UserState = {
  session: Session
  userData: N<DetailUser>
  usersList: N<DetailUser[]>
  login: string
  api: {
    pending: boolean
    login: ApiWorker
    logout: ApiWorker
    checkAuth: ApiWorker
    changePassword: ApiWorker
    restorePassword: ApiWorker
    getDetail: ApiWorker
    getList: ApiWorker
    addUser: ApiWorker
    updateUser: ApiWorker
    SMSLogin: {
      sendPhone: ApiWorker
      sendCode: ApiWorker
      pending: boolean
      errors: ApiError[]
    }
    sendOrderLink: ApiWorker
  }
}

// =====================================
