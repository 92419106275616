import SVG from "../SVG";
import { IconProps } from "../types";

const OrderList = (props: IconProps) => {
    return (
        <SVG {...props} width={props.width ?? 22} height={props.height ?? 22}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.82087 1.31651C4.10828 0.526136 4.85944 0 5.70046 0H16.2996C17.1406 0 17.8918 0.526133 18.1792 1.31651L21.6563 10.8786C21.8675 11.19 21.9933 11.5641 21.9998 11.9671C22.0004 11.9858 22.0005 12.0045 22 12.0231V19C22 20.6569 20.6569 22 19 22H3.00003C1.34317 22 2.80212e-05 20.6569 2.80212e-05 19V12.0232C-0.000405767 12.0046 -0.000322293 11.9861 0.000287678 11.9674C0.0067226 11.5643 0.132459 11.1901 0.343741 10.8786L3.82087 1.31651ZM5.70046 2H16.2996L19.2087 10H17C15.8575 10 15.0997 10.9097 14.921 11.796C14.5525 13.6239 12.9358 15 11 15C9.06421 15 7.44758 13.6239 7.07903 11.796C6.90033 10.9097 6.14258 10 5.00003 10H2.79136L5.70046 2ZM2.06409 12L2.00003 12.1762V19C2.00003 19.5523 2.44774 20 3.00003 20H19C19.5523 20 20 19.5523 20 19V12.1762L19.936 12H17.0006L16.9999 12.0003C16.9968 12.0016 16.9858 12.0069 16.9699 12.0219C16.9347 12.0551 16.8968 12.1156 16.8816 12.1913C16.3285 14.9342 13.9066 17 11 17C8.09342 17 5.67153 14.9342 5.11848 12.1913C5.10322 12.1156 5.06537 12.0551 5.03015 12.0219C5.01421 12.0069 5.00325 12.0016 5.00015 12.0003L4.99948 12H2.06409ZM7.00003 4C6.44774 4 6.00003 4.44772 6.00003 5C6.00003 5.55228 6.44774 6 7.00003 6H15C15.5523 6 16 5.55228 16 5C16 4.44772 15.5523 4 15 4H7.00003ZM8.00003 8C7.44774 8 7.00003 8.44772 7.00003 9C7.00003 9.55228 7.44774 10 8.00003 10H14C14.5523 10 15 9.55228 15 9C15 8.44772 14.5523 8 14 8H8.00003Z"
                fill="currentColor"
            />
        </SVG>
    );
};

export default OrderList;
