import SVG from "../SVG";
import { IconProps } from "../types";

const EditIcon = (props: IconProps) => {
    return (
        <SVG {...props} width={props.width ?? 20} height={props.height ?? 20}>
            <path d="M6 18.0001V19.0001C6.26522 19.0001 6.51957 18.8947 6.70711 18.7072L6 18.0001ZM2 18.0001H1C1 18.5524 1.44772 19.0001 2 19.0001V18.0001ZM2 14.0001L1.29289 13.293C1.10536 13.4805 1 13.7349 1 14.0001H2ZM13.2929 2.7072L14 3.41431L14 3.41431L13.2929 2.7072ZM14.7071 2.7072L14 3.41431L14 3.41431L14.7071 2.7072ZM17.2929 5.29299L18 4.58588V4.58588L17.2929 5.29299ZM17.2929 6.7072L16.5858 6.00009L17.2929 6.7072ZM6 17.0001H2V19.0001H6V17.0001ZM3 18.0001V14.0001H1V18.0001H3ZM2.70711 14.7072L14 3.41431L12.5858 2.00009L1.29289 13.293L2.70711 14.7072ZM14 3.41431L16.5858 6.00009L18 4.58588L15.4142 2.00009L14 3.41431ZM16.5858 6.00009L5.29289 17.293L6.70711 18.7072L18 7.41431L16.5858 6.00009ZM16.5858 6.00009V6.00009L18 7.41431C18.781 6.63326 18.781 5.36693 18 4.58588L16.5858 6.00009ZM14 3.41431H14L15.4142 2.00009C14.6332 1.21904 13.3668 1.21904 12.5858 2.00009L14 3.41431Z" />
            <path d="M10.7071 5.29289C10.3166 4.90237 9.68342 4.90237 9.29289 5.29289C8.90237 5.68342 8.90237 6.31658 9.29289 6.70711L10.7071 5.29289ZM13.2929 10.7071C13.6834 11.0976 14.3166 11.0976 14.7071 10.7071C15.0976 10.3166 15.0976 9.68342 14.7071 9.29289L13.2929 10.7071ZM9.29289 6.70711L13.2929 10.7071L14.7071 9.29289L10.7071 5.29289L9.29289 6.70711Z" />
        </SVG>
    );
};

export default EditIcon;
