import SVG from "../SVG";
import { IconProps } from "../types";

const FiltersOff = (props: IconProps) => {
    return (
        <SVG {...props} width={props.width ?? 20} height={props.height ?? 20}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 3C1 1.89543 1.89543 1 3 1H17C18.1046 1 19 1.89543 19 3V4.58579C19 5.11622 18.7893 5.62493 18.4142 6L13 11.4142L13 16.382C13 17.1395 12.572 17.832 11.8944 18.1708L9.89443 19.1708C8.56462 19.8357 7 18.8687 7 17.382V11.4142L1.58579 6L2.29289 5.29289L1.58579 6C1.21071 5.62493 1 5.11622 1 4.58579V3ZM17 3H3V4.58579L8.41421 10C8.78929 10.3751 9 10.8838 9 11.4142V17.382L11 16.382V11.4142C11 10.8838 11.2107 10.3751 11.5858 10L17 4.58579V3Z"
                fill="currentColor"
            />
        </SVG>
    );
};

export default FiltersOff;
