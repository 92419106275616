import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { resetSavedOrderNumber } from "../store/savedData";
import { ApiError, N } from "../store/types";
import { getUserData } from "../store/userSlice";
import { CheckAuth, GetUserDetail, Login, Logout } from "../store/userSlice/routines";
import { DetailUser, LoginRequestData, Session } from "../store/userSlice/types";
import { redirectTo } from "../store/viewSlice";


interface IAuthContext {
    session: Session
    showLoginForm: boolean
    userData: N<DetailUser>
    toggleLoginForm: () => void
    hideLoginForm: () => void
    login: (data: LoginRequestData) => void
    logout: () => void
    checkAuth: () => void
    getUserDetail: () => void;
    pending: boolean
    errors: {
        login: ApiError[],
        logout: ApiError[],
        checkAuth: ApiError[],
    }
}

const AuthContext = createContext({
    session: {
        isAuthorised: false,
        auth_param_name: null,
        auth_param_value: null
    },
    showLoginForm: false,
    hideLoginForm: () => {},
    isAuthorised: false,
    userData: null,
    pending: false,
    toggleLoginForm: () => {},
    getUserDetail: () => {},
    login: (data: LoginRequestData) => {},
    logout: () => {},
    checkAuth: () => {},
    errors: {
        login: [],
        logout: [],
        checkAuth: []
    }

} as IAuthContext);

export const useAuthData = () => {
    return useContext(AuthContext)
};

export default function AuthWrapper ({ children } : PropsWithChildren<{}>) {

    const [showLoginForm, setShowLoginForm] = useState(false);

    const location = useLocation();



    const dispatch = useDispatch();

    const user = useSelector(getUserData);

    const toggleLoginForm = useCallback(() => {
        setShowLoginForm(prev => !prev)
    }, [])

    const logout = useCallback(() => {
        dispatch(Logout.request());
        dispatch(resetSavedOrderNumber());
        dispatch(redirectTo('/'))
    }, [dispatch])

    const login = useCallback((data: LoginRequestData) => {
        dispatch(Login.request(data))
    }, [dispatch]);

    const checkAuth = useCallback(() => {
        const { session } = user;
        const { auth_param_name, auth_param_value } = session;

        if (auth_param_name && auth_param_value)
        dispatch(CheckAuth.request({[auth_param_name] : auth_param_value}))
    }, [dispatch, user])

    const getUserDetail = useCallback(() => {
        dispatch(GetUserDetail.request())
    }, [dispatch])

    const pending = user.api.pending;

    const hideLoginForm = useCallback(() => {
        setShowLoginForm(false)
    }, [setShowLoginForm])

    useEffect(() => {
        checkAuth()
    }, [location, user.session])

    return (
        <AuthContext.Provider value={{
            showLoginForm,
            session: user.session,
            userData: user.userData,
            toggleLoginForm,
            hideLoginForm,
            logout,
            login,
            pending,
            errors: {
                login: user.api.login.errors,
                logout: user.api.logout.errors,
                checkAuth: user.api.checkAuth.errors
            },
            checkAuth,
            getUserDetail
        }}>
            {children}
        </AuthContext.Provider>
    )
}