import { Button, Container, RadioSelect } from "../../ui";
import { OfferQuestionProps, RecapchaProps } from "./OfferQuestion";
import { collectAnswerData } from "../helpers/collectAnswerData";
import { useMemo, useState } from "react";
import { Answer } from "../../../store/offerSlice/types";
import { useDispatch } from "react-redux";
import { GetErrorForm } from "../../../store/offerSlice";

const FromRadio = (props: OfferQuestionProps & RecapchaProps) => {
    const { questionData, giveAnswer } = props;
    const { answers } = questionData;

    const dispatch = useDispatch();

    const [value, setValue] = useState({
        answerName: undefined,
        answerId: undefined,
        showError: undefined,
    });

    const disabled = useMemo(() => {
        return value.answerName || value.answerId ? undefined : true;
    }, [value]);

    const next = () => {
        const { answerId, answerName, showError } = value;

        if (answerId && answerName) {
            if (showError) {
                dispatch(GetErrorForm.request(showError))
            } else {
                giveAnswer(
                    collectAnswerData({ questionData, answerName, answerId })
                );
            }
        }
    };

    const sorter = (acc: [string, Answer], next: [string, Answer]) =>
        acc[1].order - next[1].order;

    const items = useMemo(() => {
        if (answers) {
            return Object.entries(answers)
                .sort(sorter)
                .map(([answerId, answer]) => ({
                    value: {
                        answerId,
                        answerName: answer.answerName,
                        showError: answer.showError,
                    },
                    label: answer.answerName,
                }));
        } else {
            return [];
        }
    }, [answers]);

    return (
        <Container.Flex fullWidth verticalGap={16} className="show">
            <RadioSelect items={items} onChange={(value) => setValue(value)} />
            <Button onClick={next} fullWidth disabled={disabled} withLoader pending={props.recaptchaPending}>
                ДАЛЕЕ
            </Button>
        </Container.Flex>
    );
};

export default FromRadio;
