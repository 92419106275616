import SVG from "../SVG";
import { IconProps } from "../types";

const UserIcon = (props: IconProps) => {
    return (
        <SVG {...props} width={24} height={24}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.9655 17.6997C20.237 16.1476 21 14.1629 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 14.1629 3.76299 16.1477 5.03451 17.6997C5.28161 15.0615 6.83614 12.7855 9.04146 11.6921C8.39445 10.9814 8 10.0368 8 9C8 6.79086 9.79086 5 12 5C14.2091 5 16 6.79086 16 9C16 10.0368 15.6056 10.9814 14.9585 11.6921C17.1639 12.7855 18.7184 15.0615 18.9655 17.6997ZM17 19.4844L17 18.4393C17 15.3675 14.6961 13 12 13C9.30397 13 7.00002 15.3675 7.00002 18.4393L7.00002 19.4845C8.43011 20.4417 10.1499 21 12 21C13.8502 21 15.5699 20.4417 17 19.4844ZM12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM12 7C10.8954 7 10 7.89543 10 9C10 10.1046 10.8954 11 12 11C13.1046 11 14 10.1046 14 9C14 7.89543 13.1046 7 12 7Z"
                fill="currentColor"
            />
        </SVG>
    );
};

export default UserIcon;
