import SVG from "../SVG";
import { IconProps } from "../types";

const List = (props: IconProps) => {
    return (
        <SVG {...props} width={props.width ?? 22} height={props.height ?? 18}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 3C2 2.44772 2.44772 2 3 2H19C19.5523 2 20 2.44772 20 3V15C20 15.5523 19.5523 16 19 16H3C2.44772 16 2 15.5523 2 15V3ZM3 0C1.34315 0 0 1.34315 0 3V15C0 16.6569 1.34315 18 3 18H19C20.6569 18 22 16.6569 22 15V3C22 1.34315 20.6569 0 19 0H3ZM5 6C4.44772 6 4 6.44772 4 7C4 7.55228 4.44772 8 5 8H11.8571C12.4094 8 12.8571 7.55228 12.8571 7C12.8571 6.44772 12.4094 6 11.8571 6H5ZM5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H17C17.5523 13 18 12.5523 18 12C18 11.4477 17.5523 11 17 11H5Z"
                fill="currentColor"
            />
        </SVG>
    );
};

export default List;
