import { combineReducers, configureStore, getDefaultMiddleware } from "@reduxjs/toolkit"
import createSagaMiddleware from 'redux-saga'
import rootSaga from "./saga";
import userSlice from "./userSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import offerSlice from "./offerSlice";
import viewSlice from "./viewSlice";
import orderSlice from "./orderSlice";
import apiSlice from "./apiSlice";
import themeSlice from "./themeSlice";
import savedData from "./savedData";
import contractSlice from "./contractSlice";

// const persistConfig = {
//     key: "root",
//     storage,
//     whitelist: [
//         'user',
//         'saved'
//     ]
// }

const userPersistConfig = {
    key: "user",
    storage,
    whitelist: [
        "session",
        "login"
    ]
}

const orderPersist = {
    key: "order",
    storage,
    whitelist: [
        'orderNumber'
    ]
}

const savePersist = {
    key: 'saved',
    storage,
    whitelist: [
        "orderNumber"
    ]
}

const contractPersist = {
    key: 'contract',
    storage
}

const sagaMiddleWare = createSagaMiddleware();

const rootReducer = combineReducers({
    user: persistReducer(userPersistConfig, userSlice),
    offer: offerSlice,
    order: persistReducer(orderPersist, orderSlice),
    view: viewSlice,
    api: apiSlice,
    theme: themeSlice,
    saved: persistReducer(savePersist, savedData),
    contract: persistReducer(contractPersist, contractSlice)
})

const persistedReducer = rootReducer

const store = configureStore({
    reducer: persistedReducer,
    middleware: [...getDefaultMiddleware({ thunk: false, serializableCheck: false }), sagaMiddleWare],
})

sagaMiddleWare.run(rootSaga)

export type RootState = ReturnType<typeof store.getState>

export default store