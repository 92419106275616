const getDateProps = (date?: Date) => {
  const _date = date ?? new Date()

  return {
    year: _date.getFullYear(),
    month: _date.getMonth(),
    day: _date.getDate(),
    hours: _date.getHours(),
    minutes: _date.getMinutes(),
    seconds: _date.getSeconds(),
    mseconds: _date.getMilliseconds(),
  }
}

export const getFormatDateString = (date: Date | null) => {
  if (!date) return ''
  const { day, month, year } = getDateProps(date)
  const formatDay = day < 10 ? '0' + day : '' + day
  const formatMonth = month < 10 ? '0' + month : '' + month
  const formatYear = ('' + year).slice(2)

  return `${formatDay}.${formatMonth}.${formatYear}`
}

export const getFromToday = () => {
  const { year, month, day } = getDateProps()
  return { from: new Date(year, month, day, 0, 0, 0, 0), to: new Date() }
}

const getDateByMs = (ms: number, ...rest: any[]) => {
  const data = new Date(ms)
  const { year, month, day } = getDateProps(data)
  return new Date(year, month, day, ...rest)
}

export const getFromYesterday = () => {
  const date = Date.now()
  const yesterday = date - 1 * 24 * 60 * 60 * 1000
  return { from: getDateByMs(yesterday), to: getDateByMs(yesterday, 23, 59, 59, 59) }
}

export const getFromWeek = () => {
  const date = Date.now()
  const weekAgo = date - 7 * 24 * 60 * 60 * 1000
  return { from: getDateByMs(weekAgo), to: new Date() }
}

export const getFrom20days = () => {
  const date = Date.now()
  const twentyDaysAgo = date - 20 * 24 * 60 * 60 * 1000
  return { from: getDateByMs(twentyDaysAgo), to: new Date() }
}
