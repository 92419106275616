import { Fragment, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { getOrderData } from '../../store/orderSlice'
import { Icon, Typography } from '../ui'
import SaveOrder from './SaveOrder'
import { LowerMenuProps } from './types'

const LowerMenuWrapper = styled.div`
  position: sticky;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.background.black60};
  padding: 8px 23px;
  gap: 16px;
  height: 64px;
  margin: 0 auto 24px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.04);
  backdrop-filter: blur(8px);
  border-radius: 39px;
  transition: all 200ms ease;
  bottom: 24px;

  &:hover {
    bottom: 26px;
  }
`

const LowerMenuItem = styled.div`
  color: #fff;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  max-width: 64px;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  transition: all 200ms ease;

  &:hover {
    color: ${props => props.theme.colors.text.quaternary};
  }
`

const LowerMenuContent = (props: LowerMenuProps) => {
  const { items } = props
  const { order } = useSelector(getOrderData)

  const orderNumber = order.data?.number

  const [showSaveForm, setShowSaveForm] = useState(false)

  const navigate = useNavigate()

  const onClick = (link: string) => () => {
    if (link.startsWith('handler')) {
      const action = link.replace('handler/', '')
      switch (action) {
        case 'add-device':
          return navigate(`/create/${orderNumber}/`)
        case 'save-order':
          return setShowSaveForm(true)
      }
    } else {
      navigate(link)
    }
  }

  const hideSaveOrder = useCallback(() => {
    setShowSaveForm(false)
  }, [setShowSaveForm])

  return (
    <Fragment>
      <LowerMenuWrapper>
        {items.map((item, key) => (
          <LowerMenuItem key={key} onClick={onClick(item.link)}>
            <Icon name={item.icon} />
            <Typography.LowMenu>{item.title}</Typography.LowMenu>
          </LowerMenuItem>
        ))}
      </LowerMenuWrapper>

      {showSaveForm && !!orderNumber && (
        <SaveOrder orderNumber={orderNumber} hide={hideSaveOrder} />
      )}
    </Fragment>
  )
}

export default LowerMenuContent
