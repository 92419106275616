import styled from 'styled-components'
import { Props } from '../../types'
import { Container, Image, Typography } from '../index'
import { ReactNode, RefObject, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { select } from '../../../store/selector'
import { setSlide } from '../../../store/viewSlice'
import LowerMenu from '../../LowerMenu'

type SliderProps = Props<{
  slides: { img: string; title: string; text: string }[]
}>

const SliderButton = styled.button<{ visible?: boolean }>`
  position: absolute;
  right: 0;
  bottom: 0;

  margin: 0;
  padding: 0 8px;

  opacity: ${props => (props.visible ? 1 : 0)};
  pointer-events: ${props => (props.visible ? 'auto' : 'none')};
  font-size: 14px;
  line-height: 16px;
  color: ${props => props.theme.colors.link.default};
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: opacity 300ms;

  @media (min-width: 660px) {
    display: none;
  }
`

const SliderDot = styled.span<{ current?: boolean }>`
  flex-shrink: 0;
  width: 8px;
  height: 8px;
  margin: 0 6px;

  border-radius: 50%;
  background-color: ${props =>
    props.current
      ? props.theme.colors.accent.default
      : props.theme.colors.icon.quaternary};
`

const DesktopWrapper = styled.div`
  display: block;
  width: 100%;
  @media (max-width: 660px) {
    display: none;
  }
`

const MobileWrapper = styled.div`
  position: relative;
  display: none;
  width: 100%;
  height: calc(100vh - 100px);
  @media (max-width: 660px) {
    display: block;
  }
`

const AnimateWrapper = (props: {
  children: ReactNode
  styles?: {}
  innerRef?: RefObject<HTMLDivElement>
}) => {
  return (
    <Container.Flex
      alignItems="stretch"
      styles={{
        overflowX: 'hidden',
        marginLeft: '-24px',
        marginRight: '-24px',
        width: 'calc(100% + 48px)',
      }}
    >
      <Container.Flex
        alignItems="stretch"
        direction="row"
        styles={props.styles}
        ref={props.innerRef}
      >
        {props.children}
      </Container.Flex>
    </Container.Flex>
  )
}

const MainSlider = (props: SliderProps) => {
  const dispatch = useDispatch()

  const view = useSelector(select.view),
    current = view.currentSlide

  const slides = props.slides

  const imageRow = useRef<HTMLDivElement>(null),
    titleRow = useRef<HTMLDivElement>(null),
    textRow = useRef<HTMLDivElement>(null)

  const notLast = () => {
    if (current < slides.length - 1) {
      return true
    } else {
      return false
    }
  }

  const notFirst = () => {
    if (current > 0) {
      return true
    } else {
      return false
    }
  }

  const [oldX, setOldX] = useState(0)

  const onTouchStart = (event: any) => {
    const x = event.touches[0].pageX
    if (imageRow.current) {
      imageRow.current.style.transition = 'none'
    }
    if (titleRow.current) {
      titleRow.current.style.transition = 'none'
    }
    if (textRow.current) {
      textRow.current.style.transition = 'none'
    }

    setOldX(x)
  }
  const onTouchEnd = (event: any) => {
    const x = event.changedTouches[0].pageX
    const diff = (oldX - x) / window.outerWidth

    if (imageRow.current) {
      imageRow.current.removeAttribute('style')
    }
    if (titleRow.current) {
      titleRow.current.removeAttribute('style')
    }
    if (textRow.current) {
      textRow.current.removeAttribute('style')
    }

    if (diff > 0.3 && notLast()) {
      dispatch(setSlide(current + 1))
    } else if (diff < -0.3 && notFirst()) {
      dispatch(setSlide(current - 1))
    }
  }

  return (
    <Container.Flex
      styles={{ position: 'relative' }}
      breakpoints={{
        660: {
          paddingTop: '16px',
        },
      }}
    >
      {/* slider in mobile */}
      <MobileWrapper>
        <div onTouchStart={onTouchStart} onTouchEnd={onTouchEnd}>
          <AnimateWrapper
            innerRef={imageRow}
            styles={{
              transition: 'margin 800ms ease-in-out 100ms',
              marginLeft: `-${current * 100}vw`,
            }}
          >
            {slides.map((slide, index) => {
              return (
                <div
                  key={index}
                  style={{
                    paddingLeft: '24px',
                    paddingRight: '24px',
                    textAlign: 'center',
                    width: '100vw',
                    flexShrink: 0,
                  }}
                >
                  <Image
                    src={slide.img}
                    height={228}
                    alt={slide.title}
                    styles={{ objectFit: 'contain' }}
                  />
                </div>
              )
            })}
          </AnimateWrapper>

          <AnimateWrapper
            innerRef={titleRow}
            styles={{
              transition: 'margin 700ms ease-in-out 0ms',
              marginLeft: `-${current * 100}vw`,
            }}
          >
            {slides.map((slide, index) => {
              return (
                <div
                  key={index}
                  style={{
                    paddingLeft: '24px',
                    paddingRight: '24px',
                    textAlign: 'center',
                    width: '100vw',
                    flexShrink: 0,
                    willChange: 'margin, opacity',
                    opacity: current === index ? 1 : 0,
                    transition:
                      current === index
                        ? 'opacity 550ms ease 250ms'
                        : 'opacity 550ms ease 50ms',
                  }}
                >
                  <Typography.Big
                    margin={'32px 22px 24px'}
                    breakpoints={{
                      359.9: {
                        marginLeft: '0',
                        marginRight: '0',
                      },
                    }}
                  >
                    {slide.title}
                  </Typography.Big>
                </div>
              )
            })}
          </AnimateWrapper>

          <AnimateWrapper innerRef={textRow}>
            {slides.map((slide, index) => {
              return (
                <div
                  key={index}
                  style={{
                    paddingLeft: '24px',
                    paddingRight: '24px',
                    textAlign: 'center',
                    opacity: current === index ? 1 : 0,
                    transition: 'opacity 300ms ease',
                    width: '100vw',
                    flexShrink: 0,
                    transform: `translateX(-${index * 100}vw)`,
                  }}
                >
                  <Typography.Medium margin={0}>{slide.text}</Typography.Medium>
                </div>
              )
            })}
          </AnimateWrapper>
        </div>
        <Container.Flex fullWidth padding={'0 0 0 0'}>
          <Container.Flex
            fullWidth
            justify="center"
            breakpoints={{
              660: {
                marginTop: '32px',
              },
            }}
            styles={{
              position: 'relative',
            }}
          >
            <Container.Flex
              fullWidth
              direction="row"
              justify="center"
              styles={{ position: 'relative' }}
            >
              {slides.map((_, index) => {
                return <SliderDot key={index} current={index === current} />
              })}

              <SliderButton
                visible={current < slides.length - 1}
                onClick={() => {
                  dispatch(setSlide(current + 1))
                }}
              >
                Далее
              </SliderButton>
              <SliderButton
                visible={current === slides.length - 1}
                onClick={() => {
                  dispatch(setSlide(0))
                }}
              >
                В начало
              </SliderButton>
            </Container.Flex>
          </Container.Flex>
        </Container.Flex>

        <Container.Flex styles={{ width: '187px', position: 'absolute', bottom: '0', left: 'calc(50% - (187px / 2))' }} margin="auto" >
          <LowerMenu />
        </Container.Flex>
      </MobileWrapper>

      {/* column in tablet, desktop */}
      <DesktopWrapper>
        <Container.Flex
          gap={42}
          fullWidth
          styles={{
            maxWidth: '880px',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          breakpoints={{
            1024: {
              gap: '16px',
            },
          }}
        >
          {slides.map((slide, index) => {
            return (
              <Container.Flex
                key={index}
                direction="row"
                fullWidth
                styles={{
                  minHeight: '280px',
                }}
                horizontalGap={72}
                breakpoints={{
                  1024: {
                    columnGap: '36px',
                  },
                }}
              >
                <Image
                  src={slide.img}
                  height={278}
                  width={380}
                  alt={slide.title}
                  styles={{
                    objectFit: 'contain',
                    maxWidth: 'none',
                  }}
                  breakpoints={{
                    768: {
                      maxWidth: '50%',
                    },
                  }}
                />
                <Container.Flex
                  alignItems="stretch"
                  styles={{
                    order: index % 2 === 0 ? 1 : -1,
                    marginLeft: index % 2 === 0 ? 0 : 'auto',
                    maxWidth: '312px',
                  }}
                >
                  <Typography.Big margin={'0 22px 24px'}>
                    {slide.title}
                  </Typography.Big>
                  <Typography.Medium margin={0}>{slide.text}</Typography.Medium>
                </Container.Flex>
              </Container.Flex>
            )
          })}
          <LowerMenu />
        </Container.Flex>
      </DesktopWrapper>
    </Container.Flex>
  )
}

export default MainSlider
