import styled from 'styled-components'
import { usePartnerDetail } from '../../pages/Partner'
import { Container } from '../ui'
import PartnerAbout from './PartnerAbout'
import PartnerAddresses from './PartnerAdresses'
import PartnerSpecify from './PartnerSpecify'

const Card = styled.div`
  background-color: ${props => props.theme.colors.background.contrast60};
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.04);
  backdrop-filter: blur(8px);
  border-radius: 20px;
  padding: 28px;
  max-width: 724px;
  width: 100%;
`

export default function PartnerDesctiptionCard() {
  const { data } = usePartnerDetail()

  if (!data) return null

  return (
    <Card >
      <Container.Flex
        direction="row"
        justify="between"
        alignItems="start"
        wrapped
        maxWidth={668}
        verticalGap={32}
      >
        <PartnerAbout />
        <PartnerSpecify />
        <PartnerAddresses />
      </Container.Flex>
    </Card>
  )
}
