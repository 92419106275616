import Theme from "../components/Theme"
import Container from "../components/ui/Container"
import Typography from "../components/ui/Typography"

const ThemePage = () => {

    return (
        <Container.Flex fullWidth >
            <Typography.Title>Тема</Typography.Title>
            <Theme />
            
        </Container.Flex>
    )
}

export default ThemePage