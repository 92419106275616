import { Container } from "../ui"

const ProfileEdit = () => {

    return (
        <Container.Flex fullWidth alignItems="stretch" horizontalGap={36}>
            <Container.Flex  verticalGap={24} >
                Редактирование
                {/*<ProfileTop />
                <ProfileMenu />
                <ProfileRostokCount />*/}
            </Container.Flex>

        </Container.Flex>
    )
}

export default ProfileEdit