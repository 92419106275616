import { css, keyframes } from "styled-components";

const show = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`

const hide = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;

const animations = {
    show,
    hide
}

type AnimationProps = {
    duration?: number,
    timingFunction?: 'ease' | 'ease-in' | 'ease-out' | 'linear',
    delay?: number
    iterationCount?: number | 'infinite '
}

export const animate = (keyframe: keyof typeof animations, props?: AnimationProps ) => {
    const getIterationCount = () => {
        if (props?.iterationCount) {
            if (typeof props.iterationCount === 'number') {
                return `${props.iterationCount}ms`
            } else {
                return props.iterationCount
            }
        }
    }
    return css`
        animation-name: ${keyframe};
        animation-duration: ${props?.duration ?? 200}ms;
        animation-timing-function: ${props?.timingFunction ?? 'ease'};
        animation-delay: ${props?.delay ?? 0}ms;
        animation-iteration-count: ${getIterationCount()};
    `
}