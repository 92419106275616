import { Button } from '../../ui'
import { collectAnswerData } from '../helpers/collectAnswerData'
import { useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { setPauseQuestions } from '../../../store/offerSlice'
import { GivenAnswer, Question } from '../../../store/offerSlice/types'
import { useUploadFiles } from '../../../contexts/uploadFiles'
import { setShowImageContainer } from '../../../store/viewSlice'
import { RecapchaProps } from './OfferQuestion'
import { useParams } from 'react-router-dom'

interface UploadImageProps {
  questionData: Question | null
  giveAnswer?: (answer: GivenAnswer) => void
  pauseQuestions: boolean
}

const UploadImage = (props: UploadImageProps & RecapchaProps) => {
  const { questionData, giveAnswer } = props
  const { checkList } = useParams()

  const { addFile } = useUploadFiles()

  const dispatch = useDispatch()

  useEffect(() => {
    if (checkList === 'checkList') {
      dispatch(setPauseQuestions(true))
    }
  }, [dispatch, checkList])

  const onFileInput = useCallback(
    (file: File) => {
      if (typeof window !== 'undefined') {
        if (questionData && giveAnswer) {
          const blob = window.URL.createObjectURL(file)
          dispatch(setShowImageContainer(true))
          addFile(questionData.questionId, { file, blob })
          giveAnswer(
            collectAnswerData({
              questionData,
              answerName: blob,
            }),
          )
        }
      }
      if (ref.current) {
        ref.current.blur()
      }
    },
    [giveAnswer, collectAnswerData, questionData],
  )

  const ref = useRef<HTMLButtonElement>(null)

  return (
    <>
      <Button
        fileInput
        onFileInput={onFileInput}
        icon="photo"
        variant="outline"
        fullWidth
        ref={ref}
      >
        {questionData?.questionInputButtonName ||
          'Выбрать/сделать фото'.toUpperCase()}
      </Button>
    </>
  )
}

export default UploadImage
