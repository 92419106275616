import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createRoutine } from 'redux-saga-routines'
import { RootState } from '..'
import { ApiError, ApiState } from './types'

const initialState: ApiState = {
    errors: [],
    data: null,
    status: null,
}

export const API_GET = createRoutine('Api/GET')
export const API_POST = createRoutine('Api/POST')

const apiSlice = createSlice({
    name: 'api',
    initialState,
    reducers: {
        resoterApiState(state) {
            state.data = null
            state.errors = []
            state.status = null
        },
    },
    extraReducers: {
        [API_GET.REQUEST](state) {
            state.data = null
            state.status = 'pending'
        },
        [API_GET.SUCCESS](state, { payload }: PayloadAction<any>) {
            state.data = payload
            state.status = 'success'
            state.errors = []
        },
        [API_GET.FAILURE](state, { payload }: PayloadAction<ApiError[]>) {
            state.errors = payload
            state.status = 'error'
            state.data = null
        },
        [API_POST.REQUEST](state) {
            state.data = null
            state.status = 'pending'
        },
        [API_POST.SUCCESS](state, { payload }: PayloadAction<any>) {
            state.data = payload
            state.status = 'success'
            state.errors = []
        },
        [API_POST.FAILURE](state, { payload }: PayloadAction<ApiError[]>) {
            state.errors = payload
            state.status = 'error'
            state.data = null
        },
    },
})

export const getApiState = createSelector(
    (state: RootState) => state.api,
    data => data,
)

export const { resoterApiState } = apiSlice.actions

export default apiSlice.reducer
