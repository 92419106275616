import SVG from '../SVG'
import { IconProps } from '../types'

const LeafIcon = (props: IconProps) => {
  return (
    <SVG {...props} width={props.width ?? 20} height={props.height ?? 20}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.08243 13.4125L7.94975 10.5452C8.34027 10.1546 8.97344 10.1546 9.36396 10.5452C9.75449 10.9357 9.75449 11.5688 9.36396 11.9594L6.49656 14.8268C7.67873 15.3539 8.76276 15.5489 9.72415 15.4857C10.9789 15.4033 12.1034 14.877 13.049 13.9314C14.8595 12.1208 16.0508 8.71267 15.8963 4.0131C11.1967 3.85851 7.78849 5.04978 5.97796 6.8603C5.0323 7.80596 4.50603 8.9304 4.4236 10.1852C4.36045 11.1465 4.5554 12.2304 5.08243 13.4125ZM3.58618 14.9087C2.71549 13.2434 2.32615 11.6028 2.4279 10.0541C2.54434 8.28154 3.2997 6.71013 4.56375 5.44609C7.06065 2.9492 11.4684 1.69864 16.9163 2.06184C17.4161 2.09517 17.8142 2.49324 17.8475 2.99311C18.2107 8.44091 16.9601 12.8487 14.4632 15.3456C13.1992 16.6096 11.6278 17.365 9.85526 17.4814C8.3064 17.5832 6.66572 17.1938 5.00032 16.323L3.70711 17.6162C3.31658 18.0067 2.68342 18.0067 2.29289 17.6162C1.90237 17.2257 1.90237 16.5925 2.29289 16.202L3.58618 14.9087Z"
        fill="currentColor"
      />
    </SVG>
  )
}

export default LeafIcon
