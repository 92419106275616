import { createSelector } from '@reduxjs/toolkit'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import { CheckAuthData, DetailUser, LogoutSuccessData, Session, SMSLoginResponseData, UserState } from './types'
import {
    Login,
    Logout,
    CheckAuth,
    ChangePassword,
    AddUserData,
    UpdateUserData,
    GetUserDetail,
    GetUsersList,
    RestorePassword,
    SMSLogin,
    SendOrderLink,
} from './routines'
import { ApiError } from '../types';

const getApiWorker = () => ({
    pending: false,
    status: null,
    errors: []
})

const initialState: UserState = {
    session: {
        auth_param_name: null,
        auth_param_value: null,
        isAuthorised: false,
    },
    userData: null,
    usersList: null,
    login: "",
    api: {
        login: {
            pending: false,
            status: null,
            errors: [],
        },
        logout: {
            pending: false,
            status: null,
            errors: [],
        },
        checkAuth: {
            pending: false,
            status: null,
            errors: [],
        },
        changePassword: {
            pending: false,
            status: null,
            errors: [],
        },
        restorePassword: {
            pending: false,
            status: null,
            errors: [],
        },
        addUser: {
            pending: false,
            status: null,
            errors: [],
        },
        updateUser: {
            pending: false,
            status: null,
            errors: [],
        },
        getList: {
            pending: false,
            status: null,
            errors: [],
        },
        getDetail: {
            pending: false,
            status: null,
            errors: [],
        },
        SMSLogin: {
            sendPhone: {
                pending: false,
                status: null,
                errors: []
            },
            sendCode: {
                pending: false,
                status: null,
                errors: []
            },
            pending: false,
            errors: []
        },
        sendOrderLink: getApiWorker(),
        pending: false,
    },

}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        resetSMSApi(state) {
            state.api.SMSLogin.pending = false;
            state.api.SMSLogin.sendCode.status = null;
            state.api.SMSLogin.sendPhone.status = null;
            state.api.SMSLogin.errors = [];
        },
        resetSendLink(state) {
            state.api.sendOrderLink.pending = false;
            state.api.sendOrderLink.status = null;
            state.api.sendOrderLink.errors = [];
        }
    },
    extraReducers: {
        [Login.REQUEST](state) {
            state.api.pending = true
            state.api.login.pending = true
        },
        [Login.SUCCESS](state, { payload }: PayloadAction<Session>) {
            state.session = payload
            state.api.login.status = "success"
        },
        [Login.FAILURE](state, { payload }: PayloadAction<ApiError[]>) {
            state.api.login.errors = payload;
            state.api.login.status = "error"
        },
        [Login.FULFILL](state) {
            state.api.pending = false
            state.api.login.pending = false
        },
        [CheckAuth.REQUEST](state) {
            state.api.pending = true;
            state.api.checkAuth.pending = true
        },
        [CheckAuth.SUCCESS](state, { payload } : PayloadAction<CheckAuthData>) {
            state.api.checkAuth.status = "success";
            state.session.isAuthorised = payload.isAuthorised;
            state.login = payload.login
        },
        [CheckAuth.FAILURE](state, { payload } : PayloadAction<ApiError[]>) {
            state.api.checkAuth.status = "error";
            state.session.isAuthorised = false;
            state.api.checkAuth.errors = payload 
        },
        [CheckAuth.FULFILL](state) {
            state.api.pending = false;
            state.api.checkAuth.pending = false;
        },
        [Logout.REQUEST](state) {
            state.api.logout.pending = true
            state.api.pending = true
        },
        [Logout.SUCCESS](state, { payload } : PayloadAction<LogoutSuccessData>) {
            state.api.logout.status = "success";
            state.session.isAuthorised = payload.isAuthorised;
            state.session.auth_param_name = null;
            state.session.auth_param_value = null;
            state.userData = null;
        },
        [Logout.FAILURE](state, { payload } : PayloadAction<ApiError[]>) {
            state.api.logout.status = "error";
            state.api.logout.errors = payload
        },
        [Logout.FULFILL](state) {
            state.api.pending = false;
            state.api.logout.pending = false;
        },
        [ChangePassword.REQUEST](state) {
            state.api.pending = true;
            state.api.changePassword.pending = true;
        },
        [ChangePassword.SUCCESS](state) {
            state.api.changePassword.status = "success"
        },
        [ChangePassword.FAILURE](state, { payload } : PayloadAction<ApiError[]>) {
            state.api.changePassword.status = "error"
            state.api.changePassword.errors = payload
        },
        [ChangePassword.FULFILL](state) {
            state.api.pending = false
            state.api.changePassword.pending = false
        },
        [AddUserData.REQUEST](state) {
            state.api.pending = true;
            state.api.addUser.pending = true;
        },
        [AddUserData.SUCCESS](state, { payload } : PayloadAction<DetailUser>) {
            state.api.addUser.status = "success"
            state.userData = payload
        },
        [AddUserData.FAILURE](state, { payload } : PayloadAction<ApiError[]>) {
            state.api.addUser.status = "error"
            state.api.addUser.errors = payload
        },
        [AddUserData.FULFILL](state) {
            state.api.pending = false
            state.api.addUser.pending = false
        },
        [UpdateUserData.REQUEST](state) {
            state.api.pending = true;
            state.api.updateUser.pending = true;
        },
        [UpdateUserData.SUCCESS](state, { payload } : PayloadAction<DetailUser>) {
            state.api.updateUser.status = "success"
            state.userData = payload
        },
        [UpdateUserData.FAILURE](state, { payload } : PayloadAction<ApiError[]>) {
            state.api.updateUser.status = "error"
            state.api.updateUser.errors = payload
        },
        [UpdateUserData.FULFILL](state) {
            state.api.pending = false
            state.api.updateUser.pending = false
        },
        [GetUserDetail.REQUEST](state) {
            state.api.pending = true;
            state.api.getDetail.pending = true;
        },
        [GetUserDetail.SUCCESS](state, { payload } : PayloadAction<DetailUser>) {
            state.api.getDetail.status = "success"
            state.userData = payload
        },
        [GetUserDetail.FAILURE](state, { payload } : PayloadAction<ApiError[]>) {
            state.api.getDetail.status = "error"
            state.api.getDetail.errors = payload
        },
        [GetUserDetail.FULFILL](state) {
            state.api.pending = false
            state.api.getDetail.pending = false;
            state.api.pending = false;
        },
        [GetUsersList.REQUEST](state) {
            state.api.pending = true;
            state.api.getList.pending = true;
        },
        [GetUsersList.SUCCESS](state, { payload } : PayloadAction<DetailUser[]>) {
            state.api.getList.status = "success"
            state.usersList = payload
        },
        [GetUsersList.FAILURE](state, { payload } : PayloadAction<ApiError[]>) {
            state.api.getList.status = "error"
            state.api.getList.errors = payload
        },
        [GetUsersList.FULFILL](state) {
            state.api.pending = false
            state.api.getList.pending = false
            state.api.pending = false;
        },
        [RestorePassword.REQUEST](state) {
            state.api.pending = true;
            state.api.restorePassword.pending = true;
        },
        [RestorePassword.SUCCESS](state) {
            state.api.restorePassword.status = "success"
        },
        [RestorePassword.FAILURE](state, { payload } : PayloadAction<ApiError[]>) {
            state.api.restorePassword.status = "error"
            state.api.restorePassword.errors = payload
        },
        [RestorePassword.FULFILL](state) {
            state.api.pending = false
            state.api.restorePassword.pending = false
        },
        [SMSLogin.REQUEST](state) {
            state.api.SMSLogin.pending = true;
        },
        [SMSLogin.SUCCESS](state, { payload } : PayloadAction<SMSLoginResponseData>) {
            if (payload.codeSent) {
                state.api.SMSLogin.sendPhone.status = 'success';
            } else {
                state.api.SMSLogin.sendCode.status = 'success';
                state.session.auth_param_name = payload.auth_param_name ?? "";
                state.session.auth_param_value = payload.auth_param_value ?? "";
                state.session.isAuthorised = payload.isAuthorised;
            }
        },
        [SMSLogin.FAILURE](state, { payload } : PayloadAction<ApiError[]>) {
            state.api.SMSLogin.errors = payload;
        },
        [SMSLogin.FULFILL](state) {
            state.api.SMSLogin.pending = false;
        },
        [SendOrderLink.REQUEST](state) {
            state.api.sendOrderLink.pending = true;
        },
        [SendOrderLink.SUCCESS](state) {
            state.api.sendOrderLink.status = "success"
        },
        [SendOrderLink.FAILURE](state, { payload } : PayloadAction<ApiError[]>) {
            state.api.sendOrderLink.status = "error";
            state.api.sendOrderLink.errors = payload;
        },
        [SendOrderLink.FULFILL](state) {
            state.api.sendOrderLink.pending = false
        },

    },
})

export const getUserData = createSelector(
    (state: RootState) => state.user,
    user => user,
)

export const getSMSLoginData = createSelector(
    (state: RootState) => state.user,
    user => ({
        codeSent: user.api.SMSLogin.sendPhone.status === "success",
        coodeSentSuccess: user.api.SMSLogin.sendCode.status === "success",
        pending: user.api.SMSLogin.pending,
        error: user.api.SMSLogin.errors.map(el => el.message).join('. ')
    })
)

export const getSendOrderLinkData = createSelector(
    (state: RootState) => state.user,
    user => ({...user.api.sendOrderLink, error: user.api.sendOrderLink.errors.map(el => el.message).join('. ')})
)

export const { resetSMSApi, resetSendLink } = userSlice.actions

export default userSlice.reducer
