import { memo } from 'react';
import SaveOrderForm from '../Login/SaveOrderForm'
import { Overlay } from '../ui'

interface SaveOrderProps {
  orderNumber: string
  hide: () => void;
}

const SaveOrder = (props: SaveOrderProps) => {
  const { orderNumber, hide } = props;


  return (
    <Overlay>
      <SaveOrderForm orderNumber={orderNumber} hide={hide}/>
    </Overlay>
  )
}

export default memo(SaveOrder)
