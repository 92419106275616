import styled from 'styled-components/macro'
import { TextProps } from '../types'
import { getTextAlign } from './helpers'
import { getCommonProps, collectTypographyStyles } from '../../../types'
import { IndicatorStyles } from '..'
import Icon from '../../Icon'
import { useLink } from './useLink'

export const SmallLowMenu = styled.span<TextProps>`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: inherit;
  ${props => getTextAlign(props)};
  ${props => getCommonProps(props)};
  ${(props) => collectTypographyStyles(props)};
  ${props => ({
    ...props.theme.typography.small,
    ...props.styles,
  })};
  line-height: 12px;
  ${props => (props.color ? { color: props.color } : { color: "inherit"})};
  ${props =>
    props.secondary ? { color: props.theme.colors.text.secondary } : {}};
  ${props => {
    return props.maxContent ? { width: 'max-content' } : {}
  }};
    transition: ${props => props.noTransition ? "none" : "all 200ms ease-in"};
  ${props => (props.onClick ? { cursor: 'pointer' } : {})};
`

export default function Small(props: TextProps) {
  const { navigate } = useLink();
  return (
    <SmallLowMenu {...props} onClick={props.onClick ?? navigate(props.link)}>
      {props.children}
      {props.withIndicator && (
        <Icon name="color-indicator" styles={IndicatorStyles} />
      )}
    </SmallLowMenu>
  )
}
