import { Container } from '../ui'
import { FiltersPart } from './FiltersContext'
import FiltersList from './FiltersList'

export default function FiltersItem({ filter }: { filter: FiltersPart }) {

  return (
    <Container.Flex fullWidth>
      <Container.Flex fullWidth>
        <FiltersList filter={filter} />
      </Container.Flex>
    </Container.Flex>
  )
}
