import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Offer from '../components/Offer'
import Order from '../components/Order/Order'
import { useUploadFiles } from '../contexts/uploadFiles'
import { restoreOffer, setStep } from '../store/offerSlice'
import { GetOrder, getOrderItemData } from '../store/orderSlice'
import PageLayout from './PageLayout'

function CreatePage() {
  const { itemNumber, orderNumber } = useParams()
  const { isLoading, errors, orderData } = useSelector(getOrderItemData)
  const dispatch = useDispatch()

  const { setFiles } = useUploadFiles()

  useEffect(() => {
    setFiles({})
    if (!itemNumber) {
      dispatch(restoreOffer())
    }
    dispatch(setStep('questions'))
  }, [itemNumber])

  const currentItem = useMemo(() => {
    return orderData?.items.find(item => item.itemNumber === itemNumber)
  }, [orderData])

  useEffect(() => {
    if (!orderNumber) return
    if (!isLoading && !orderData && !errors.length) {
      dispatch(GetOrder.request({ itemNumber, orderNumber }))
    }
  }, [orderData, isLoading, errors, orderNumber])

  const showOffer = useMemo(() => {
    if (!itemNumber) return true
    if (isLoading) return false
    if (!currentItem) return false
    if (!itemNumber && !orderNumber) return true
    return true
  }, [isLoading, currentItem])

  return (
    <PageLayout withProfile>
      {showOffer && <Offer />}
      {!!orderNumber && <Order orderNumber={orderNumber} withoutHeader withoutLoader/>}
    </PageLayout>
  )
}

export default CreatePage
