import { useDispatch, useSelector } from 'react-redux';
import { getSetRegistrationData, SetOrderRegistration } from '../store/orderSlice/index';
import { useCallback } from 'react';

export default function useSetRegistrationData () {

    const dispatch = useDispatch();

    const data = useSelector(getSetRegistrationData);

    const setRegistration = useCallback((code: string) => {
        dispatch(SetOrderRegistration.request(code))
    }, [])

    return ({
        ...data,
        setRegistration
    })    

}