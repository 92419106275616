import { memo, useState, useMemo, useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useDeleteOrder } from '../../hooks/useDeleteOrder'
import { useSession } from '../../hooks/useSession'
import { RootState } from '../../store'
import { GetOrder, getOrderItemData } from '../../store/orderSlice'
import { ItemStatuses, OrderItem } from '../../store/orderSlice/types'
import { UserType } from '../../store/userSlice/types'
import { Button, Confirm, Typography } from '../ui'
import OrderHeader from './OrderHeader'
import OrderFinal from './OrderFinal'
import OrderShortItem from './OrderShortItem'
import PageLoader from '../Layout/PageLoading'
import OfferOrdering from '../Offer/OfferOrdering'

interface OrderProps {
  orderNumber: string
  itemNumber?: string
  withoutHeader?: true
  withoutLoader?: true
}

const getProgress = (item: OrderItem) => {
  switch (item.status) {
    case 'F':
      return 0.75
    case 'D':
      return 1
    default:
      return 1
  }
}

const Order = (props: OrderProps) => {
  const { orderNumber, itemNumber, withoutHeader, withoutLoader } = props
  const dispatch = useDispatch()

  const { isLoading, orderData, showResultFor, errors } =
    useSelector(getOrderItemData)

  const [showConfirm, setShowConfirm] = useState(false)
  const { isAdmin } = useSession()

  const {
    onDeleteOrder,
    pending,
    error: deleteOrderError,
    status,
    setStatus,
  } = useDeleteOrder()

  const error = useMemo(() => {
    if (!errors?.length) return '' || deleteOrderError

    return errors.reduce((acc, next) => acc + next.message, '')
  }, [errors])

  const userRole =
    useSelector((state: RootState) => state.user.userData?.userType) ??
    UserType.quest

  useEffect(() => {
    dispatch(GetOrder.request({ orderNumber }))
  }, [])

  useEffect(() => {
    if (status === 'error') {
      setShowConfirm(false)
    }
  }, [status])

  useEffect(() => {
    setStatus(null)
  }, [showConfirm])

  const showOfferOrdering = useMemo(() => {
    if (!orderData) return false

    if (orderData.registration) return false

    if (orderData.items.some(el => el.status === ItemStatuses.Confirm)) {
      return true
    }
    return false
  }, [orderData])

  const deleteItem = () => {
    setShowConfirm(true)
  }

  const onDelete = () => {
    setShowConfirm(false)
    onDeleteOrder(orderNumber)()
  }


  return (
    <Fragment>
      {showConfirm && (
        <Confirm
          styles={{ maxWidth: '312px', margin: 'auto' }}
          question={`Удалить заказ ${orderNumber}?`}
          onYes={onDelete}
          yesText={'Удалить'}
          noText="Отмена"
          onNo={() => setShowConfirm(false)}
          yesVariant={'danger'}
          noVariant={'primary'}
          questionTitle="Удаление"
        />
      )}
      {(!!error || !!deleteOrderError) && (
        <Typography.Error>{error ?? deleteOrderError}</Typography.Error>
      )}
      {!!orderData && !isLoading && !pending && (
        <Fragment>
          {orderNumber && !withoutHeader && (
            <OrderHeader
              orderNumber={orderNumber}
              userRole={userRole}
              date={orderData?.dateCreate}
              orderStatus={orderData?.status}
              orderStatusColor={orderData?.statusColor}
              deleteItem={deleteItem}
            />
          )}
          {!!orderData && !!orderData?.registration && (
            <OrderFinal data={orderData} />
          )}
          {!orderData?.registration && (
            <Fragment>
              {!!orderData && (
                <Fragment>
                  {!!orderData &&
                    !isLoading &&
                    orderData?.items
                      .filter(el => el.itemNumber !== itemNumber)
                      .map(item => (
                        <OrderShortItem
                          orderNumber={orderData.number}
                          itemData={item}
                          key={item.itemNumber}
                          progress={getProgress(item)}
                          currency={
                            orderData.currencyLang || orderData.currency
                          }
                          showResult={item.itemNumber === showResultFor}
                          withoutLoader={withoutLoader}
                        />
                      ))}
                </Fragment>
              )}
              {showOfferOrdering && !!orderData && (
                <OfferOrdering orderData={orderData} />
              )}

              {isAdmin && (
                <Button
                  variant="danger"
                  margin={'24px 0 0 0'}
                  onClick={deleteItem}
                  styles={{
                    display: 'none',
                  }}
                  breakpoints={{
                    600: {
                      display: 'block',
                    },
                  }}
                >
                  УДАЛИТЬ ЗАЯВКУ
                </Button>
              )}
            </Fragment>
          )}
        </Fragment>
      )}

      {(isLoading || (pending && !withoutLoader)) && <PageLoader />}
    </Fragment>
  )
}

export default memo(Order)
