import { useTheme } from "styled-components";
import Typography from "../Typography";

interface PriceProps {
    amount?: number;
    currency?: string;
    text?: string;
}

const Price = (props: PriceProps) => {
    const theme = useTheme();

    const color = theme.colors.text.default;

    const { amount, currency, text } = props;

    const base = amount ? Math.floor(amount) : null;

    const rest = (base && amount) ? Math.round(100 * (amount % base)) : 0;

    const stringRest = rest > 9 ? "" + rest : "0" + rest;

    return !text ? (
        <>
            <Typography.RublesMedium>
                {"" + base}
            </Typography.RublesMedium>
            <Typography.RublesMedium
                margin={'0 0 0 1px'}
                styles={{
                    fontSize: "10px",
                    transform: "translateY(-2px)"
                }}
            >
                {stringRest}
            </Typography.RublesMedium>
            <Typography.RublesMedium
                styles={{
                    color,
                    marginLeft: "3px"
                }}
            >
                {currency}
            </Typography.RublesMedium>
        </>
    ) : (
        <>
            <Typography.RublesMedium
                styles={{
                    color,
                }}
            >
                {text}
            </Typography.RublesMedium>
        </>
    );
};

export default Price;
