import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Offer from '../components/Offer'
import PageLayout from './PageLayout'
import { addSeveralAnswers, setStep } from '../store/offerSlice'
import { GetOrder, getOrderItemData, setQrCode } from '../store/orderSlice'
import { useUploadFiles } from '../contexts/uploadFiles'
import { RootState } from '../store'
import { ItemStatuses } from '../helpers/getProgressByStatus'
import { getErrorString } from '../helpers/getErrorString'
import { Typography } from '../components/ui'
import Order from '../components/Order/Order'

const OrderItemPage = () => {
  const { itemNumber, orderNumber, checkList } = useParams()
  const { search } = useLocation()

  const navigate = useNavigate()
  const { isLoading, orderData, errors } = useSelector(getOrderItemData)
  const { givenAnswers, step } = useSelector((state: RootState) => state.offer)
  const dispatch = useDispatch()
  const { remoteFiles, setRemoteFiles } = useUploadFiles()

  const qrCode = useMemo(() => {
    const params = new URLSearchParams(search)

    return params.get('qrcode')
  }, [search])

  useEffect(() => {
    if (orderData) {
      const currentItem = orderData.items.find(
        item => item.itemNumber === itemNumber,
      )
      if (currentItem?.status === ItemStatuses.ImageLoaded) {
        setStep('summary')
      } else if (currentItem?.status === 'D') {
        navigate(`/order/${orderNumber}/`)
      } else if (!!checkList) {
        setStep('questions')
      } else {
        if (!qrCode && step !== 'questions') {
          dispatch(setStep('prePrice'))
        }
      }
    }
  }, [orderData, itemNumber, orderNumber, dispatch, qrCode, step])

  const getOrder = useCallback(() => {
    if (!isLoading) {
      dispatch(GetOrder.request({ itemNumber, orderNumber, checkList }))
    }
  }, [isLoading])

  useEffect(() => {
    if (qrCode) {
      dispatch(setQrCode(qrCode))
      dispatch(setStep('questions'))
      // navigate(`/order/${orderNumber}/${itemNumber}/`)
    }
  }, [qrCode])

  const currentItem = useMemo(() => {
    return orderData?.items.find(item => item.itemNumber === itemNumber)
  }, [itemNumber, orderData])

  useEffect(getOrder, [])

  useEffect(() => {
    if (Object.keys(remoteFiles).length) {
      dispatch(addSeveralAnswers(remoteFiles))
      setRemoteFiles({})
    }
  }, [remoteFiles, givenAnswers, dispatch])

  const showOffer = useMemo(() => {
    if (isLoading) return false
    if (!currentItem) return false
    if (!itemNumber && !orderNumber) return true
    return true
  }, [currentItem])

  const error = useMemo(() => getErrorString(errors), [errors])

  if (error) {
    return (
      <PageLayout>
        <Typography.Error>{error}</Typography.Error>
      </PageLayout>
    )
  }

  return (
    <PageLayout withProfile>
      {showOffer && <Offer />}
      {!!orderNumber && (
        <Order
          orderNumber={orderNumber}
          itemNumber={itemNumber}
          withoutHeader
          withoutLoader
        />
      )}
    </PageLayout>
  )
}

export default OrderItemPage
