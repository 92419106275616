import { useDispatch } from 'react-redux'
import { useTheme } from 'styled-components'
import { SetOrderRegistration } from '../../store/orderSlice'
import { UserType } from '../../store/userSlice/types'
import { Container, Typography } from '../ui'

interface IOrderHeader {
  userRole: UserType
  orderNumber: string
  orderStatus?: string
  orderStatusColor?: string
  date?: string
  deleteItem: () => void
}

export default function OrderHeader(props: IOrderHeader) {
  const dispatch = useDispatch()
  const isUser = props.userRole === UserType.quest
  const isAdmin = !isUser
  const theme = useTheme()

  const editOrder = () => {
    dispatch(SetOrderRegistration.request(''))
  }

  return (
    <Container.Flex fullWidth margin={'0 0 24px'}>
      <Container.Flex
        fullWidth
        direction="row"
        justify="start"
        styles={{
          position: 'relative',
        }}
        breakpoints={{
          750: {
            justifyContent: 'space-between'
          }
        }}
      >
        <Typography.Title
          hoverStyles={{
            cursor: 'pointer',
            color: theme.colors.link.hover,
          }}
          onClick={editOrder}
          margin={'0 16px 0 0'}
        >{`Заявка ${props.orderNumber}`}</Typography.Title>
        {isAdmin && (
          <Typography.Link
            href="/profile/order-list/"
            styles={{ fontSize: '14px' }}
            margin={0}
          >
            Все заявки
          </Typography.Link>
        )}

        {isAdmin && (
          <Typography.Medium
            onClick={props.deleteItem}
            color={theme.colors.info.error}
            styles={{
              position: 'absolute',
              right: 0,
              display: 'block',
            }}
            breakpoints={{
              [750]: {
                display: 'none',
              },
            }}
          >
            Удалить заявку
          </Typography.Medium>
        )}
      </Container.Flex>

      {isAdmin && (
        <Container.Flex fullWidth direction="row" justify="between" margin={0}>
          <Typography.Micro secondary>{props.date ?? ''}</Typography.Micro>

          {props.orderStatus && (
            <Typography.Medium
              color={props.orderStatusColor}
              withIndicator
              indicatorColor={props.orderStatusColor}
            >
              {props.orderStatus}
            </Typography.Medium>
          )}
        </Container.Flex>
      )}
    </Container.Flex>
  )
}
