import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import Api, { URLS } from "../apinew";
import { useSession } from "../hooks/useSession";

const Context = createContext({

} as {
    recaptchaKey: string
})

export const useRecaptchaContext = () => {
    return useContext(Context)
}

const RecaptchaContext = ({ children } : { children : ReactNode }) => {

    const [recaptchaKey, setRecapchaKey] = useState("");
    const { session } = useSession();

    useEffect(() => {
        const getKey = async () => {
            const api = new Api();
            const response = await api._get(URLS.getRecapchaKey)(session);
            const newKey: { recaptchaCode: string | null } = response.data;
            setRecapchaKey(newKey.recaptchaCode || "");
        }
        getKey()
    }, [])

    return (
        <Context.Provider value={{recaptchaKey}} >
            {children}
        </Context.Provider>
    )
}

export default RecaptchaContext
