import { styled } from '../../../../helpers/styled'
import { FormEvent, useState } from "react";
import Typography from "../../../ui/Typography";
import { useTheme } from "styled-components/macro";

const Input = styled.input<{error: boolean}>`
    position: relative;
    border: none;
    border-radius: 12px;
    padding: 10px 12px 10px 16px;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: ${props => props.theme.colors.text.default};
    background: ${props => props.error ? props.theme.colors.background.error70 : props.theme.colors.background.contrast70};
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.04);
    backdrop-filter: blur(8px);
    outline: none;
    transition: background-color 250ms ease-in;

    &::placeholder {
        color: ${props => props.theme.colors.text.secondary};
    }
`

interface Props {
    register: any
    placeholder?: string
    required: boolean
    code: string
    defaultValue?: any
    error?: any
    type: string
    value?: string
    hidden: boolean
    pattern?: any
    onInput?: (e: FormEvent<HTMLInputElement>) => void
}

const Label = styled.label`
    position: relative;
    width: 100%;
`

export default function ErrorFormInput(props: Props) {
    const { register, placeholder, required, code, defaultValue, type, onInput, hidden, pattern, error } = props;
    const [value, setValue] = useState(defaultValue);
    const theme = useTheme();
    const [focused, setFocused] = useState(false);
    return (
        <Label>
            <Input
                hidden={hidden}
                type={type}
                placeholder={placeholder}
                id={code}
                {...register(code, {
                    required: {
                        value: required,
                        message: "Это обязательное поле"
                    },
                    pattern
                })}
                onInput={(e: FormEvent<HTMLInputElement>) => {
                    const target = e.target as HTMLInputElement;
                    if (onInput) {
                        onInput(e);
                    }
                    setValue(target.value);
                }}
                onFocus={() => {setFocused(true)}}
                onBlur={() => {setFocused(false)}}
                defaultValue={defaultValue}
                error={!!error && !focused}
            />

            {code === "imei-tel" && value.length > 0 && (
                <Typography.Tertiary
                    styles={{
                        position: "absolute",
                        bottom: "12px",
                        right: "12px",
                    }}
                >
                    {`${value.length}/15`}
                </Typography.Tertiary>
            )}

            {!!error && !focused && (
                <Typography.Micro
                    styles={{
                        position: 'absolute',
                        bottom: 0,
                        right: "4px",
                        transform: "translateY(100%)"
                    }}
                    color={theme.colors.info.error}
                >
                    {error.message}
                </Typography.Micro>
            )}
        </Label>
    )
}
