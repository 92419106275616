import { css } from 'styled-components'
import Typography from '.'
import { Props } from '../../types'

export type TextProps = Props<{
    textAlign?: 'center' | 'end' | 'start'
    underline?: boolean
    onClick?: () => void
    start?: true
    withIndicator?: true
    indicatorColor?: string
    color?: string
    secondary?: true
    maxContent?: true
    link?: string
    tag?: 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | "a" | 'span',
    variant?: keyof typeof Typography
    noTransition?: true
}>

export const getTextProps = (props: TextProps) => {
    return css`
        text-decoration-line: ${props.underline ? 'underline' : 'none'};
        text-decoration: ${props.underline ? 'underline' : 'none'};
    `
}
