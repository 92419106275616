import SVG from "../SVG";
import { IconProps } from "../types";

const QrCodeIcon = (props: IconProps) => {
    return (
        <SVG {...props} width={props.width ?? 20} height={props.height ?? 20}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 3C11 1.89543 11.8954 1 13 1H17C18.1046 1 19 1.89543 19 3V7C19 8.10457 18.1046 9 17 9H13C11.8954 9 11 8.10457 11 7V3ZM17 3H13V7H17V3ZM3 11C1.89543 11 1 11.8954 1 13V17C1 18.1046 1.89543 19 3 19H7C8.10457 19 9 18.1046 9 17V13C9 11.8954 8.10457 11 7 11H3ZM3 13H7V17H3V13ZM18 11C18.5523 11 19 11.4477 19 12V15C19 15.5523 18.5523 16 18 16H15C14.4477 16 14 15.5523 14 15C14 14.4477 14.4477 14 15 14H17V13C16.4477 13 16 12.5523 16 12C16 11.4477 16.4477 11 17 11H18ZM11 18V15C11 14.4477 11.4477 14 12 14C12.5523 14 13 14.4477 13 15V17H14C14.5523 17 15 17.4477 15 18C15 18.5523 14.5523 19 14 19H12C11.4477 19 11 18.5523 11 18ZM17 17C16.4477 17 16 17.4477 16 18C16 18.5523 16.4477 19 17 19H18C18.5523 19 19 18.5523 19 18C19 17.4477 18.5523 17 18 17H17ZM11 12C11 11.4477 11.4477 11 12 11H14C14.5523 11 15 11.4477 15 12C15 12.5523 14.5523 13 14 13H12C11.4477 13 11 12.5523 11 12ZM3 1C1.89543 1 1 1.89543 1 3V7C1 8.10457 1.89543 9 3 9H7C8.10457 9 9 8.10457 9 7V3C9 1.89543 8.10457 1 7 1H3ZM3 3H7V7H3V3Z"
                fill="currentColor"
            />
        </SVG>
    );
};

export default QrCodeIcon;
