import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import PageLoader from "../components/Layout/PageLoading"
import Profile from "../components/Profile"
import { useAuthData } from "../contexts/auth"

const ProfilePage = () => {

    const { getUserDetail, session, pending } = useAuthData()
    const navigate = useNavigate()

    useEffect(() => {
        if (!session.isAuthorised) {
            navigate("/")
        } else {
            getUserDetail()
        }

    }, [getUserDetail, session, navigate])

    if (pending) return <PageLoader />

    return <Profile />
}

export default ProfilePage