import { Order } from '../../../store/orderSlice/types'
import { Container } from '../../ui'
import OrderItems from './OrderItems'
import Shops from '../../Shops'
import { useDispatch } from 'react-redux'
import { SetOrderRegistration } from '../../../store/orderSlice'
import { UserType } from '../../../store/userSlice/types'
import Ordering from './Ordering'
import { useSession } from '../../../hooks/useSession'
import Filters from '../../Filters/FiltersContext'
import { URLS } from '../../../apinew'
import { useState } from 'react'
import { useApi } from '../../../hooks/useApi'

const OrderFinal = ({ data }: { data: Order }) => {
  const dispatch = useDispatch()

  const [partners, setPartners] = useState(data.partners ?? null)
  const [getPartnersPending, setPartnersPending] = useState(false)
  const [getPartnersError, setPartnersError] = useState('')

  const { post } = useApi()

  const editOrder = () => {
    dispatch(SetOrderRegistration.request(''))
  }

  const { userRole } = useSession()

  const getParnters = (data?: any) => {
    setPartnersPending(true)
    post(URLS.getPartners, data).then(response => {
      if (response.status === 'success') {
        setPartners(response.data)
      }
      if (response.status === 'error') {
        setPartnersError(response.errors.join('. '))
      }
      setPartnersPending(false)
    })
  }

  return (
    <Container.Flex verticalGap={24} alignItems="start" >
      <OrderItems order={data} editOrder={editOrder} />

      {[UserType.employee, UserType.partner].includes(userRole) && <Ordering />}

      {!!partners && userRole === UserType.quest && (
        <Filters getFiltersURL={URLS.getPartnersFilter} getData={getParnters}>
          <Shops
            withoutCreate
            partners={partners}
            title="Выберите партнёра"
            pending={getPartnersPending}
            error={getPartnersError}
          />
        </Filters>
      )}
    </Container.Flex>
  )
}

export default OrderFinal
