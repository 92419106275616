import SVG from "../SVG";
import { IconProps  } from "../types";

const PhotoIcon = (props: IconProps) => {
    return (
        <SVG {...props} width={16} height={20}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 0.5H9H9.56309C9.98093 0.5 10.3798 0.674291 10.6637 0.980889L15.1006 5.77276C15.3574 6.05003 15.5 6.41399 15.5 6.79187L15.5 18C15.5 18.8284 14.8284 19.5 14 19.5H2C1.17157 19.5 0.5 18.8284 0.5 18V2C0.5 1.17157 1.17157 0.5 2 0.5ZM2 1.5L8.5 1.5V6C8.5 6.82843 9.17157 7.5 10 7.5L14.5 7.5L14.5 18C14.5 18.2761 14.2761 18.5 14 18.5H2C1.72386 18.5 1.5 18.2761 1.5 18L1.5 2C1.5 1.72386 1.72386 1.5 2 1.5ZM10 6.5L14.406 6.5C14.394 6.48334 14.381 6.46736 14.3669 6.45216L9.92997 1.6603C9.83534 1.5581 9.70237 1.5 9.56309 1.5H9.5V6C9.5 6.27614 9.72386 6.5 10 6.5ZM6.63184 8.5C6.63184 9.32843 5.96026 10 5.13184 10C4.30341 10 3.63184 9.32843 3.63184 8.5C3.63184 7.67157 4.30341 7 5.13184 7C5.96026 7 6.63184 7.67157 6.63184 8.5ZM7.63184 8.5C7.63184 9.88071 6.51255 11 5.13184 11C3.75112 11 2.63184 9.88071 2.63184 8.5C2.63184 7.11929 3.75112 6 5.13184 6C6.51255 6 7.63184 7.11929 7.63184 8.5ZM7.13059 13.3845L5.68462 16.2764C5.60713 16.4314 5.61541 16.6155 5.70651 16.7629C5.79761 16.9103 5.95855 17 6.13184 17H8.13184C8.40798 17 8.63184 16.7761 8.63184 16.5C8.63184 16.2239 8.40798 16 8.13184 16H6.94085L10.0663 9.74916L12.4103 16H10.1318C9.85569 16 9.63184 16.2239 9.63184 16.5V17.5C9.63184 17.7761 9.85569 18 10.1318 18C10.408 18 10.6318 17.7761 10.6318 17.5V17H13.1318C13.2958 17 13.4494 16.9196 13.5428 16.7848C13.6362 16.65 13.6576 16.478 13.6 16.3244L10.6 8.32444C10.5302 8.13823 10.3566 8.01111 10.158 8.00069C9.95945 7.99026 9.77356 8.09852 9.68462 8.27639L7.57942 12.4868C6.95795 12.2268 6.04013 12.1318 5.14524 12.3236C4.15847 12.535 3.13592 13.1101 2.55279 14.2763C2.42929 14.5233 2.5294 14.8236 2.77639 14.9471C3.02338 15.0706 3.32372 14.9705 3.44721 14.7235C3.86408 13.8898 4.59153 13.4649 5.35476 13.3014C6.04798 13.1528 6.72024 13.2318 7.13059 13.3845Z"
                fill="currentColor"
            />
        </SVG>
    );
};

export default PhotoIcon;
