import { styled } from '../../../helpers/styled'

export type PopupTriggerProps = {
    text: string
    showPopup: () => void
}

const Text = styled.span`
    font-size: 16px;
    line-height: 20px;
    color: ${props => props.theme.colors.link.default};
    text-decoration: underline;
    &:hover {
        cursor: pointer;
    }
`

const PopupTrigger = (props: PopupTriggerProps) => {
    const { text, showPopup } = props

    return <Text onClick={showPopup}>{text}</Text>
}

export default PopupTrigger
