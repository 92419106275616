import { createGlobalStyle, css } from 'styled-components'
import { animate } from '../../styles/keyframes'
import { zIndexes } from '../../zIndexes'
import { Container } from '../ui'
import { useFiltersData } from './FiltersContext'
import { Fragment, useEffect, useRef, useState } from 'react'
import FiltersMain from './FiltersMain'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/effect-fade'
import FiltersHeader from './FiltersHeader'
import FiltersItem from './FiltersItem'
import FiltersButtons from './FiltersButton'
import cls from 'classnames'

const BoxStyles = css`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: ${zIndexes.overlay};
  top: 0;
  left: 0;
  ${animate('show')};
  background: rgba(0, 0, 0, 0.7);
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.04);
  backdrop-filter: blur(8px);
  padding: 64px 24px 0px 24px;
  overflow: hidden;
  transition: height 150ms ease;
  cursor: pointer;
`

const CardProps = css`
  width: 100%;
  max-width: 312px;
  overflow: hidden;
  padding: 28px;
  background-color: ${props => props.theme.colors.text.contrast};
  border-radius: 28px;
  transition: height 150ms ease-in 50ms;
`

const GlobalStyles = createGlobalStyle`
  .swiper {
    width: 100%
  }

  .swiper-slide-active {
  opacity: 1;
  transition: opacity 400ms ease-in;
}

.swiper-slide-next {
  opacity: 0;
  transition: opacity 400ms ease-in;
}

.swiper-slide-prev {
  opacity: 0;
  transition: opacity 400ms ease-in;
}
`

const classes = {
  hideToRight: 'hide-filters-body-to-right',
  hideToLeft: 'hide-filters-body-to-left',
  showFromLeft: 'show-filters-body-from-left',
  showFromRight: 'show-filters-body-from-right',
  hidden: 'filters-hidden',
  item: 'filters-item',
}

export default function FiltersComponent() {
  const { showFilters, filters, setShowFilters, currentFilter } =
    useFiltersData()
  const [display, setDisplay] = useState<'flex' | 'none'>('none')


  const containerRef = useRef<HTMLDivElement>(null)
  const headerRef = useRef<HTMLDivElement>(null)

  const onTransitionEnd = () => {
    if (!showFilters) {
      setDisplay('none')
    }
  }

  useEffect(() => {
    const baseHeight = 246

    if (!currentFilter) {
      if (containerRef.current) {
        containerRef.current.style.height = `${baseHeight}px`
      }
    } else {
      if (containerRef.current) {
        containerRef.current.style.height = `${
          currentFilter.items.length * 44 + 156
        }px`
      }
    }
  }, [currentFilter])

  useEffect(() => {
    if (showFilters) {
      setDisplay('flex')
    }
  }, [showFilters, setDisplay])

  const close = (e: any) => {
    if (e.target.classList.contains('show-filters')) {
      setShowFilters(false)
    }
  }

  return (
    <Container.Flex
      css={BoxStyles}
      className={showFilters ? 'show-filters' : 'hide-filters'}
      styles={{ display }}
      onTransitionEnd={onTransitionEnd}
      onClick={close}
    >
      <GlobalStyles />
      <Container.Flex css={CardProps} ref={containerRef}>
        <Container.Flex verticalGap={32} fullWidth>
          <FiltersHeader ref={headerRef} />
          <Container.Flex fullWidth styles={{ position: 'relative' }}>
            <Container.Flex
              fullWidth
              verticalGap={32}
              className={cls({
                [classes.item]: true,
                [classes.showFromLeft]: !currentFilter,
                [classes.hideToLeft]: !!currentFilter
              })}
            >
              {filters && !currentFilter && (
                <Fragment>
                  <FiltersMain filters={filters} />
                  <FiltersButtons root />
                </Fragment>
              )}
            </Container.Flex>
            <Container.Flex
              fullWidth
              verticalGap={32}
              className={cls({
                [classes.item]: true,
                [classes.showFromRight]: !!currentFilter,
                [classes.hideToRight]: !currentFilter,
              })}
            >
              {!!currentFilter && (
                <Fragment>
                  <FiltersItem filter={currentFilter} />
                  <FiltersButtons />
                </Fragment>
              )}
            </Container.Flex>
          </Container.Flex>
        </Container.Flex>
      </Container.Flex>
    </Container.Flex>
  )
}
