
import { ContactFromData } from "../../Order/OrderFinal/Ordering/OrderingContext";
import { documents } from "../../Order/OrderFinal/Ordering/OrderingContext";

export const getClientDocumentData = (data: ContactFromData) => {
    const key = data.document as keyof typeof documents;

    const documentName = documents[key].name;
    const documentId = data.documentId;

    return `${documentName}. Идентификационный номер - ${documentId}`
}