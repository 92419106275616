import { ChangeEvent, KeyboardEvent, useState } from "react";
import styled from "styled-components";
import { getCommonProps, Props } from "../../types";
import Typography from "../Typography";
import { useTheme } from "styled-components/macro";
import Icon from "../Icon";
import { Icons } from "../Icon/types";

type TextAreaProps = Props<{
    label?: string;
    onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    onFocus?: () => void;
    onKeyPress?: (e: KeyboardEvent<HTMLTextAreaElement>) => void;
    onKeyDown?: (e: KeyboardEvent<HTMLTextAreaElement>) => void;
    value?: string | number;
    error?: string;
    placeholder?: string;
    defaultValue?: string;
    secure?: boolean;
    icon?: Icons;
    iconPosition?: "left" | "right";
    iconClick?: () => void;
}>;

const TextAreaWrapper = styled.textarea<TextAreaProps>`
    position: relative;
    border: ${(props) =>
        props.error ? `1px solid ${props.theme.colors.info.error}` : "none"};
    border-radius: 12px;
    padding: 10px 20px;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: ${(props) =>
        props.error
            ? props.theme.colors.info.error
            : props.theme.colors.text.default};
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.04);
    backdrop-filter: blur(8px);
    outline: none;

    &::placeholder {
        color: ${(props) =>
            props.error
                ? props.theme.colors.info.error
                : props.theme.colors.text.secondary};
    }
`;

const Wrapper = styled.div<TextAreaProps>`
    width: ${(props) => (props.fullWidth ? "100%" : "auto")};
    ${(props) => getCommonProps(props)}
    position: relative;
`;

const Input = (props: TextAreaProps) => {
    const {
        label,
        value,
        onChange,
        onFocus,
        onKeyPress,
        onKeyDown,
        secure,
        icon,
        iconPosition,
        iconClick,
    } = props;

    const [_secure, setSecure] = useState(secure);

    const theme = useTheme();


    const onIconClick = () => {
        if (iconClick) {
            iconClick()
        }
    }

    return (
        <Wrapper fullWidth>
            <TextAreaWrapper
                {...props}
                onChange={onChange}
                onFocus={onFocus}
                onKeyPress={onKeyPress}
                onKeyDown={onKeyDown}
                value={value}
                placeholder={props.placeholder}
            ></TextAreaWrapper>

            {typeof _secure === "boolean" && (
                <Icon
                    onClick={() => setSecure(!_secure)}
                    name={_secure ? "eye-close" : "eye"}
                    styles={{
                        position: "absolute",
                        top: "10px",
                        right: "12px",
                        fill: theme.colors.icon.tertiary,
                    }}
                    hoverStyles={{ cursor: "pointer" }}
                />
            )}
            {label && (
                <Typography.Tertiary
                    styles={{
                        position: "absolute",
                        top: "-10px",
                        padding: "0 10px",
                        left: "20px",
                        color: props.error
                            ? theme.colors.text.tertiary
                            : theme.colors.text.default,
                        backgroundColor: "transparent",
                    }}
                >
                    {label}
                </Typography.Tertiary>
            )}

            {!!props.error && (
                <Typography.Error
                    styles={{
                        position: "absolute",
                        top: "42px",
                        left: "10px",
                    }}
                >
                    {props.error}
                </Typography.Error>
            )}

            {icon && (
                <Icon
                    name={icon}
                    onClick={onIconClick}
                    styles={{
                        position: "absolute",
                        left: iconPosition === "left" ? "10px" : "auto",
                        right: iconPosition === "right" ? "10px" : "auto",
                        top: "10px",
                        color: theme.colors.icon.secondary,
                        zIndex: 10
                    }}
                    hoverStyles={{ cursor: "pointer" }}
                />
            )}
        </Wrapper>
    );
};

export default Input;
