import { ApiError } from "../types"

export enum ItemStatuses {
    Created = "N",
    PhotoLoaded = "F",
    Confirm = "D"
}

export type CreateOrChangeOrderRequest = {
    combinationId?: string
    number?: string
    offerId?: string
    deviceId?: string
    questions: {[questionId: string] : string}
    productId?: string
    combination?: string
}

export type CreateOrderResponseData = {
    number: string
    itemNumber: string
    success: boolean
}

export type CreateOrderResponse = {
    status: "success" | "error"
    data?: CreateOrderResponseData,
    errors: string[]
};

export type GetOrderRequest = {
    orderNumber: string
    itemNumber: string
    create?: true
    qrCode?: string
    code?: string
    checkList?: string
};

export type OrderRequest<T> = {
    orderNumber?: string
    itemNumber?: string,
} & T

type ApiResponse<T> = {
    status: "success" | "error"
    data: T
    errors: string[]
}

export type OrderItem = {
    price: number
    name: string
    image: string
    itemNumber: string
    status: string
    combinationCode?: string
    combinationId?: string
    answers: Array<{
        name: string,
        value: string
    }>
}

export type OrderButton = {
    code: string
    description: string
    value: string
}

export type Partner = {
    code: string
    name: string
    cardColor: string
    cardDescription: string
    cardTitle: string
    companyLogo: string
}

export type Partners = {
    [k: number] : Partner
}

export type Order = {
    number: string
    amount: number
    amountRansom: number
    button: OrderButton[]
    currency: string
    currencyLang: string
    status: OrderStatuses
    statusColor: string
    items: OrderItem[]
    registration?: string
    partners?: Partners
    dateCreate: string
}

export type DeleteItemResponseData = {
    item: boolean
    order: boolean
} | null

export type DeleteItemResponse = {
    status: "success" | "error" | null,
    data: DeleteItemResponseData
    errors: string[]
}

export type GetOrderResponse = ApiResponse<Order>

export type SetRegistrationResponseData = {
    type: string
    success: boolean
}

export enum OrderStatuses {
    Checking = "N",
    Confirm = "confirm",
    Done = 'done',
    Ready = 'ready',
    Accept = 'accept',
    Paid = 'paid',
    Canceled = 'canceled'
}

export type OrderState = {
    order: {
        number: string
        itemNumber: string
        data: Order | null,
        status: "success" | "error" | null
        loading: boolean,
        errors: ApiError[],
    },
    deleteItem: {
        result: "success" | "error" | null,
        loading: boolean,
        errors: string[]
        data: DeleteItemResponseData,
        itemNumber: string | null
    },
    setRegistrations: {
        loading: boolean,
        data: SetRegistrationResponseData | null,
        errors: string[],
        result: "success" | "error" | null
    },
    showResultFor: string
    qrCode?: string
}