import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
    GivenAnswer,
    GivenAnswers,
    Question,
} from '../../../store/offerSlice/types'
import { Button, Info, Container, Typography, FilesContainer } from '../../ui'
import FreeInput from './FreeInput'
import FromList from './FromList'
import QrCode from './OfferQR'
import UploadImage from './UploadImage'
import FromRadio from './FromRadio'
import { styled } from '../../../helpers/styled'
import { PopupTrigger } from '../../ui/Popup'
import usePopup from '../../../hooks/usePopup'
import { getPopupData } from '../../../helpers/getPopupData'
import { useUploadFiles } from '../../../contexts/uploadFiles'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useRecaptchaContext } from '../../../contexts/recaptcha'

export interface OfferQuestionProps {
    questionData: Question
    combinationId?: string
    givenAnswers: GivenAnswers
    giveAnswer: (answer: GivenAnswer) => void
    back: () => void
    pauseQuestions: boolean
}

export type RecapchaProps = {
    recaptchaPending: boolean
}

const QuestionDescription = styled.div`
    > a {
        user-select: none;
        color: ${props => props.theme.colors.link.default};
    }
    > p {
        margin-top: 0;
    }
`

const OfferQuestion = (props: OfferQuestionProps) => {
    const { questionData, givenAnswers, back } = props
    const { combinationId, combinationCode } = givenAnswers
    const {
        answerType,
        questionName,
        questionDescription,
        questionHelp,
        questionHeader,
        needRecaptcha,
    } = questionData

    const { showPopup } = usePopup()
    const { executeRecaptcha } = useGoogleReCaptcha()
    const { recaptchaKey } = useRecaptchaContext()
    const [getRecapchaPending, setGetRecaptchaPending] = useState(false)

    const giveAnswer = async (answer: GivenAnswer) => {
        if (!recaptchaKey.trim() || !needRecaptcha) {
            props.giveAnswer(answer)
            return
        }
        if (needRecaptcha) {
            setGetRecaptchaPending(true)

            if (!executeRecaptcha) {
                console.log('Recaptcha has not been loaded')

                return
            }

            const token = await executeRecaptcha('getQuestions')
            props.giveAnswer({ ...answer, recaptchaToken: token })
            setGetRecaptchaPending(false)
        }
    }

    const contentRef = useRef<HTMLDivElement>(null)

    const [height, setHeight] = useState('auto')

    const resize = useCallback(() => {
        if (contentRef.current) {
            setHeight(`${contentRef.current.offsetHeight}px`)
        }
    }, [setHeight])

    useEffect(resize)

    const { showImageContainer } = useUploadFiles()

    const popupData = useMemo(() => {
        return getPopupData(questionDescription ?? '')
    }, [questionDescription])

    const { popupContent, popupTrigger, processedHTML } = popupData

    return (
        <Container.Flex
            fullWidth
            alignItems="start"
            verticalGap={16}
            styles={{ height }}
        >
            <Container.Flex
                fullWidth
                verticalGap={16}
                alignItems="start"
                ref={contentRef}
                onTransitionEnd={resize}
                className="show"
            >
                {showImageContainer && <FilesContainer />}

                {!!questionHeader && (
                    <Typography.Title
                        textAlign={'start'}
                        styles={{
                            paddingLeft: '4px',
                            paddingRight: '4px',
                            margin: '0 0 8px',
                        }}
                    >
                        {questionHeader}
                    </Typography.Title>
                )}

                {!!processedHTML && (
                    <Container.Flex
                        fullWidth
                        alignItems="start"
                        padding="0 4px"
                        margin="0 0 6px"
                    >
                        <Typography.Main textAlign="start" margin={0}>
                            <QuestionDescription
                                dangerouslySetInnerHTML={{
                                    __html: processedHTML,
                                }}
                            />
                        </Typography.Main>

                        {!!popupTrigger && (
                            <PopupTrigger
                                text={popupTrigger}
                                showPopup={() => showPopup(popupContent)}
                            />
                        )}
                    </Container.Flex>
                )}

                {questionName && (
                    <Typography.TitleSecondary
                        textAlign="start"
                        margin={0}
                    >
                        {questionName}
                    </Typography.TitleSecondary>
                )}

                {answerType === 'from_list' && (
                    <FromList
                        {...props}
                        combinationId={combinationId}
                        recaptchaPending={getRecapchaPending}
                    />
                )}
                {answerType === 'free_input' && (
                    <FreeInput
                        {...props}
                        giveAnswer={giveAnswer}
                        combinationId={combinationId}
                        outerResize={resize}
                        recaptchaPending={getRecapchaPending}
                    />
                )}

                {answerType === 'radio_button' && (
                    <FromRadio
                        {...props}
                        giveAnswer={giveAnswer}
                        combinationId={combinationId}
                        recaptchaPending={getRecapchaPending}
                    />
                )}

                {answerType === 'show_qr_link' && (
                    <QrCode
                        combinationCode={combinationCode}
                        combinationId={combinationId}
                    />
                )}

                {!!questionHelp && <Info>{questionHelp}</Info>}

                {answerType === 'show_qr_link' && (
                    <Button fullWidth variant="outline" onClick={back}>
                        Назад
                    </Button>
                )}

                {(answerType === 'upload_image' || props.pauseQuestions) && (
                    <UploadImage
                        {...props}
                        giveAnswer={giveAnswer}
                        recaptchaPending={getRecapchaPending}
                    />
                )}
            </Container.Flex>
        </Container.Flex>
    )
}

export default OfferQuestion
