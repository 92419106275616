import {
    KeyboardEvent,
    MouseEvent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'
import { Button, Container } from '../../ui'
import Input from '../../ui/Input'
import { OfferQuestionProps, RecapchaProps } from './OfferQuestion'
import { collectAnswerData } from '../helpers/collectAnswerData'

const IMEIvalidator = `^\\d{15}$`
const IMEIvalidateError = 'Неправильный формат IMEI'

const FreeInput = (
    props: OfferQuestionProps & { outerResize?: () => void } & RecapchaProps,
) => {
    const { giveAnswer } = props

    const [value, setValue] = useState('')
    const [height, setHeight] = useState(200)
    const [error, setError] = useState('')

    const { questionData } = props
    const {
        validator,
        validateFailure,
        questionInputButtonName,
        questionInputPlaceholder,
    } = questionData
    const validate = () => {
        return new RegExp(validator ?? IMEIvalidator).test(value)
    }

    const onClick = useCallback(
        (e?: MouseEvent<HTMLButtonElement>) => {
            if (validate()) {
                giveAnswer(
                    collectAnswerData({questionData, answerName: value}),
                )
            } else {
                setError(validateFailure ?? IMEIvalidateError)
            }
        },
        [giveAnswer, setError, collectAnswerData, value, validate],
    )

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            onClick()
        }
    }

    const onChange = (e: any) => {
        const clearedValue = e.target.value.replace(/[^\d]/g, '')
        if (clearedValue.length <= 15) {
            setValue(clearedValue)
            setError('')
        }
    }

    const contentRef = useRef<HTMLDivElement>(null)

    const resize = useCallback(() => {
        if (contentRef.current) {
            setHeight(contentRef.current.offsetHeight)
        }
        props.outerResize && props.outerResize()
    }, [])

    useEffect(resize)

    const getDisabled = () => {
        if (value.length === 0) return true
        if (error) return true
        return undefined
    }

    return (
        <Container.Flex
            fullWidth
            alignItems="center"
            styles={{
                height: `${height}px`,
                transition: 'height 200ms ease-in',
            }}
        >
            <Container.Flex
                ref={contentRef}
                fullWidth
            >
                <Input
                    value={value}
                    onInput={onChange}
                    fullWidth
                    placeholder={questionInputPlaceholder || ''}
                    onKeyDown={onKeyDown}
                    error={error}
                    label={(value.length > 0) ? `${value.length}/15` : ""}
                    labelPosition="right"
                />

               {/* <Container.Flex
                    margin={'8px 0 -8px'}
                    fullWidth
                    styles={{
                        height: error ? 'auto' : '0',
                        overflow: 'hidden',
                    }}
                >
                    <Typography.Error className="show">
                        {error}
                    </Typography.Error>
                </Container.Flex>*/}

                <Button
                    fullWidth
                    onClick={onClick}
                    disabled={getDisabled()}
                    margin="16px 0 0"
                    withLoader
                    pending={props.recaptchaPending}
                >
                    {questionInputButtonName ?? 'ОК'}
                </Button>
            </Container.Flex>
        </Container.Flex>
    )
}

export default FreeInput
