import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../store'
import { UserType } from '../store/userSlice/types'
import { bp } from '../theme/media'

export const useSession = () => {
  const { session, userData } = useSelector((state: RootState) => state.user)

  const isAdmin = useMemo(() => {
    return (
      userData?.userType === UserType.partner ||
      userData?.userType === UserType.employee
    )
  }, [userData])

  const userRole = useMemo(() => {
    return userData?.userType ?? UserType.quest
  }, [userData])

	const bpTable = useMemo(() => {
		return isAdmin ? 1200 : bp.table;
	}, [isAdmin])

  return {
    session,
    userRole,
    isAdmin,
		bpTable
  }
}
