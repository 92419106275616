import { Button, Container } from '../ui'
import { useFiltersData } from './FiltersContext'

export default function FiltersButtons({ root } : { root?: true}) {
  const {
    filtersOn,
    setCurrentFilter,
    setShowFilters,
    resetFilters,
  } = useFiltersData();


  const goToMain = () => {
    setCurrentFilter(null);
  };

  return (
    <Container.Grid fullWidth rows="1fr" cols="1fr 1fr" gap={16}>
      <Button 
        variant='danger'
        disabled={!filtersOn || undefined} onClick={resetFilters}>
        Сбросить
      </Button>
      <Button
        onClick={() => {
          root ? setShowFilters(false) : goToMain()
        }}
        variant='primary'
      >
        {root ? 'Закрыть' : 'Вернуться'}
      </Button>
    </Container.Grid>
  )
}
