import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom"
import { URLS } from "../../apinew";
import { useApi } from "../../hooks/useApi";
import { getContractData } from "../../store/contractSlice";
import { Container, Typography } from "../ui"
import ContractPrintPage from "./ContractPrintPage";

export default function Contract () {

    const { orderNumber } = useParams();
    const { post } = useApi()

    const data = useSelector(getContractData(orderNumber));

    useEffect(() => {
        if (data && orderNumber) {
            post(URLS.sendContractData, { number: orderNumber, data: data })
        }
    }, [data, orderNumber])

    if (!data) return null;

    if (!data.contractData || !data.formData) return <Typography.Error>Ошибка</Typography.Error>;

    return (
        <Container.Flex fullWidth>
            <ContractPrintPage contractData={data.contractData} formData={data.formData} />
        </Container.Flex>
    )
}