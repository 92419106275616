import axios, { AxiosInstance, AxiosResponse } from 'axios'
import qs from 'qs'
import { ResponseData } from '../store/types'
import { Session } from '../store/userSlice/types'
import { collectFormData, collectParams, getErrorResponse } from './helpers'

axios.defaults.paramsSerializer = params => qs.stringify(params)
const baseURL = process.env.REACT_APP_API_URL

export enum URLS {
    //GENERAL
    getRecapchaKey = '/general/getrecaptchacode/',
    //USER/AUTH
    login = '/auth/login/',
    logout = '/auth/logout/',
    checkAuth = '/auth/checkauth/',
    changePassword = '/auth/changepassword/',
    restorePassword = '/auth/restorepassword/',
    addUserData = '/user/add/',
    updateUserData = '/user/update/',
    getUserDetail = '/user/getDetail/',
    getUsersList = '/user/getList/',
    SMSLogin = '/auth/clientlogin/',
    //PARTNERS
    getPartners = '/partnersdata/getpartners/',
    getPartnersFilter = '/partnersdata/getpartnersfilter/',
    getPartnersDetail = '/partnersdata/getpartnerdetail/',
    //PAGE-FORM
    getPageForm = '/pageform/get/',
    sendPageForm = '/pageform/add/',
    //ORDER
    createOrder = '/orderrequest/create/',
    orderAddProperty = '/orderrequest/addproperty/',
    orderSetRegistration = '/orderrequest/setRegistration/',
    getOrder = '/orderrequest/getorder/',
    orderAddItem = '/orderrequest/additem/',
    orderDeleteItem = '/orderrequest/deleteitem/',
    orderGetItemStatus = '/orderrequest/getitemstatus/',
    orderAddFile = '/orderrequest/addfile/',
    getOrderList = '/orderrequest/getorderlist/',
    getOrderListFilters = '/orderrequest/getordersfilter/',
    deleteOrder = '/orderrequest/delete/',
    SendOrderLink = '/orderrequest/sendorderlink/',
    //DEVICEDATA
    getQuestions = '/devicedata/getquestions/',
    getItemInfo = '/orderrequest/getiteminfo/',
    addCustomerDevice = '/orderrequest/addcustomerdevice/',
    //THEME
    getTheme = "/theme/get/",
    //ORDERFINAL
    getSalePoints = '/orderrequest/getsalepoints/',
    getContractData = '/orderrequest/getcontractfieldsdata/',
    sendContractData = '/orderrequest/adduserdata/'
}

class Api {
    login
    logout
    checkAuth
    changePassword
    restorePassword
    addUserData
    updateUserData
    getUserDetail
    getUsersList
    getPartners
    getPageForm
    sendPageForm
    createOrder
    orderAddProperty
    orderSetRegistration
    getOrder
    orderAddItem
    orderDeleteItem
    orderGetItemStatus
    orderAddFile
    getQuestions
    getTheme
    getItemInfo
    getOrderListFilters
    smsLogin
    sendOrderLink
    api: AxiosInstance
    constructor() {
        this.api = axios.create({
            baseURL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
        this.login = this._post(URLS.login)
        this.logout = this._post(URLS.logout)
        this.checkAuth = this._post(URLS.checkAuth)
        this.changePassword = this._post(URLS.changePassword)
        this.restorePassword = this._post(URLS.restorePassword)
        this.addUserData = this._post(URLS.addUserData)
        this.updateUserData = this._post(URLS.updateUserData)
        this.getUserDetail = this._get(URLS.getUserDetail)
        this.getUsersList = this._get(URLS.getUsersList)
        this.getPartners = this._post(URLS.getPartners)
        this.getPageForm = this._post(URLS.getPageForm)
        this.sendPageForm = this._post(URLS.sendPageForm)
        this.createOrder = this._post(URLS.createOrder)
        this.orderAddProperty = this._post(URLS.orderAddProperty)
        this.orderSetRegistration = this._post(URLS.orderSetRegistration)
        this.getOrder = this._post(URLS.getOrder)
        this.orderAddItem = this._post(URLS.orderAddItem)
        this.orderDeleteItem = this._post(URLS.orderDeleteItem)
        this.orderGetItemStatus = this._post(URLS.orderGetItemStatus)
        this.orderAddFile = this._post(URLS.orderAddFile)
        this.getQuestions = this._post(URLS.getQuestions)
        this.getTheme = this._get(URLS.getTheme)
        this.getItemInfo = this._post(URLS.getItemInfo)
        this.getOrderListFilters = this._post(URLS.getOrderListFilters);
        this.smsLogin = this._post(URLS.SMSLogin)
        this.sendOrderLink = this._post(URLS.SendOrderLink)
    }

    _get(url: URLS) {
        return (session: Session, params = {}) => {
            return this.api
                .get(url, {
                    params: collectParams(session, params)
                })
                .then(
                    (response: AxiosResponse<ResponseData<any>>) =>
                        response.data,
                )
                .catch((error: any) => getErrorResponse(error.message))
        }
    }

    _post(url: URLS) {
        return (data: any, session: Session) => {
            return this.api
                .post(url, collectFormData(data, session))
                .then(
                    (response: AxiosResponse<ResponseData<any>>) =>
                        response.data,
                )
                .catch((error: any) => getErrorResponse(error.message))
        }
    }
}

export default Api
