import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { getOrderData } from "../store/orderSlice";
import { saveOrderNumber } from "../store/savedData";

export const useSavedData = () => {

    const orderData = useSelector(getOrderData);

    const dispatch = useDispatch();

    useEffect(() => {
        if (orderData.order.data) {
            dispatch(saveOrderNumber(orderData.order.data.number))
        }

    }, [orderData.order.data?.number, dispatch])
}