import { styled } from '../../helpers/styled'
import { Container, Typography } from '../ui'
import { FiltersPart, useFiltersData } from './FiltersContext'
import { RowCss } from './FiltersMain'

const Checkbox = styled.div<{ checked: boolean }>`
	position: relative;
	width: 20px;
	height: 20px;
	cursor: pointer;
	&::after {
		content: "";
		width: 20px;
		height: 20px;
		position: absolute;
		top: 0;
		left: 0;
		background-image: ${props => `url(/images/checkbox/${props.checked ? "checked" : 'unchecked'}.png)`};
		background-size: cover;
		background-repeat: no-repeat;
	}
`

export default function FiltersList({ filter } : { filter: FiltersPart}) {
  const { isChecked, onFilterChange } = useFiltersData()

  if (filter?.valueType === 'list') {
    return (
      <Container.Flex fullWidth verticalGap={24} margin={0}>
        {filter.items.map(item => {
          const value = isChecked(item.value)
          return (
            <Container.Flex
              fullWidth
              direction="row"
              justify="between"
              css={RowCss}
              key={item.value}
              onClick={() => onFilterChange(item.value)(!value)}
            >
              <Typography.Main margin={0}>{item.name}</Typography.Main>
              <Checkbox checked={value} />
            </Container.Flex>
          )
        })}
      </Container.Flex>
    )
  }

  return null
}
