import DoneImg from '../../../assets/done.png';
// import AttentionImg from '../../../assets/attention.png'
import OkImg from '../../../assets/ok.png'
import Image from './Image';
import { ItemStatuses } from '../../../store/orderSlice/types';

type Props = {
    status: string
    result?: true
    noMargin?: true
}

const getUrl = (status: string, result?: true) => {
    switch (status) {
        case ItemStatuses.Confirm:
            return result ? OkImg : DoneImg
        default:
            return ""
    }
}

export default function StatusImage (props: Props) {

    const { status, result } = props;
    const url = getUrl(status, result);

    return (
        url ? <Image src={url} alt={status} width={40} height={40} noBasePath styles={{
            width: "16px",
            height: "16px",
            marginRight: props.noMargin ? 0 : "4px"
        }}/> : null
    )
}