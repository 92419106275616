import { ChangeEvent, KeyboardEvent, FormEvent, useState, forwardRef } from 'react'
import styled from 'styled-components'
import { getCommonProps, Props } from '../../types'
import Typography from '../Typography'
import { InputTypes } from './types'
import { useTheme } from 'styled-components/macro'
import Icon from '../Icon'
import { Icons } from '../Icon/types'
import { zIndexes } from '../../../zIndexes'

type InputProps = Props<{
  label?: string
  labelPosition?: 'right' | 'top'
  type?: InputTypes
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onInput?: (e: FormEvent<HTMLInputElement>) => void
  onFocus?: () => void
  onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void
  value?: string | number
  error?: string
  placeholder?: string
  defaultValue?: string
  secure?: boolean
  icon?: Icons
  iconPosition?: 'left' | 'right'
  iconClick?: () => void
  phone?: true
  maxLength?: number
}>

const InputWrapper = styled.input<InputProps>`
  position: relative;
  border: none;
  border-radius: 12px;
  padding: 10px 20px;
  width: ${props => props.styles?.width ?? '100%'};
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${props => props.theme.colors.text.default};
  background: ${props =>
    props.error
      ? props.theme.colors.background.error70
      : props.theme.colors.background.contrast70};
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.04);
  backdrop-filter: blur(8px);
  outline: none;

  &::placeholder {
    color: ${props =>
      props.error
        ? props.theme.colors.info.error
        : props.theme.colors.text.secondary};
  }
`

const Wrapper = styled.div<InputProps>`
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  ${props => getCommonProps(props)}
  position: relative;
`

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    label,
    labelPosition,
    value,
    onChange,
    onInput,
    onFocus,
    onKeyPress,
    onKeyDown,
    secure,
    icon,
    iconPosition,
    iconClick,
  } = props

  const [_secure, setSecure] = useState(secure)

  const theme = useTheme()

  const getType = () => {
    if (_secure === true) return 'password'
    if (_secure === false) return 'text'
    return props.type ?? 'text'
  }

  const onIconClick = () => {
    if (iconClick) {
      iconClick()
    }
  }

  const onMaxChange = (e: ChangeEvent<HTMLInputElement>, maxLength: number) => {
    if (e.target.value.length > maxLength) {
      e.target.value = e.target.value.slice(0, maxLength)
      return
    }

    onChange && onChange(e)
  }

  return (
    <Wrapper fullWidth>
      <InputWrapper
        ref={ref}
        {...props}
        type={getType()}
        onChange={e =>
          props.maxLength
            ? onMaxChange(e, props.maxLength)
            : onChange && onChange(e)
        }
        onInput={onInput}
        onFocus={onFocus}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
        value={value}
        placeholder={props.placeholder}
        maxLength={props.maxLength}
      ></InputWrapper>

      {typeof _secure === 'boolean' && (
        <Icon
          onClick={() => setSecure(!_secure)}
          name={_secure ? 'eye-close' : 'eye'}
          styles={{
            position: 'absolute',
            top: '10px',
            right: '12px',
            fill: theme.colors.icon.tertiary,
          }}
          hoverStyles={{ cursor: 'pointer' }}
        />
      )}
      {label && (
        <Typography.Tertiary
          styles={{
            position: labelPosition === 'right' ? 'absolute' : 'static',
            bottom: labelPosition === 'right' ? '12px' : '',
            right: labelPosition === 'right' ? '12px' : '',
          }}
        >
          {label}
        </Typography.Tertiary>
      )}

      {!!props.error && (
        <Typography.Micro
          styles={{
            position: 'absolute',
            bottom: 0,
            right: '4px',
            transform: 'translateY(100%)',
          }}
          color={theme.colors.info.error}
        >
          {props.error}
        </Typography.Micro>
      )}

      {icon && (
        <Icon
          name={icon}
          onClick={onIconClick}
          styles={{
            position: 'absolute',
            left: iconPosition === 'left' ? '10px' : 'auto',
            right: iconPosition === 'right' ? '10px' : 'auto',
            top: '10px',
            color: theme.colors.icon.secondary,
            zIndex: zIndexes.header,
          }}
          hoverStyles={{ cursor: 'pointer' }}
        />
      )}
    </Wrapper>
  )
})

export default Input
