import { Fragment, useState } from 'react'
import Button from '../ui/Button'
import Input from '../ui/Input'
import Container from '../ui/Container'
import Card from '../ui/Card'
import Typography from '../ui/Typography'
import { Info } from '../ui'
import { useApi } from '../../hooks/useApi'
import {
    RestorePasswordData,
    RestorePasswordRequestData,
} from '../../store/userSlice/types'
import { URLS } from '../../apinew'

const RestorePassword = () => {
    const [loginOrEmail, setLoginOrEmail] = useState('')
    const { pending, errors, apiPost, data } = useApi()

    const result = data as RestorePasswordData | null

    const onSubmit = () => {
        const data = {
            loginEmail: loginOrEmail,
        } as RestorePasswordRequestData
        apiPost(URLS.restorePassword, data, true)
    }

    return (
        <Card
            noShadow
            padding={28}
            styles={{
                width: 'auto',
                margin: 'auto',
            }}
        >
            <Container.Flex
                verticalGap={16}
                fullWidth
                fullHeight
                justify="center"
                styles={{ opacity: pending ? 0 : 1 }}
            >
                <Input
                    value={loginOrEmail}
                    onChange={e => setLoginOrEmail(e.target.value)}
                    fullWidth
                    placeholder="Логин или email"
                />

                <Button pending={pending} fullWidth onClick={onSubmit}>
                    ВОССТАНОВИТЬ
                </Button>

                <Info>
                    <Typography.Main start>
                        На email будет отправлена инструкция для восстановления
                        пароля
                    </Typography.Main>
                </Info>

                {!!errors.length && (
                    <Typography.Error>{errors.join('. ')}</Typography.Error>
                )}

                {!!result && (
                    <Fragment>
                        <div
                            dangerouslySetInnerHTML={{ __html: result.MESSAGE }}
                        ></div>

                        <Button link="/" fullWidth>
                            {result.TYPE}
                        </Button>
                    </Fragment>
                )}
            </Container.Flex>
        </Card>
    )
}

export default RestorePassword
