import {
    createContext,
    useContext,
    ReactNode,
    useState,
    useEffect,
    useCallback,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSendPhotoStatus } from '../store/offerSlice'
import { CheckListResponseImages } from '../store/offerSlice/types'
import { getUploadImageData, setShowImageContainer } from '../store/viewSlice'

interface InputContextState {
    files: UploadImagesState
    process: string
    setProcess: (v: string) => void
    addFile: (questionId: string, file: FileStateItem) => void
    setFiles: (files: UploadImagesState) => void
    removeFile: (blob: string) => void
    remoteFiles: CheckListResponseImages
    setRemoteFiles: (v: CheckListResponseImages) => void
    showImageContainer: boolean
}

export const InputFilesContext = createContext({
    files: {},
    process: "",
    setProcess: (_: string) => {},
    addFile: () => {},
    setFiles: () => {},
    removeFile: () => {},
    remoteFiles: {},
    setRemoteFiles: () => {},
    showImageContainer: false,
} as InputContextState)

export function useUploadFiles() {
    return useContext(InputFilesContext)
}

export type FileStateItem = {
    file: File,
    blob: string
}

export type UploadImagesState = {
    [questionId: string] : FileStateItem
}

export default function UploadFiles({ children }: { children: ReactNode }) {
    const [files, setFiles] = useState<UploadImagesState>({});
    const [remoteFiles, setRemoteFiles] = useState<CheckListResponseImages>({})
    const [process, setProcess] = useState("");

    const dispatch = useDispatch()

    const sendPhotoStatus = useSelector(getSendPhotoStatus);
    const { showImageContainer } = useSelector(getUploadImageData)

    useEffect(() => {
        if (sendPhotoStatus === 'success') {
            setFiles({})
        }
    }, [sendPhotoStatus]);

    useEffect(() => {
        if (!Object.entries(files).length) {
            dispatch(setShowImageContainer(false))
        }
    }, [dispatch, files]);

    const addFile = useCallback((questionId: string, file: FileStateItem) => {
        setFiles(prev => ({
            ...prev,
            [questionId]: file
        }))
    }, [files, setFiles]);

    const removeFile = useCallback((blob: string) => {
        const newFiles = {...files};
        const item = Object.entries(newFiles).find(([key, value]) => {
            return value.blob === blob
        });
        if (item) {
            delete newFiles[item[0]];
            setFiles(newFiles)
        }
    }, [files, setFiles])

    return (
        <InputFilesContext.Provider
            value={{
                files,
                addFile,
                removeFile,
                setFiles,
                process,
                setProcess,
                showImageContainer,
                remoteFiles,
                setRemoteFiles
            }}
        >
            {children}
        </InputFilesContext.Provider>
    )
}
