import { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { LowerMenu } from '../components'
import Profile from '../components/Profile'
import { Container } from '../components/ui'
import { styled } from '../helpers/styled'
import { RootState } from '../store'

const LeftPart = styled.div`
  display: flex;
  margin-right: 18px;
  flex-shrink: 0;

  @media screen and (max-width: 1200px) {
    display: none;
  }
`

const PageSeparator = styled.div`
  width: 2px;
  background: linear-gradient(
    to bottom,
    transparent 0%,
    #d8d8d8 15%,
    transparent 85%
  );
  margin-left: 18px;
`

export default function PageLayout({
  children,
  withProfile,
  width,
}: {
  children: ReactNode
  width?: string
  withProfile?: true
}) {
  const userData = useSelector((state: RootState) => state.user.userData)

  return (
    <Container.Flex fullWidth verticalGap={24}>
    <Container.Flex
      className="page-layout"
      fullWidth
      fullHeight
      direction="row"
      alignItems="start"
      justify="center"
      styles={{ flexShrink: 0 }}
    >
      {withProfile && !!userData && (
        <LeftPart>
          <Profile />
          <PageSeparator />
        </LeftPart>
      )}
      <Container.Flex direction='row' alignItems='start' wrapped gap={34}
        styles={{ width: '100%', margin: '0 auto' }}
        breakpoints={{
          850: {
            width: width ?? '660px',
          },
          750: {
            width: width ?? '312px',
          },
        }}
      >
        {children}
      </Container.Flex>
    </Container.Flex>
    <LowerMenu />
    </Container.Flex>
  )
}
