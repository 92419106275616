import { ChangeEvent, Fragment, useRef, useState, useEffect, memo } from 'react'
import Timer from './Timer'
import { classNames } from '../../styles/classNames'
import { Card, Container, PhoneInput, Typography, Button, Input } from '../ui'
import { useDispatch, useSelector } from 'react-redux'
import { getSMSLoginData, resetSendLink, resetSMSApi } from '../../store/userSlice'
import { SMSLogin } from '../../store/userSlice/routines'

type Steps = 'phone-input' | 'input-code'

interface LoginSMSProps {
  hide: () => void
}

const LoginSMS = ({ hide }: LoginSMSProps) => {
  const dispatch = useDispatch()
  const [step, setStep] = useState<Steps>('phone-input')
  const [phone, setPhone] = useState('')

  const [disableCodeSubmit, setDisableCodeSubmit] = useState<true | undefined>(
    true,
  )
  const [disablePhoneSubmit, setDisablePhoneSumbit] = useState<
    true | undefined
  >(true)

  const _hide = () => {
    dispatch(resetSMSApi());
    hide();
  }

  const { codeSent, coodeSentSuccess, pending } = useSelector(getSMSLoginData)

  const contentRef = useRef<HTMLDivElement>(null)
  const phoneInputRef = useRef<HTMLInputElement>(null)
  const codeInputRef = useRef<HTMLInputElement>(null)

  const onChangeStep = (step: Steps) => () => {
    if (contentRef.current) {
      contentRef.current.classList.add(classNames.changeCardContent)

      setTimeout(() => {
        setStep(step)
      }, 300)
    }
  }

  useEffect(() => {
    if (codeSent) {
      onChangeStep('input-code')()
    }
  }, [codeSent])

  useEffect(() => {
    if (coodeSentSuccess) {
      _hide();
    }
  }, [coodeSentSuccess, dispatch, _hide])

  const afterPhoneChange = (value: string) => {
    if (disablePhoneSubmit) {
      if (!value.match(/_/)) {
        setDisablePhoneSumbit(undefined)
      }
    } else {
      if (value.match(/_/)) {
        setDisablePhoneSumbit(true)
      }
    }
  }

  const resendCode = () => {
    onChangeStep("phone-input")()
  }

  const onCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (disableCodeSubmit) {
      if (e.target.value.length === 4) {
        setDisableCodeSubmit(undefined)
      }
    } else {
      if (e.target.value.length !== 4) {
        setDisableCodeSubmit(true)
      }
    }
  }

  const sendPhone = () => {
    const _phone = phone || phoneInputRef.current?.value || '';

    dispatch(
      SMSLogin.request({
        phone: _phone
      }),
    )
    if (_phone) {
      setPhone(_phone)
    }

  }

  const sendCode = () => {
    dispatch(
      SMSLogin.request({
        phone,
        code: codeInputRef.current?.value ?? '',
      }),
    )
  }

  useEffect(() => {
    return () => {
      dispatch(resetSMSApi());
      dispatch(resetSendLink());
    }
  }, [dispatch])

  return (
    <Card padding={28} styles={{ overflow: 'hidden' }}>
      <Container.Flex
        fullWidth
        ref={contentRef}
        verticalGap={16}
        alignItems="start"
        onAnimationEnd={() => {
          contentRef.current?.classList.remove(classNames.changeCardContent)
        }}
      >
        {step === 'phone-input' && (
          <Fragment>
            <PhoneInput afterChange={afterPhoneChange} ref={phoneInputRef}/>
            <Typography.Small>
              На указанный номер придёт СМС-код
            </Typography.Small>
            <Button onClick={sendPhone} disabled={disablePhoneSubmit} fullWidth withLoader pending={pending}>
              ДАЛЕЕ
            </Button>
          </Fragment>
        )}

        {step === 'input-code' && (
          <Fragment>
            <Input
              ref={codeInputRef}
              placeholder="Код из СМС"
              type="number"
              maxLength={4}
              onChange={onCodeChange}
            />
            <Timer resendCode={resendCode} codePending={pending}/>
            <Button
              disabled={disableCodeSubmit}
              fullWidth
              withLoader
              pending={pending}
              onClick={sendCode}
            >
              ВОЙТИ
            </Button>
          </Fragment>
        )}
      </Container.Flex>
    </Card>
  )
}

export default memo(LoginSMS)
