import styled from 'styled-components/macro'
import { TextProps } from '../types'
import { getTextAlign } from './helpers'
import { getCommonProps, collectTypographyStyles } from '../../../types'
import { IndicatorStyles } from '..'
import Icon from '../../Icon'
import { useRef } from 'react'

export const ErrorWrapper = styled.span<TextProps>`
    ${props => getTextAlign(props)};
    ${props => ({
        ...props.theme.typography.small,
        color: props.theme.colors.info.error,
        ...props.styles,
    })};
    ${props => getCommonProps(props)};
    ${(props) => collectTypographyStyles(props)};
    ${props => (props.color ? { color: props.color } : {})};
    transition: ${props => props.noTransition ? "none" : "all 200ms ease-in"};
`

export default function Error(props: TextProps) {
    const spanRef = useRef<HTMLSpanElement>(null)

    return (
        <ErrorWrapper {...props} ref={spanRef}>
            {props.children}
            {props.withIndicator && (
                <Icon name="color-indicator" styles={IndicatorStyles} />
            )}
        </ErrorWrapper>
    )
}
