import Container from '../ui/Container'
import styled, { useTheme } from 'styled-components'
import Logo from '../../assets/logo'
import { useNavigate } from 'react-router-dom'
import Icon from '../ui/Icon'
import { useDispatch, useSelector } from 'react-redux'
import { getMenuData, showMenu } from '../../store/viewSlice'
import Menu from './Menu'
interface ButtonProps {
    onClick: () => void
}

const BurgerWrapper = styled.svg`
    width: 20px;
    height: 20px;
    color: ${props => props.theme.colors.icon.secondary};
    transition: color 200ms ease-in;
    &:hover {
        cursor: pointer;
        color: ${props => props.theme.colors.icon.tertiary};
    }
`
const LogoWrapper = styled.span`
    flex-shrink: 0;
    font-size: 0;
`

const Burger = ({ onClick }: ButtonProps) => {
    return (
        <BurgerWrapper
            onClick={onClick}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 5C1 4.44772 1.44772 4 2 4H18C18.5523 4 19 4.44772 19 5C19 5.55228 18.5523 6 18 6H2C1.44772 6 1 5.55228 1 5ZM1 10C1 9.44772 1.44772 9 2 9H18C18.5523 9 19 9.44772 19 10C19 10.5523 18.5523 11 18 11H2C1.44772 11 1 10.5523 1 10ZM2 14C1.44772 14 1 14.4477 1 15C1 15.5523 1.44772 16 2 16H18C18.5523 16 19 15.5523 19 15C19 14.4477 18.5523 14 18 14H2Z"
                fill="currentColor"
            />
        </BurgerWrapper>
    )
}

const ButtonContainer = styled.button`
    padding: 0;
    margin: 0 auto 0 0;
    background-color: transparent;
    border: none;
`

const BidLink = styled.a<{ active: boolean }>`
    position: relative;
    width: 20px;
    height: 20px;
    margin-left: auto;
    color: ${props => props.theme.colors.icon.secondary};
    text-align: center;
    visibility: hidden;

    svg {
        fill: currentColor;
    }

    &:after {
        position: absolute;
        right: 0;
        top: 0;
        display: ${props => (props.active ? 'block' : 'none')};
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background-color: ${props => props.theme.colors.info.error};
        content: '';
    }
`

const Header = () => {
    const { menuIsShown } = useSelector(getMenuData)
    const dispatch = useDispatch()

    const theme = useTheme()

    const navigate = useNavigate()

    const onLogoClick = () => {
        navigate('/')
    }

    return (
        <header className="container">
            <Container.Flex
                fullWidth
                justify="center"
                direction="row"
                styles={{
                    padding: '35px 32px',
                }}
                breakpoints={{
                    659.9: {
                        padding: '11px 8px',
                    },
                }}
            >
                {!menuIsShown && (
                    <ButtonContainer type="button" style={{ color: theme.colors.icon.secondary}}>
                        <Burger onClick={() => dispatch(showMenu())} />
                    </ButtonContainer>
                )}

                <Menu />

                <LogoWrapper>
                    <Logo isWhite={menuIsShown} onClick={onLogoClick} />
                </LogoWrapper>

                <BidLink href="/" active={false}>
                    <Icon name="bell" />
                </BidLink>
            </Container.Flex>
        </header>
    )
}

export default Header
