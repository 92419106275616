import { collectAnswers } from '../../components/Offer/helpers/collectAnswers'
import { RootState } from '../../store'
import { AddPropertyRequestData } from '../offerSlice/types'

export const collectAddPropertyData = (
    state: RootState,
    orderNumber: string,
    itemNumber: string,
    addCombinationId?: true
): AddPropertyRequestData => {
    const { answers } = state.offer.givenAnswers;

    const data: AddPropertyRequestData = {        number: orderNumber,
        itemNumber,
        questions: collectAnswers(answers.filter(ans => ans.saveInOrder))}

    if (addCombinationId) {
        data.combinationId = state.offer.givenAnswers.combinationId
    }

    return data
}
