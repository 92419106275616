import { Fragment, ReactNode } from "react";
import Container from "../Container";
import Icon from "../Icon";
import Typography from "../Typography";
import { useTheme } from "styled-components";

interface InfoProps {
    children?: ReactNode;
    parts?: string[];
}

const Info = ({ children, parts }: InfoProps) => {

    const theme = useTheme();
    return (
        <Container.Grid cols="20px 1fr" rows="1fr" gap={8} styles={{paddingTop: "4px"}}>
            <Icon name="info" color={theme.colors.icon.secondary} />
            {children && (
                <Typography.Main
                    fullWidth
                    styles={{ margin: "0" }}
                    textAlign="start"
                >
                    {children}
                </Typography.Main>
            )}

            {parts &&
            <Container.Flex>
                {parts.map((part, idx) => (
                    <Fragment key={idx}>
                        <Typography.Main
                            fullWidth
                            styles={{ margin: "0" }}
                            textAlign="start"
                        >
                            {part}
                        </Typography.Main>
                        {idx < parts.length - 1 && <br />}
                    </Fragment>
                ))}
            </Container.Flex>
            }
        </Container.Grid>
    );
};

export default Info;
