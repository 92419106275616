import { useTheme } from 'styled-components'
import { getFormatRubles } from '../../../helpers/getrFormatRubles'
import { Container, Typography } from '../../ui'
import { OrderListData } from './OrderListContext'
import styled from "styled-components/macro";

const Item = styled.div`
    display: flex;
    align-items: center;
    margin-right: 32px;
    
    &:last-child {
        margin-right: 0;
    }
    
    @media (max-width: 660px) {
        margin-right: 0;
    }
`
export default function OrderListBodyTop({
    data,
}: {
    data: OrderListData[] | null
}) {
    const theme = useTheme()

    const colorSmall = theme.colors.text.tertiary;

    const price = data?.reduce((acc, next) => acc + (+next.amount), 0) ?? 0;

    const { rubles, centes } = getFormatRubles(price)

    return (
        <Container.Flex
            fullWidth
            direction="row"
            justify="center"
            margin={'24px 0'}
            padding={"0 8px"}

            breakpoints={{
                660: {
                    justifyContent: "space-between"
                }
            }}
        >
            <Item>
                <Typography.Small margin="0 4px 0 0" color={colorSmall} textAlign="start">
                    Заявок:
                </Typography.Small>
                <Typography.RublesMedium secondary margin={0} textAlign="start">
                    {data?.length ?? 0}
                </Typography.RublesMedium>
            </Item>

            <Item>
                <Typography.Small margin="0 4px 0 0" color={colorSmall} textAlign="start">
                    Устройств:
                </Typography.Small>
                <Typography.RublesMedium secondary>
                    {data?.reduce((acc, next) => acc + next.items.length, 0) ?? 0}
                </Typography.RublesMedium>
            </Item>

            <Item>
                <Typography.Small margin="0 4px 0 0" color={colorSmall} textAlign="start">
                    Сумма:
                </Typography.Small>
                <Typography.RublesMedium secondary margin={0} textAlign="start">
                    {rubles}
                </Typography.RublesMedium>
                <Typography.RublesCentes secondary margin={0} textAlign="start">
                    {centes}
                </Typography.RublesCentes>
            </Item>

        </Container.Flex>
    )
}
