import SVG from '../SVG'
import { IconProps } from '../types'

const MapIcon = (props: IconProps) => {
  return (
    <SVG {...props} width={props.width ?? 20} height={props.height ?? 20}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.56153 0.172539C7.54979 0.164578 7.53786 0.156846 7.52573 0.14935C7.23091 -0.0328563 6.86277 -0.0494204 6.55279 0.105574L0.552786 3.10557C0.214002 3.27497 0 3.62123 0 4V19C0 19.3466 0.179452 19.6684 0.474269 19.8507C0.769085 20.0329 1.13723 20.0494 1.44721 19.8944L7 17.118L12.5422 19.8891C12.6104 19.9242 12.6824 19.9513 12.7567 19.97C12.9698 20.0235 13.2014 20.0075 13.4152 19.9097C13.4272 19.9043 13.439 19.8986 13.4508 19.8926L19.4472 16.8944C19.786 16.725 20 16.3788 20 16V1C20 0.653423 19.8205 0.331557 19.5257 0.14935C19.2309 -0.0328563 18.8628 -0.0494204 18.5528 0.105574L12.9993 2.88234L7.56153 0.172539ZM8 2.62562V15.382L12 17.382V4.61896L8 2.62562ZM2 17.382V4.61804L6 2.61804V15.382L2 17.382ZM18 15.382L14 17.382V4.61803L18 2.61804V15.382Z"
        fill="currentColor"
      />
    </SVG>
  )
}

export default MapIcon
