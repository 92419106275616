import { PayloadAction } from "@reduxjs/toolkit";
import { delay, takeEvery, put } from "redux-saga/effects";
import {
    animationClear,
    animationClose,
    animationOpen,
    hideMenu,
    hideMenuFulfill,
    redirectRequest,
    showMenu,
    redirectTo,
    setPageVisible,
} from ".";

function* showMenuWorker() {
    yield put(animationOpen());
    yield delay(400);
    yield put(animationClear());
}

function* hideMenuWorker() {
    yield put(animationClear());
    yield put(animationClose());
    yield delay(400);
    yield put(animationClear());
    yield put(hideMenuFulfill());
}

function* redirectRequestWorker({ payload }: PayloadAction<string | null>) {
    yield put(setPageVisible(payload ? true : false));
    yield delay(400);
    yield put(redirectTo(payload));
    yield put(setPageVisible(payload ? false : true));
}

export default function* viewSagas() {
    yield takeEvery(showMenu, showMenuWorker);
    yield takeEvery(hideMenu, hideMenuWorker);
    yield takeEvery(redirectRequest, redirectRequestWorker);
}
