import { usePartnerDetail } from "../../pages/Partner";
import { Container, Typography } from "../ui";

export default function PartnerDetailHeader () {

  const { data } = usePartnerDetail();
  const title = data?.name ?? ""

  return (
    <Container.Flex
      fullWidth
      direction="row"
      styles={{
        justifyContent: 'flex-start',
      }}
      breakpoints={{
        400: {
          justifyContent: 'space-between',
        },
      }}
    >
      <Typography.Title margin={'0 4px'}>{title}</Typography.Title>

      <Typography.Medium link="/partners/">Все партнёры</Typography.Medium>
    </Container.Flex>
  )
}