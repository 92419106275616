import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideMenu, showMenu, getMenuData } from "../store/viewSlice";

export const animateTime = 400;

export const useMenu = () => {
    const dispatch = useDispatch();

    const { menuIsShown, animationClose, animationOpen, savedOrderNumber } = useSelector(getMenuData);

    const _showMenu = useCallback(() => {
        dispatch(showMenu());
    }, []);

    const _hideMenu = useCallback(() => {
        dispatch(hideMenu())
    }, [])

    return ({
        hideMenu: _hideMenu,
        showMenu: _showMenu,
        menuIsShown,
        animationClose,
        animationOpen,
        savedOrderNumber
    })
};
