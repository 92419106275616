import * as CSS from 'csstype'
import { Title, TitleSecondary, Small, RublesLarge, RublesSmall, RublesMedium, Big, Medium, Main, Link, Button, Error, Tertiary, Micro, RublesCentes, LowMenu } from './components'

export const IndicatorStyles: CSS.Properties = {
  flexShrink: 0,
  height: "9px",
  width: "9px",
  verticalAlign: "top",
  marginLeft: '4px'
}

const Typography = {
  Title,
  TitleSecondary,
  Small,
  RublesLarge,
  RublesSmall,
  RublesMedium,
  Big,
  Medium,
  Main,
  Link,
  Button,
  Error,
  Tertiary,
  Micro,
  RublesCentes,
  LowMenu
};

export default Typography