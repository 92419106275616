import { Container, Typography } from '../ui'
import ShopItem from './ShopItem'
import { Partners } from '../../store/orderSlice/types'
import PageLoader from '../Layout/PageLoading'
import Search from './ShopsSearch'
import { bp, containerSizes } from '../../theme/media'

type Props = {
  partners: Partners | null
  title?: string
  pending: boolean
  error: string
  start?: true
  withoutCreate?: true
}


const Shops = ({
  partners,
  error,
  pending,
  start,
}: Props) => {

  if (!partners) return null

  if (pending) return <PageLoader />

  if (error) return <Typography.Error>{error}</Typography.Error>

  return (
    <Container.Flex
      alignItems={'center'}
      justify="between"
      direction="column"
      wrapped
      className="show"
      padding={'0 0 160px 0'}
      styles={{ 
        width: containerSizes.desktop
      }}
      breakpoints={{
        [bp.table]: {
          width: containerSizes.table
        },
        [bp.mobile]: {
          width: containerSizes.mobile
        },
        [bp.xs]: {
          width: '100%'
        }
      }}
      margin={start ? '0' : '0 auto'}
    >
      <Container.Flex
        margin={'0 0 24px 0'}
        direction="row"
        fullWidth
        wrapped
        alignItems="baseline"
        justify="between"
      >
        <Search />
      </Container.Flex>

      <Container.Flex
        direction='row'
        breakpoints={{
          [bp.mobile]: {
            flexDirection: 'column',
            rowGap: "16px"
          }
        }}
        fullWidth
        wrapped
        horizontalGap={36}
        verticalGap={24}

      >
        {Object.values(partners).map(partner => (
          <ShopItem data={partner} key={partner.code} />
        ))}
      </Container.Flex>

    </Container.Flex>
  )
}

export default Shops
