import styled from "styled-components/macro";
import { TextProps } from "../types";
import { getTextAlign } from "./helpers";
import { collectTypographyStyles, getCommonProps } from "../../../types";
import { IndicatorStyles } from "..";
import Icon from "../../Icon";
import { useLink } from "./useLink";

export const BigWrapper = styled.p<TextProps>`
    ${(props) => getTextAlign(props)};
    ${(props) => ({
        ...props.theme.typography.big,
        ...props.styles,
    })};
    ${(props) => getCommonProps(props)};
    ${(props) => collectTypographyStyles(props)};
    ${(props) => (props.color ? { color: props.color } : {})};
    ${(props) => (props.secondary ? { color: props.theme.colors.text.secondary } : {})};
    transition: ${props => props.noTransition ? "none" : "all 200ms ease-in"};
`;

export default function Big (props: TextProps) {

    const { navigate } = useLink()

    return <BigWrapper {...props} onClick={props.onClick ?? navigate(props.link)}>
        {props.children}
        {props.withIndicator && <Icon name="color-indicator" styles={IndicatorStyles}/>}
    </BigWrapper>
}