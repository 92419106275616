import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    DeleteItem,
    getDeleteData,
    setDeleteItemNumber,
} from '../store/orderSlice'

export default function useDeleteData() {
    const dispatch = useDispatch()

    const deleteData = useSelector(getDeleteData)

    const _setDeleteItem = useCallback((itemNumber: string | null) => {
        dispatch(setDeleteItemNumber(itemNumber))
    }, [])

    const deleteItem = useCallback(() => {
        dispatch(DeleteItem.request())
    }, [])

    return {
        itemToDelete: deleteData.itemNumber,
        deleteItem,
        setDeleteItem: _setDeleteItem,
        loading: deleteData.loading,
    }
}
