import { Filter } from "../OrderListContext"

interface RequestFilters {
    [name: string] : string[]
}

export const collectFiltersData = (filters: Filter[]) : RequestFilters => {

    const obj = {} as RequestFilters;

    filters.forEach(filter => {
        const { propertyName } = filter;
        const items = filter.items.filter(el => el.chosen).map(el => el.value);
        if (items.length) {
            obj[propertyName] = items
        }

    })

    return obj
}