import SVG from "../SVG";
import { IconProps } from "../types";

const BellIcon = (props: IconProps) => {
    return (
        <SVG {...props} width={props.width ?? 20} height={props.height ?? 20}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 8C4 4.6863 6.68628 2.00001 9.99998 2C13.3137 1.99999 16 4.68629 16 8V11.7224C16 12.1653 16.176 12.5901 16.4892 12.9034L17 13.4142V14H13.0059C13.004 14 13.002 14 13 14H7C6.99802 14 6.99605 14 6.99408 14H3L3 13.4142L3.51082 12.9034L2.81612 12.2087L3.51082 12.9034C3.82404 12.5902 4 12.1654 4 11.7224V8ZM6 16H3C1.89543 16 1 15.1046 1 14V13.4142C1 12.8838 1.21071 12.3751 1.58579 12L2.22555 12.6398L1.58579 12L2 11.5858V8C2 3.58173 5.58171 1.08481e-05 9.99998 0C14.4183 -1.0848e-05 18 3.58171 18 8V11.5858L18.4142 12C18.7893 12.3751 19 12.8838 19 13.4142V14C19 15.1046 18.1046 16 17 16H14C14 18.2091 12.2091 20 10 20C7.79087 20 6.00001 18.2091 6 16ZM8 16C8.00001 17.1046 8.89544 18 10 18C11.1046 18 12 17.1046 12 16H8Z"
            />
        </SVG>
    );
};

export default BellIcon;
