import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { URLS } from '../../apinew'
import { useApi } from '../../hooks/useApi'
import { Func } from '../../types'
import FiltersComponent from './FiltersComponent'

const FiltersContect = createContext(
  {} as {
    filters: FiltersPart[] | null
    onFilterChange: (k: any) => Func
    currentFilter: FiltersPart | null
    setCurrentFilter: Func
    resetFilters: Func
    filtersOn: boolean
    showFilters: boolean
    setShowFilters: Func
    isChecked: (v: any) => boolean
    activeFilter: (v: any) => boolean
    getFiltersData: any
    getFiltersError: string
    getFiltersPending: boolean
    swiper: any
    setSwiper: Func
    search: string
    setSearch: Func
    getData: Func
  },
)

export type InitialFilter = {
  name: string
  value: number
}

export type FiltersPart = {
  name: string
  propertyName: string
  valueType: 'list' | 'radio'
  items: InitialFilter[]
}

export type FiltersProps = {
  children: ReactNode
  filters?: FiltersPart[]
  getFiltersURL: URLS
  getData: Func
}

export type FiltersState = {
  [name: number]: { text: string; value: any }
}

export const useFiltersData = () => {
  return useContext(FiltersContect)
}

export default function Filters({
  children,
  getFiltersURL,
  getData,
}: FiltersProps) {
  const { post } = useApi()

  const getInitialState = useCallback((filters: FiltersPart[]) => {
    return filters.reduce((acc, next) => {
      next.items.forEach(filter => {
        const { name, value } = filter
        acc[value] = {
          text: name,
          value: '',
        }
      })
      return acc
    }, {} as FiltersState)
  }, [])

  const [state, setState] = useState<FiltersState>({})
  const [currentFilter, setCurrentFilter] = useState<FiltersPart | null>(null)
  const [showFilters, setShowFilters] = useState(false)
  const [filters, setFilters] = useState<FiltersPart[]>([])
  const [getFiltersPending, setGetFiltersPending] = useState(false)
  const [getFiltersError, setGetFiltersError] = useState('')
  const [swiper, setSwiper] = useState<any>(null)
  const [search, setSearch] = useState('')

  useEffect(() => {
    setGetFiltersPending(true)
    post(getFiltersURL, {}).then(response => {
      if (response.status === 'success') {
        setState(getInitialState(response.data))
        setFilters(response.data)
      }
      if (response.status === 'error') {
        setGetFiltersError(response.errors.join('. '))
      }
      setGetFiltersPending(false)
    })
  }, [post])

  const getFiltersData = useMemo(() => {
    const _filters = {} as any

    filters &&
      filters.forEach(filter => {
        const items = filter.items
          .filter(el => !!state?.[el.value].value)
          .map(el => el.value)
        if (items.length) {
          _filters[filter.propertyName] = items
        }
      })

    return _filters
  }, [state, filters])

  useEffect(() => {
    const requestData = {} as any
    requestData.filters = getFiltersData

    if (search) {
      requestData.search = search
    }
    getData(requestData)
  }, [state])

  const onFilterChange = useCallback(
    (name: keyof typeof state) => (value: string) => {
      setState(prev => ({
        ...prev,
        [name]: {
          ...prev[name],
          value,
        },
      }))
    },
    [],
  )

  const isChecked = useCallback(
    (k: keyof typeof state) => {
      return Boolean(state[k].value)
    },
    [state],
  )

  const resetFilters = useCallback(() => {
    if (!filters) return
    if (!currentFilter) {
      setState(getInitialState(filters))
    } else {
      const _filters = filters.find(
        el => el.propertyName === currentFilter.propertyName,
      )
      if (_filters) {
        const newState = { ...state }
        _filters.items.forEach(filter => {
          const { value } = filter
          state[value].value = ''
        })
        setState(newState)
      }
    }
  }, [state, setState, currentFilter, getInitialState, filters])

  const filtersOn = useMemo(() => {
    if (!filters) return false
    if (currentFilter) {
      const _filters = filters.find(
        el => el.propertyName === currentFilter.propertyName,
      )
      if (!_filters) {
        return false
      } else {
        return _filters.items.some(el => {
          const { value } = el
          return !!state[value].value
        })
      }
    } else {
      return Object.values(state).some(el => !!el.value)
    }
  }, [state, currentFilter, filters])

  const activeFilter = useCallback(
    (propertyName: string) => {
      if (!filters) return false
      const filter = filters.find(el => el.propertyName === propertyName)
      if (!filter) {
        return false
      } else {
        const { items } = filter
        return items.some(item => {
          const { value } = item
          return Boolean(state[value].value)
        })
      }
    },
    [state, filters],
  )

  return (
    <FiltersContect.Provider
      value={{
        swiper,
        setSwiper,
        filters,
        onFilterChange,
        currentFilter,
        setCurrentFilter,
        resetFilters,
        filtersOn,
        showFilters,
        setShowFilters,
        isChecked,
        activeFilter,
        getFiltersData,
        getFiltersError,
        getFiltersPending,
        search,
        setSearch,
        getData,
      }}
    >
      {showFilters && <FiltersComponent />}
      {children}
    </FiltersContect.Provider>
  )
}
