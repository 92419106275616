import { withTheme } from './hooks/withTheme'
import styled, { createGlobalStyle } from 'styled-components/macro'
import UploadFiles from './contexts/uploadFiles'
import Routes from './pages/Routes'
import { withLayout } from './components/Layout/withLayout'
import { withAuth } from './components/Layout/withAuth'
import RecaptchaContext from './contexts/recaptcha'

const GlobalStyles = createGlobalStyle`
body::-webkit-scrollbar {
  position: absolute;
  top: 0;
  right: 0;
  width: 5px;
}

body::-webkit-scrollbar-track {
  background-color: ${props => props.theme.colors.scrollBar.background};
}

body::-webkit-scrollbar-thumb {
  background-color: ${props => props.theme.colors.scrollBar.slider};
}

body::-webkit-scrollbar-thumb:hover {

}

`

const AppWrapper = styled.div`
  width: 100%;
  height: 100%;
`

function App() {
  return (
    <RecaptchaContext>
      <UploadFiles>
        <GlobalStyles />
        <AppWrapper>
          <Routes />
        </AppWrapper>
      </UploadFiles>
    </RecaptchaContext>
  )
}

export default withTheme(withAuth(withLayout(App)))
