import { useEffect } from 'react'
import { Container, Image, Overlay } from '../ui'

interface ImagePopupProps {
    url: string
    hide: () => void
}

export default function ImagePopup(props: ImagePopupProps) {

    useEffect(() => {

        const onKeyPress = (e: any) => {
            if (e.key === "Escape") {
                props.hide()
            }
        } 

        if (typeof window !== "undefined") {
            window.addEventListener("keypress", onKeyPress)
        }

        return () => {
            window.removeEventListener("keypress", onKeyPress)
        }
    }, [])

    return (
        <Overlay close={props.hide}>
            <Container.Flex fullHeight fullWidth justify="center" padding={"10%"}>
                <Image src={props.url} alt="popup-image" className="show" />
            </Container.Flex>
        </Overlay>
    )
}
