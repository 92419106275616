import SVG from "../SVG";
import { IconProps } from "../types";

const Calendar = (props: IconProps) => {
    return (
        <SVG {...props} width={props.width ?? 20} height={props.height ?? 20}>
            <path fillRule="evenodd" clipRule="evenodd" d="M14 0C14.5523 0 15 0.447715 15 1H17C18.1046 1 19 1.89543 19 3V6V17C19 18.1046 18.1046 19 17 19H3C1.89543 19 1 18.1046 1 17V6V3C1 1.89543 1.89543 1 3 1H5C5 0.447715 5.44772 0 6 0C6.55228 0 7 0.447715 7 1H13C13 0.447715 13.4477 0 14 0ZM6 3H3V5H17V3H14H6ZM3 7H17V17H3V7ZM14 13C13.4477 13 13 13.4477 13 14V14.002C13 14.5543 13.4477 15.002 14 15.002H14.002C14.5543 15.002 15.002 14.5543 15.002 14.002V14C15.002 13.4477 14.5543 13 14.002 13H14ZM9 14C9 13.4477 9.44771 13 10 13H10.002C10.5543 13 11.002 13.4477 11.002 14V14.002C11.002 14.5543 10.5543 15.002 10.002 15.002H10C9.44771 15.002 9 14.5543 9 14.002V14ZM6 13C5.44772 13 5 13.4477 5 14V14.002C5 14.5543 5.44772 15.002 6 15.002H6.002C6.55428 15.002 7.002 14.5543 7.002 14.002V14C7.002 13.4477 6.55428 13 6.002 13H6ZM13 10C13 9.44771 13.4477 9 14 9H14.002C14.5543 9 15.002 9.44771 15.002 10V10.002C15.002 10.5543 14.5543 11.002 14.002 11.002H14C13.4477 11.002 13 10.5543 13 10.002V10ZM10 9C9.44771 9 9 9.44771 9 10V10.002C9 10.5543 9.44771 11.002 10 11.002H10.002C10.5543 11.002 11.002 10.5543 11.002 10.002V10C11.002 9.44771 10.5543 9 10.002 9H10ZM5 10C5 9.44771 5.44772 9 6 9H6.002C6.55428 9 7.002 9.44771 7.002 10V10.002C7.002 10.5543 6.55428 11.002 6.002 11.002H6C5.44772 11.002 5 10.5543 5 10.002V10Z"/>
        </SVG>
    );
};

export default Calendar;
