import { useTheme } from 'styled-components'
import { OrderItem } from '../../store/orderSlice/types'
import { Button, Container, Typography } from '../ui'
import { Fragment } from 'react'

interface PrePriceProps {
    itemData?: OrderItem
    currency?: string
    onClick?: () => void
}

const OfferPrePrice = (props: PrePriceProps) => {
    const { itemData, onClick, currency } = props

    const theme = useTheme()

    return (
        <Container.Flex fullWidth alignItems="stretch" margin={'24px 0 0 0'}>
            {itemData && currency && (
                <Fragment>
                    <Typography.Title
                        textAlign="start"
                        margin="0 0 20px"
                        padding="0 4px"
                        color={theme.colors.text.secondary}
                    >
                        Предварительная оценка
                    </Typography.Title>

                    <Typography.RublesLarge margin={'0 0 24px'}>
                        {`≈ ${itemData.price} ${currency}`}
                    </Typography.RublesLarge>
                </Fragment>
            )}

            <Button onClick={onClick} styles={{ textTransform: 'uppercase' }}>
                К финальной стоимости
            </Button>
        </Container.Flex>
    )
}

export default OfferPrePrice
