import SVG from '../SVG'
import { IconProps } from '../types'

const CloseIcon = (props: IconProps) => {
    return (
        <SVG {...props} width={props.width ?? 8} height={props.height ?? 8} >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.52483 0.81802C1.32957 0.622757 1.01298 0.622758 0.817723 0.81802C0.62246 1.01328 0.622461 1.32986 0.817723 1.52513L3.2926 4L0.817723 6.47487C0.62246 6.67014 0.62246 6.98672 0.817723 7.18198C1.01298 7.37724 1.32957 7.37724 1.52483 7.18198L3.9997 4.70711L6.47458 7.18198C6.66984 7.37724 6.98642 7.37724 7.18168 7.18198C7.37695 6.98672 7.37695 6.67014 7.18168 6.47487L4.70681 4L7.18168 1.52513C7.37695 1.32986 7.37695 1.01328 7.18168 0.81802C6.98642 0.622757 6.66984 0.622758 6.47458 0.81802L3.9997 3.29289L1.52483 0.81802Z"
                fill={props.color ?? "#464646"}
            />
        </SVG>
    )
}

export default CloseIcon
