import styled from "styled-components/macro";
import { TextProps } from "../types";
import { getTextAlign } from "./helpers";
import { getCommonProps, collectTypographyStyles } from "../../../types";
import { IndicatorStyles } from "..";
import Icon from "../../Icon";
import { useLink } from './useLink'

export const TitleWrapper = styled.h2<TitleProps>`
    ${(props) => getTextAlign(props)};
    ${(props) => getCommonProps(props)};
    ${(props) => collectTypographyStyles(props)};
    ${(props) => ({
        ...props.theme.typography.title,
        ...props.styles,
    })};
    ${(props) => {if (props.color) return ({ color: props.color })}};
    ${(props) => {if (props.secondary) return ({ color: props.theme.colors.text.secondary })}}
    transition: ${props => props.noTransition ? "none" : "all 200ms ease-in"};
`;

type TitleProps = TextProps & {
    secondary?: true
}

export default function Title (props: TitleProps) {
    const { navigate } = useLink();

    return <TitleWrapper {...props} onClick={props.onClick ?? navigate(props.link)}>
        {props.children}
        {props.withIndicator && <Icon name="color-indicator" styles={IndicatorStyles}/>}
    </TitleWrapper>
}