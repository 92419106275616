import { PayloadAction } from '@reduxjs/toolkit'
import {
  giveAnswer,
  giveAnswerRequest,
  hideQuestionContent,
  makeAdditionAction,
  setAdditionalAction,
  setCombinationCode,
  SendPhoto,
  GetErrorForm,
  SendErrorForm,
  setStepRequest,
  GetItemData,
  setCustomerCombinationId,
  setIsCopyItem,
  setAddCombinationId,
} from './index'
import {
  call,
  put,
  select,
  takeEvery,
  delay,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'
import { GetQuestions, setDeviceInfo, setStep } from '.'
import {
  CheckListItemData,
  ErrorForm,
  GivenAnswer,
  MakeAdditionAction,
  OfferSteps,
  QuestionsResponse,
  RequestAnswers,
  SendPhotoData,
} from './types'
import { RootState } from '..'
import { formatRequestAnswer } from '../../components/Offer/helpers/formatRequestAnswer'
import { CreateOrChangeOrder, UpdateItemDate } from '../orderSlice'
import {
  hideOfferCard,
  setOfferCardOpacity,
  setPageVisible,
  showOfferCard,
} from '../viewSlice'
import handleError from '../helpers/handleError'
import { collectAddPropertyData } from '../helpers/collectAddPropertyData'
import Api from '../../apinew'
import { ResponseData } from '../types'

const api = new Api()

function* getQuestionsWorker({
  payload,
}: PayloadAction<{ orderNumber?: string; itemNumber?: string }>) {
  const state: RootState = yield select()
  const session = state.user.session
  const { orderNumber, itemNumber } = payload
  const { customerCombinationId, isCopyItem } = state.offer.givenAnswers
  const addCombinationId = state.offer.addCombinationId
  yield put(hideOfferCard())
  yield delay(200)
  if (orderNumber && itemNumber) {
    yield call(
      api.orderAddProperty,
      {
        data: collectAddPropertyData(
          state,
          orderNumber,
          itemNumber,
          addCombinationId,
        ),
      },
      session,
    )
    if (addCombinationId) {
      yield put(setAddCombinationId())
    }

    yield put(UpdateItemDate.request({ number: orderNumber, itemNumber }))
  }

  const { pauseQuestions } = state.offer
  if (pauseQuestions) return

  const answers: RequestAnswers = yield call(formatRequestAnswer, state)

  const data = { answers } as any

  if (customerCombinationId) {
    yield put(setCustomerCombinationId())
  }
  if (isCopyItem) {
    data.isCopyItem = isCopyItem
    yield put(setIsCopyItem())
  }
  const qrCode = state.order.qrCode
  const response: ResponseData<QuestionsResponse> = yield call(
    api.getQuestions,
    data,
    session,
  )

  if (response?.data?.deviceInfo && !Array.isArray(response.data.deviceInfo)) {
    yield put(setDeviceInfo(response.data.deviceInfo))
  }

  if (response?.data?.complete) {
    yield put(setStep('summary'))
    yield put(setPageVisible(true))
    yield put(showOfferCard())
    yield put(GetQuestions.success(response.data))
  } else {
    if (response?.status === 'success') {
      if (!response.data?.questionsData && !response.data?.questionsTree) {
        yield handleError({}, 'default')
        yield put(GetQuestions.fulfill())
        return
      }
      yield put(GetQuestions.success(response.data))
    }
    if (response?.status === 'error') {
      yield handleError(response)
      yield put(GetQuestions.failure(response.errors))
      return
    }
    yield put(showOfferCard())
    yield put(GetQuestions.fulfill())

    if (qrCode) {
      yield put(setCombinationCode(''))
      yield put(setStep('questions'))
    }
  }

  yield put(setAdditionalAction())
  yield put(GetQuestions.fulfill())
}

function* sendPhotoWorker({ payload }: PayloadAction<SendPhotoData>) {
  const state: RootState = yield select()
  const session = state.user.session
  if (state.offer.sendPhoto.result) return
  yield put(setPageVisible(false))
  yield delay(200)

  const { itemNumber, orderNumber, files, isCopyItem } = payload

  yield put(setAdditionalAction())

  if (!files || !itemNumber || !orderNumber) {
    yield put(SendPhoto.failure(['Ошибка загрузки картинок']))
    return
  }

  const response: ResponseData<any> = yield call(
    api.orderAddFile,
    { images: { ...files }, number: orderNumber, itemNumber, isCopyItem },
    session,
  )

  if (response.status === 'success') {
    yield put(SendPhoto.success(response.data))
    yield put(setCombinationCode())
    yield put(SendPhoto.fulfill())
    yield put(showOfferCard())
    yield put(setPageVisible(false))
    yield put(GetQuestions.request({ orderNumber, itemNumber }))
  }

  if (response.status === 'error') {
    yield handleError(response)
    yield put(SendPhoto.failure(response.errors))
    yield put(SendPhoto.fulfill())
  }
}

function* makeAdditionActionWorker({
  payload,
}: PayloadAction<MakeAdditionAction>) {
  const state: RootState = yield select()
  if (state.offer.pauseQuestions) return
  yield put(setPageVisible(false))
  yield put(hideOfferCard())
  const { pauseQuestions } = state.offer
  if (pauseQuestions) return
  const { itemNumber, orderNumber } = payload
  const { action } = payload
  switch (action) {
    case 'createOrder':
      yield put(CreateOrChangeOrder.request({ orderNumber, itemNumber }))
      break
    case 'addPhoto':
      yield put(
        SendPhoto.request({
          files: payload.images,
          itemNumber: payload.itemNumber,
          orderNumber: payload.orderNumber,
        }),
      )
      break
  }
  yield put(setAdditionalAction())
}

function* getErrorFormWorker({ payload }: PayloadAction<string>) {
  const state: RootState = yield select()
  const sessions = state.user.session
  const code = payload
  yield put(hideOfferCard())
  yield delay(200)

  const response: ResponseData<ErrorForm> = yield call(
    api.getPageForm,
    { code },
    sessions,
  )

  if (response.status === 'success') {
    yield put(GetErrorForm.success(response.data))
  }

  if (response.status === 'error') {
    yield put(GetErrorForm.failure())
  }

  yield put(showOfferCard())
  yield put(GetErrorForm.fulfill())
  yield put(setPageVisible(true))
}

function* sendErrorFormWorker({ payload }: PayloadAction<any>) {
  const state: RootState = yield select()
  const session = state.user.session
  const response: ResponseData<any> = yield call(
    api.sendPageForm,
    payload,
    session,
  )

  if (response.status === 'success') {
    yield put(SendErrorForm.success(response.data))
  }
  if (response.status === 'error') {
    yield put(SendErrorForm.failure(response.errors))
  }
  yield put(SendErrorForm.fulfill())
}

function* giveAnswerRequestWorker({ payload }: PayloadAction<GivenAnswer>) {
  yield put(hideQuestionContent())
  yield delay(200)
  yield put(giveAnswer(payload))
  yield put(setOfferCardOpacity('1'))
}

function* setStepRequestWorker({ payload }: PayloadAction<OfferSteps>) {
  yield put(hideOfferCard())
  yield delay(200)
  yield put(setStep(payload))
  yield put(showOfferCard())
}

function* getItemDataRequestWorker({ payload }: PayloadAction<string>) {
  const state: RootState = yield select()
  const session = state.user.session

  const response: ResponseData<CheckListItemData> = yield api.getItemInfo(
    { itemNumber: payload },
    session,
  )

  const success = response.status === 'success'

  if (success) {
    yield put(GetItemData.success(response.data))
  }

  if (response.status === 'error') {
    yield put(GetItemData.failure(response.errors))
  }

  yield put(GetItemData.fulfill())

  if (success) {
    yield put(setStep('check-list'))
  }
}

export default function* offerSagas() {
  yield takeLatest(GetQuestions.REQUEST, getQuestionsWorker)
  yield takeEvery(makeAdditionAction, makeAdditionActionWorker)
  yield takeEvery(giveAnswerRequest, giveAnswerRequestWorker)
  yield takeLeading(SendPhoto.REQUEST, sendPhotoWorker)
  yield takeLeading(GetErrorForm.REQUEST, getErrorFormWorker)
  yield takeLatest(SendErrorForm.REQUEST, sendErrorFormWorker)
  yield takeEvery(setStepRequest, setStepRequestWorker)
  yield takeEvery(GetItemData.REQUEST, getItemDataRequestWorker)
}
