import { useParams } from 'react-router-dom'
import PageLayout from './PageLayout'
import Order from '../components/Order/Order'

const OrderPage = () => {
  const { orderNumber } = useParams()

  return (
    <PageLayout withProfile>
      {!!orderNumber && <Order orderNumber={orderNumber} />}
    </PageLayout>
  )
}

export default OrderPage
