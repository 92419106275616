import { memo } from "react"
import OrderList from "./OrdersList/OrderList"
import OrderListContextWrapper from "./OrdersList/OrderListContext"

const ProfileOrders = () => {

    return (
        <OrderListContextWrapper>
            <OrderList />
        </OrderListContextWrapper>
    )
}

export default memo(ProfileOrders)