import SVG from "../SVG";
import { IconProps } from "../types";

const NewOrderIcon = (props: IconProps) => {
    return (
        <SVG {...props} width={props.width ?? 24} height={props.height ?? 19}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 1C20 0.447715 19.5523 0 19 0C18.4477 0 18 0.447715 18 1V4H15C14.4477 4 14 4.44772 14 5C14 5.55228 14.4477 6 15 6H18V9C18 9.55229 18.4477 10 19 10C19.5523 10 20 9.55229 20 9V6H23C23.5523 6 24 5.55228 24 5C24 4.44772 23.5523 4 23 4H20V1ZM3 3C2.44772 3 2 3.44772 2 4V16C2 16.5523 2.44772 17 3 17H19C19.5523 17 20 16.5523 20 16V12C20 11.4477 20.4477 11 21 11C21.5523 11 22 11.4477 22 12V16C22 17.6569 20.6569 19 19 19H3C1.34315 19 0 17.6569 0 16V4C0 2.34315 1.34315 1 3 1H13C13.5523 1 14 1.44772 14 2C14 2.55228 13.5523 3 13 3H3ZM4 8C4 7.44772 4.44772 7 5 7H9.57143C10.1237 7 10.5714 7.44772 10.5714 8C10.5714 8.55229 10.1237 9 9.57143 9H5C4.44772 9 4 8.55229 4 8ZM4 13C4 12.4477 4.44772 12 5 12H13C13.5523 12 14 12.4477 14 13C14 13.5523 13.5523 14 13 14H5C4.44772 14 4 13.5523 4 13Z"
                fill="currentColor"
            />
        </SVG>
    );
};

export default NewOrderIcon;
