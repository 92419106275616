import { memo, ReactNode } from 'react'
import styled from 'styled-components/macro'
import { useDelete } from '../../contexts/delete'
import { Delete } from '../ui'

const Wrapper = styled.main`
    /*width: 100%;
    height: 100%;
    max-width: 1104px;
    margin: 0 auto;
    transition: all 500ms ease-in;*/
`

const Main = ({ children }: { children: ReactNode }) => {
    const { title, onDelete, onCancel, question } = useDelete()

    return (
        <Wrapper className="container">
            {!!onDelete && (
                <Delete
                    onCancel={onCancel}
                    onDelete={onDelete}
                    question={question}
                    title={title}
                />
            )}
            {children}
        </Wrapper>
    )
}

export default memo(Main)
