const reducer = (errors: string[], file: File) => {
    const { size, type } = file
    if (size > 50 * 10 ** 6) {
        errors.push('Превышен размер файла')
    }

    if (!['image/jpeg', 'image/png'].includes(type)) {
        errors.push('Файлы допустимы только в формате jpg или png')
    }
    return errors
}

export const collectErrors = (files: File[]) =>
    files.reduce(reducer, [] as string[])
