type PopupData = {
    popupTrigger: string
    popupContent: string
    processedHTML: string
}

export const getPopupData = (html: string) : PopupData  => {

    const popupTrigger = html.match(
        /<\s*span class="popup-trigger"[^>]*>(.*?)<\s*\/\s*span>/g
    )?.[0] || "";

    const popupContent = html.match(
        /<\s*div class="popup-container"[^>]*>(.*?)<\s*\/\s*div>/g
    )?.[0] || "";

    const processedHTML = html.replace(popupTrigger, "").replace(popupContent, "");
    
    return ({
        popupContent: popupContent.replace(`<div class="popup-container">`, "").replace("</div>", ""),
        popupTrigger: popupTrigger.replace(`<span class="popup-trigger">`, "").replace("</span>", ""),
        processedHTML
    })
}