import { memo, ReactNode, useEffect } from 'react'
import styled from 'styled-components/macro'
import { useAuthData } from '../../contexts/auth'
import { useMenu, animateTime } from '../../hooks/useMenu'
import Container from '../ui/Container'
import Icon from '../ui/Icon'
import Typography from '../ui/Typography'
import { Fragment } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { zIndexes } from '../../zIndexes'
import { useSession } from '../../hooks/useSession'

const MenuWrapper = styled.div<{
  visible: boolean
  animationOpen: boolean
  animationClose: boolean
}>`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  /*overflow: hidden;*/
  padding: 11px 32px 20px;
  z-index: ${zIndexes.menuOverlay};
  text-decoration: none;
  &:before {
    position: absolute;
    left: 42px;
    top: 32px;
    z-index: -1;
    width: ${props => (props.animationOpen ? '20px' : '250vh')};
    height: ${props => (props.animationOpen ? '20px' : '250vh')};
    background: rgba(0, 0, 0, 0.7);
    border-radius: 50%;
    backdrop-filter: blur(8px);
    transform: ${props =>
      props.animationOpen || props.animationClose
        ? 'translate(-50%, -50%)'
        : !props.animationOpen && !props.animationClose
        ? 'translate(-50%, -50%)'
        : 'translate(0, 0)'};
    content: '';
    will-change: width, height, translate;
    animation: ${props =>
      props.animationOpen
        ? `menuAppearance ${animateTime}ms forwards`
        : props.animationClose
        ? `menuAppearance ${animateTime}ms reverse`
        : 'none'};
  }

  @media (min-width: 660px) {
    &::before {
      left: 96px;
      top: 56px;
    }
  }

  @media (orientation: landscape) {
    &::before {
      width: ${props => (props.animationOpen ? '20px' : '250vw')};
      height: ${props => (props.animationOpen ? '20px' : '250vw')};

      animation: ${props =>
        props.animationOpen
          ? `menuAppearanceLandscape ${animateTime}ms forwards`
          : props.animationClose
          ? `menuAppearanceLandscape ${animateTime}ms reverse`
          : 'none'};
    }
  }
`

const Close = styled.svg`
  z-index: ${zIndexes.aboveMenu};
  position: relative;
  margin-right: auto;
  transition: ${props => `color ${animateTime}ms`};
  color: ${props => props.theme.colors.icon.contrast};
  &:hover {
    cursor: pointer;
    color: ${props => props.theme.colors.icon.secondary};
  }
`

const CloseButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Close
      onClick={onClick}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.63603 14.9499C3.24551 15.3404 3.24551 15.9736 3.63603 16.3641C4.02656 16.7546 4.65972 16.7546 5.05025 16.3641L9.99988 11.4144L14.9497 16.3643C15.3403 16.7548 15.9734 16.7548 16.364 16.3643C16.7545 15.9738 16.7545 15.3406 16.364 14.9501L11.4141 10.0002L16.364 5.05037C16.7545 4.65984 16.7545 4.02668 16.364 3.63615C15.9734 3.24563 15.3403 3.24563 14.9497 3.63615L9.99988 8.58602L5.05025 3.63639C4.65972 3.24586 4.02656 3.24586 3.63603 3.63639C3.24551 4.02691 3.24551 4.66008 3.63603 5.0506L8.58566 10.0002L3.63603 14.9499Z"
        fill="currentColor"
      />
    </Close>
  )
}

const MenuLink = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 37px;
  color: ${props => props.theme.colors.link.contrast};
  text-decoration: none;
  transition: color 200ms;

  * {
    text-decoration: none;
  }
  &:hover {
    cursor: pointer;
    color: ${props => props.theme.colors.button.hover};
  }
  &:active,
  &:focus {
    color: ${props => props.theme.colors.link.pressed};
  }

  svg {
    transition: all 200ms;
  }
`

const Text = ({ children }: { children: ReactNode }) => {
  return (
    <Typography.Button
      styles={{
        color: 'currentcolor',
        marginLeft: '21px',
        transition: `color 200ms`,
      }}
    >
      {children}
    </Typography.Button>
  )
}

const NavWrapper = styled.nav<{ visible: boolean }>`
  margin: 88px auto 0;
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: ${props =>
    props.visible
      ? `opacity ${animateTime / 2}ms`
      : `opacity ${animateTime}ms`};
`

const linkStyle = {
  width: '100%',
  textDecoration: 'none'
}

const Menu = () => {
  const {
    hideMenu,
    menuIsShown,
    animationOpen,
    animationClose,
    savedOrderNumber,
  } = useMenu()
  const { session, logout } = useAuthData();
  const { isAdmin } = useSession();
  const location = useLocation()

  useEffect(() => {
    hideMenu()
  }, [location, hideMenu])

  const onMenuClick = (e: any) => {
    if (!e.target.classList.contains('menu-link')) {
      hideMenu()
    }
  }

  return (
    <Fragment>
      {menuIsShown && <CloseButton onClick={hideMenu} />}
      <MenuWrapper
        onClick={onMenuClick}
        className="container"
        visible={menuIsShown}
        animationOpen={animationOpen}
        animationClose={animationClose}
      >
        <NavWrapper visible={menuIsShown}>
          <Container.Flex
            styles={{
              width: 'fit-content',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <Link
              style={linkStyle}
              to={session.isAuthorised ? '/profile' : '/login'}
            >
              <MenuLink className="menu-link">
                <Icon name={session.isAuthorised ? 'user' : 'exit'} />
                <Text>{session.isAuthorised ? 'Профиль' : 'Войти'}</Text>
              </MenuLink>
            </Link>

            {isAdmin && (
              <Link style={linkStyle} to={`/profile/order-list/`}>
                <MenuLink className="menu-link">
                  <Icon name="list" />
                  <Text> Список заявок</Text>
                </MenuLink>
              </Link>
            )}

            {!isAdmin && savedOrderNumber && (
              <Link style={linkStyle} to={`/order/${savedOrderNumber}/`}>
                <MenuLink className="menu-link">
                  <Icon name="list" />
                  <Text> Мои устройства</Text>
                </MenuLink>
              </Link>
            )}

            <Link style={linkStyle} to="/partner/">
              <MenuLink className="menu-link">
                <Icon name="reports" />
                <Text>Стать партнером</Text>
              </MenuLink>
            </Link>

            <Link style={linkStyle} to="/help/">
              <MenuLink className="menu-link">
                <Icon name="help" />
                <Text>Помощь</Text>
              </MenuLink>
            </Link>

            {session.isAuthorised && (
              <MenuLink className="menu-link" onClick={logout}>
                <Icon name="exit" />
                <Text>Выйти</Text>
              </MenuLink>
            )}
          </Container.Flex>
        </NavWrapper>
      </MenuWrapper>
    </Fragment>
  )
}

export default memo(Menu)
