import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { SavedDataState } from "./types";

const initialState: SavedDataState = {
    orderNumber: null
}

const saveSlice = createSlice({
    name: "savedData",
    initialState,
    reducers: {
        saveOrderNumber(state: SavedDataState, { payload } : PayloadAction<string>) {
            state.orderNumber = payload
        },
        resetSavedOrderNumber(state: SavedDataState) {
            state.orderNumber = null
        }
    }
});


export const getOrderNumber = createSelector(
    (state: RootState) => state.saved,
    data => data.orderNumber
)

export const {
    saveOrderNumber,
    resetSavedOrderNumber
} = saveSlice.actions

export default saveSlice.reducer