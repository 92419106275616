import { ComponentType, ReactElement } from 'react'
import { useSelector } from 'react-redux'
import Bearer from './Bearer'
import Header from './Header'
import Main from './Main'
import PageLoading from './PageLoading'
import Container from '../ui/Container'
import Background from './Backgorund'
import useDeleteData from '../../hooks/useDeleteData'
import { Delete, Popup } from '../ui'
import { getPageVisible } from '../../store/viewSlice'
import usePopup from '../../hooks/usePopup'
import DeleteWrapper from '../../contexts/delete'
import { useAuthData } from '../../contexts/auth'
import { usePopupImage } from '../../hooks/usePopupImage'
import ImagePopup from './PopupImage'
import { bp } from '../../theme/media'
import { useSession } from '../../hooks/useSession'

export const withLayout =
  <T extends object>(Component: ComponentType<T>, needAuth?: true) =>
  (props: T): ReactElement => {
    const { deleteItem, itemToDelete, setDeleteItem } = useDeleteData()
    const { session, pending, errors } = useAuthData()
    const { isAdmin } = useSession()

    const { popupContent, hidePopup } = usePopup()
    const { image, setImage } = usePopupImage()

    const pageVisible = useSelector(getPageVisible)

    const bpTable = isAdmin ? bp.table - 364 - 18 : bp.table

    return (
      <DeleteWrapper>
        <Container.Flex
          alignItems="stretch"
          fullHeight
          styles={{ position: 'relative', minHeight: '100vh' }}
        >
          <Container.Flex
            margin={'0 auto'}
            styles={{
              width: '1104px',
              minHeight: '100vh',
            }}
            breakpoints={{
              [bpTable]: {
                width: '660px',
              },
              [1200]: {
                width: '660px',
              },
              [bp.mobile]: {
                width: '100%',
                maxWidth: 'calc(100vw - 48px)',
              },
            }}
          >
            <Background />
            <Header />
            <Container.Flex
              fullWidth
              styles={{
                transition: 'opacity 400ms ease-in',
                opacity: pageVisible ? 1 : 0,
                height: `calc(100% - 112px)`
              }}
            >
              {!!popupContent && (
                <Popup content={popupContent} hidePopup={hidePopup} />
              )}
                <Main>
                  {!needAuth && <Component {...props} />}
                  {needAuth && !pending && session.isAuthorised && (
                    <Component {...props} />
                  )}
                  {needAuth && pending && <PageLoading />}
                  {needAuth && !pending && !!errors.checkAuth.length && (
                    <Bearer />
                  )}
                  {needAuth && !pending && !session.isAuthorised && <Bearer />}

                  {!!itemToDelete && (
                    <Delete
                      onDelete={deleteItem}
                      onCancel={() => setDeleteItem(null)}
                      title="Удаление"
                      question="Вы уверены что хотите произвести удаление?"
                    />
                  )}

                  {image && (
                    <ImagePopup url={image} hide={() => setImage(null)} />
                  )}
                </Main>

            </Container.Flex>
          </Container.Flex>
        </Container.Flex>
      </DeleteWrapper>
    )
  }
