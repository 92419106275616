import { Container } from '../ui'
import PartnerDesctiptionCard from './ParnterDescriptionCard'
import PartnerCard from './PartnerCard'
import PartnerDetailHeader from './PartnerDetailHeader'

export default function ParnterDetail() {
  return (
    <Container.Flex fullWidth verticalGap={16} padding='0 0 24px 0'>
      <PartnerDetailHeader />

      <Container.Flex
        direction="row"
        wrapped
        fullWidth
        justify="between"
        alignItems="start"
      >
        <PartnerCard fullWidth/>
        <PartnerDesctiptionCard />
      </Container.Flex>
    </Container.Flex>
  )
}
