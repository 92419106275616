import { useTheme } from "styled-components";
import { useState, useRef, useEffect } from "react";
import { Container, Typography } from "../ui";

interface TimerProps {
  resendCode: () => void;
  codePending: boolean
}

const Timer = ({ resendCode, codePending }: TimerProps) => {
  const theme = useTheme()

  const [timer, setTimer] = useState(60);

  const timerRef = useRef<ReturnType<typeof setInterval>>()

  const changeTimer = () => {
    if (timer - 1 < 0) {
    } else {
      setTimer(timer - 1)
    }
  }

  const clearTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
  };

  useEffect(() => {
    if (codePending) {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    } else {
      setTimer(60)
    }
  }, [codePending])

  const getCode = async () => {
    if (timer === 0) {
      resendCode();
    }
  }

  useEffect(() => {
    timerRef.current = setTimeout(changeTimer, 1000)

    return () => {
      clearTimer()
    }
  }, [timer])

  return (
    <Container.Flex direction="row" horizontalGap={5}>
      <Typography.Small color={timer > 0 ? theme.colors.text.tertiary : theme.colors.link.default} onClick={getCode}>
        Получить код еще раз
      </Typography.Small>
      <Typography.Small>{`через ${timer} сек`}</Typography.Small>
    </Container.Flex>
  )
}

export default Timer