import { RootState } from './../../../store/index'
import { CreateOrChangeOrderRequest } from '../../../store/orderSlice/types'
import { collectAnswers } from '../../helpers/collectAnswers'

export const collectCreateOrChangeOrderData = (
    state: RootState,
    orderNumber?: string,
): CreateOrChangeOrderRequest => {
    const { givenAnswers, deviceInfo } = state.offer
    const { combinationId, offerId } = givenAnswers

    const obj: CreateOrChangeOrderRequest = {
        questions: {},
    }

    if (combinationId) {
        obj.combinationId = combinationId
    }

    if (offerId) {
        obj.productId = offerId
    }

    if (deviceInfo?.deviceID && !offerId) {
        obj.productId = deviceInfo.deviceID
    }

    if (orderNumber) {
        obj.number = orderNumber
    }

    obj.questions = collectAnswers(
        givenAnswers.answers.filter(ans => ans.saveInOrder),
    )

    return obj
}
