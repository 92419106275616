import OrderingContext from "./OrderingContext";
import OrderingFormWrapper from "./OrderingFormWrapper";

export default function Ordering () {

    return (
        <OrderingContext>
            <OrderingFormWrapper />
        </OrderingContext>
    )
}