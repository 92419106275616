import styled from 'styled-components'
import { forwardRef, MouseEvent } from 'react'
import { setCaretPosition } from './setCaretPosition'
import { onKeyDown } from './onKeyDown'
import { defaultPhone  } from '.'

const Input = styled.input`
  border: none;
  border-radius: 12px;
  padding: 10px 20px;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${props => props.theme.colors.text.default};
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.04);
  backdrop-filter: blur(8px);
  outline: none;

  &::placeholder {
    color: ${props => props.theme.colors.info.error};
  }
`

type PhoneInputProps = {
  afterChange?: (value: string) => void
}

const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>((props, ref) => {

  const onClick = (e: MouseEvent<HTMLInputElement>) => {
    setCaretPosition(e.target);
    e.preventDefault();
    e.stopPropagation();
  }



  return (
    <Input
      ref={ref}
      type="tel"
      onKeyDown={(e) => onKeyDown(e, props.afterChange)}
      onClick={onClick}
      defaultValue={defaultPhone}
    />
  )
});

export default PhoneInput
